/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Timeline event type
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type TimelineEventTypeEnum = 'Edit' | 'InReview' | 'Draft' | 'Published' | 'Archived' | 'CheckIn' | 'CheckOut' | 'Created' | 'UpdatedMajorVersion' | 'Snapshot' | 'Restored';

export const TimelineEventTypeEnum = {
    Edit: 'Edit' as TimelineEventTypeEnum,
    InReview: 'InReview' as TimelineEventTypeEnum,
    Draft: 'Draft' as TimelineEventTypeEnum,
    Published: 'Published' as TimelineEventTypeEnum,
    Archived: 'Archived' as TimelineEventTypeEnum,
    CheckIn: 'CheckIn' as TimelineEventTypeEnum,
    CheckOut: 'CheckOut' as TimelineEventTypeEnum,
    Created: 'Created' as TimelineEventTypeEnum,
    UpdatedMajorVersion: 'UpdatedMajorVersion' as TimelineEventTypeEnum,
    Snapshot: 'Snapshot' as TimelineEventTypeEnum,
    Restored: 'Restored' as TimelineEventTypeEnum
};

marker('Edit');
marker('InReview');
marker('Draft');
marker('Published');
marker('Archived');
marker('CheckIn');
marker('CheckOut');
marker('Created');
marker('UpdatedMajorVersion');
marker('Snapshot');
marker('Restored');

