/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi target types
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type TargetType = 'Biodiversity' | 'Human Wellbeing';

export const TargetType = {
    Biodiversity: 'Biodiversity' as TargetType,
    HumanWellbeing: 'Human Wellbeing' as TargetType
};

marker('Biodiversity');
marker('Human Wellbeing');

