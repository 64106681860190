/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Threat rating (mode)
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ThreatRatingModeEnum = 'Simple' | 'Stress Based';

export const ThreatRatingModeEnum = {
    Simple: 'Simple' as ThreatRatingModeEnum,
    StressBased: 'Stress Based' as ThreatRatingModeEnum
};

marker('Simple');
marker('Stress Based');

