/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi diagram factor font style
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type DiagramFactorFontStyleEnum = 'Bold' | 'Underline' | 'StrikeThrough';

export const DiagramFactorFontStyleEnum = {
    Bold: 'Bold' as DiagramFactorFontStyleEnum,
    Underline: 'Underline' as DiagramFactorFontStyleEnum,
    StrikeThrough: 'StrikeThrough' as DiagramFactorFontStyleEnum
};

marker('Bold');
marker('Underline');
marker('StrikeThrough');

