/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Factor delete info
 */
import { ValidateUtils } from 'app/shared/utils';

export class FactorDeleteInfo { 
    /**
     * Flag indicating whether factor can be deleted without warning or not
     */
    readonly warnOnDelete?: boolean;
    /**
     * Flag indicating whether factor is referenced across multiple diagrams
     */
    readonly isSharedFactor?: boolean;

    constructor(item?: Partial<FactorDeleteInfo>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            warnOnDelete: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.warnOnDelete : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isSharedFactor: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isSharedFactor : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: FactorDeleteInfo.isValid,
        };
    }

    static isValid(obj: FactorDeleteInfo): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = FactorDeleteInfo.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.warnOnDelete.isValid(item);
            result = result && validationObject.isSharedFactor.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

