/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Indicator scorecard aggregation method
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type IndicatorScorecardAggregationMethodEnum = 'Average' | 'Total' | 'Maximum' | 'Minimum';

export const IndicatorScorecardAggregationMethodEnum = {
    Average: 'Average' as IndicatorScorecardAggregationMethodEnum,
    Total: 'Total' as IndicatorScorecardAggregationMethodEnum,
    Maximum: 'Maximum' as IndicatorScorecardAggregationMethodEnum,
    Minimum: 'Minimum' as IndicatorScorecardAggregationMethodEnum
};

marker('Average');
marker('Total');
marker('Maximum');
marker('Minimum');

