import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import * as gettext from 'gettext-parser';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';



export class TranslatePoLoader implements TranslateLoader {

	/**
	 * Translation context
	 */
	public context = '';

	constructor(
		protected _http: HttpClient,
		protected _prefix: string = 'i18n',
		protected _suffix: string = '.po'
	) {
	}

	/**
	 * Gets the translations from file
	 * @param lang
	 * @returns {any}
	 */
	getTranslation(lang: string): Observable<any> {
		return this._http
			.get(`${this._prefix}/${lang}${this._suffix}`, { responseType: 'text' })
			.pipe(
				map((contents: string) => {
					if (!contents || contents[0] === '<') {
						return throwError(contents);
					}

					return this.parse(contents);
				}),
				catchError((error: any) => {
					return throwError(new HttpErrorResponse({
						error: 'Missing or invalid translation file.',
						status: 404,
						statusText: 'Not Found',
						url: `${this._prefix}/${lang}${this._suffix}`,
					}));
				})
			);
	}

	/**
	 * Parse po file
	 * @param contents
	 * @returns {any}
	 */
	parse(contents: string): any {
		const translations: { [key: string]: any } = {};

		const po = gettext.po.parse(contents, 'utf-8');
		if (!po.translations.hasOwnProperty(this.context)) {
			return translations;
		}

		for (const context of Object.keys(po.translations)) {
			translations[context || ''] = {};
			for (const key of Object.keys(po.translations[context])) {
				const translation: string = po.translations[context][key].msgstr.pop();
				if (key.length > 0 && translation.length > 0) {
					translations[context || ''][key] = translation;
				}
			}
		}

		return translations;
	}

}
