import { Observable, Subject } from 'rxjs';

export class BlobUtils {

  static b64ToBlob(b64: string, fileType: string): Blob {
    const bytesString: string = atob(b64);
    return BlobUtils.binaryStringToBlob(bytesString, fileType);
  }

  static binaryStringToBlob(bytesString: string, fileType: string, sliceSize?: number) {
    sliceSize = sliceSize || 512;

    var byteArrays = [];
    for (var offset = 0; offset < bytesString.length; offset += sliceSize) {
      var slice = bytesString.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: fileType });
  }

  static stringToBlob(text: string, fileType: string): Blob {
    return new Blob([text], { type: fileType });
  }

  static blobToB64(file: File | Blob): Observable<string> {
    const subject = new Subject<string>();

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      subject.next(reader.result as string);
      subject.complete();
    };
    reader.onerror = function (error) {
      subject.error(error);
    };

    return subject;
 }

}
