<div *ngIf="!hidden"
  class="flex-row gap-2 center middle survey-banner">
  <div></div>

  <div class="flex-1"></div>

  <i class="icon fas fa-bullhorn"></i>

  <div class="label">
    <b>{{ 'Help shape the future of Miradi!' | translate }}</b>
    {{ 'Take our survey for a chance to win some great prizes.' | translate }}
  </div>

  <p-button
    (click)="takeSurvey('https://www.surveymonkey.com/r/ZYCRMQL')"
    [label]="'Take Survey' | translate"
    [raised]="true"
    severity="secondary">
  </p-button>

  <div class="flex-1"></div>

  <p-button
    class="button-dismiss"
    [text]="true"
    icon="fas fa-times"
    (click)="dismiss()">
  </p-button>
</div>
