/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi diagram factor types
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type DiagramFactorType = 'Activity' | 'Biophysical Factor' | 'Biophysical Result' | 'Contributing Factor' | 'Direct Threat' | 'Goal' | 'Group Box' | 'Human Wellbeing Target' | 'Indicator' | 'Intermediate Result' | 'Link' | 'Monitoring Activity' | 'Objective' | 'Scope Box' | 'Strategy' | 'Stress' | 'Target' | 'Text Box' | 'Threat Reduction Result' | 'Output' | 'Assumption' | 'Subassumption';

export const DiagramFactorType = {
    Activity: 'Activity' as DiagramFactorType,
    BiophysicalFactor: 'Biophysical Factor' as DiagramFactorType,
    BiophysicalResult: 'Biophysical Result' as DiagramFactorType,
    ContributingFactor: 'Contributing Factor' as DiagramFactorType,
    DirectThreat: 'Direct Threat' as DiagramFactorType,
    Goal: 'Goal' as DiagramFactorType,
    GroupBox: 'Group Box' as DiagramFactorType,
    HumanWellbeingTarget: 'Human Wellbeing Target' as DiagramFactorType,
    Indicator: 'Indicator' as DiagramFactorType,
    IntermediateResult: 'Intermediate Result' as DiagramFactorType,
    Link: 'Link' as DiagramFactorType,
    MonitoringActivity: 'Monitoring Activity' as DiagramFactorType,
    Objective: 'Objective' as DiagramFactorType,
    ScopeBox: 'Scope Box' as DiagramFactorType,
    Strategy: 'Strategy' as DiagramFactorType,
    Stress: 'Stress' as DiagramFactorType,
    Target: 'Target' as DiagramFactorType,
    TextBox: 'Text Box' as DiagramFactorType,
    ThreatReductionResult: 'Threat Reduction Result' as DiagramFactorType,
    Output: 'Output' as DiagramFactorType,
    Assumption: 'Assumption' as DiagramFactorType,
    Subassumption: 'Subassumption' as DiagramFactorType
};

marker('Activity');
marker('Biophysical Factor');
marker('Biophysical Result');
marker('Contributing Factor');
marker('Direct Threat');
marker('Goal');
marker('Group Box');
marker('Human Wellbeing Target');
marker('Indicator');
marker('Intermediate Result');
marker('Link');
marker('Monitoring Activity');
marker('Objective');
marker('Scope Box');
marker('Strategy');
marker('Stress');
marker('Target');
marker('Text Box');
marker('Threat Reduction Result');
marker('Output');
marker('Assumption');
marker('Subassumption');

