import { Injectable } from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {

  }

  getItem(key: string): any {
    let value = localStorage.getItem(key);
    if (value && (value.indexOf('[') === 0 || value.indexOf('{') === 0 || value.indexOf('null') === 0)) {
      value = JSON.parse(value);
    }
    return value;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  setItem(key: string, value: any): void {
    try {
      if (typeof value !== 'string') {
        value = JSON.stringify(value);
      }

      localStorage.setItem(key, value);
    } catch (error) {
      // This is pretty much here to cover the scenario where the storage gets above
      // the maximum size and it would start failing all the time if we don't free up
      // some space
      localStorage.clear();
    }
  }

}
