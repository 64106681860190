/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';
import { map }                                               from 'rxjs/operators';


import { Assumption } from '../model/assumption';
import { AssumptionNew } from '../model/assumptionNew';
import { AssumptionRelevantFactors } from '../model/assumptionRelevantFactors';
import { AssumptionUpdate } from '../model/assumptionUpdate';
import { DiagramColorPalette } from '../model/diagramColorPalette';
import { DiagramDeleteInfo } from '../model/diagramDeleteInfo';
import { DiagramFactorTypeDict } from '../model/diagramFactorTypeDict';
import { FactorDeleteInfo } from '../model/factorDeleteInfo';
import { GroupBox } from '../model/groupBox';
import { GroupBoxChildDiagramFactors } from '../model/groupBoxChildDiagramFactors';
import { GroupBoxChildDiagramFactorsMoveInfo } from '../model/groupBoxChildDiagramFactorsMoveInfo';
import { GroupBoxNew } from '../model/groupBoxNew';
import { GroupBoxUpdate } from '../model/groupBoxUpdate';
import { ProblemDetail } from '../model/problemDetail';
import { ProjectDiagram } from '../model/projectDiagram';
import { ProjectDiagramCopyInfo } from '../model/projectDiagramCopyInfo';
import { ProjectDiagramFactor } from '../model/projectDiagramFactor';
import { ProjectDiagramFactorBulkUpdate } from '../model/projectDiagramFactorBulkUpdate';
import { ProjectDiagramFactorChain } from '../model/projectDiagramFactorChain';
import { ProjectDiagramFactorChainCopyInfo } from '../model/projectDiagramFactorChainCopyInfo';
import { ProjectDiagramFactorChainDeleteInfo } from '../model/projectDiagramFactorChainDeleteInfo';
import { ProjectDiagramFactorCopyInfo } from '../model/projectDiagramFactorCopyInfo';
import { ProjectDiagramFactorNameUpdate } from '../model/projectDiagramFactorNameUpdate';
import { ProjectDiagramFactorNew } from '../model/projectDiagramFactorNew';
import { ProjectDiagramFactorUpdate } from '../model/projectDiagramFactorUpdate';
import { ProjectDiagramGuide } from '../model/projectDiagramGuide';
import { ProjectDiagramGuideContent } from '../model/projectDiagramGuideContent';
import { ProjectDiagramImage } from '../model/projectDiagramImage';
import { ProjectDiagramInfo } from '../model/projectDiagramInfo';
import { ProjectDiagramLink } from '../model/projectDiagramLink';
import { ProjectDiagramLinkBulkUpdate } from '../model/projectDiagramLinkBulkUpdate';
import { ProjectDiagramLinkNew } from '../model/projectDiagramLinkNew';
import { ProjectDiagramLinkUpdate } from '../model/projectDiagramLinkUpdate';
import { ProjectDiagramNew } from '../model/projectDiagramNew';
import { ProjectDiagramTag } from '../model/projectDiagramTag';
import { ProjectDiagramTagNew } from '../model/projectDiagramTagNew';
import { ProjectDiagramUpdate } from '../model/projectDiagramUpdate';
import { ProjectDiagramZOrder } from '../model/projectDiagramZOrder';
import { ProjectDiagramZOrderUpdate } from '../model/projectDiagramZOrderUpdate';
import { ScopeBox } from '../model/scopeBox';
import { ScopeBoxNew } from '../model/scopeBoxNew';
import { ScopeBoxUpdate } from '../model/scopeBoxUpdate';
import { SubAssumption } from '../model/subAssumption';
import { SubAssumptionNew } from '../model/subAssumptionNew';
import { SubAssumptionRelevantFactors } from '../model/subAssumptionRelevantFactors';
import { TextBox } from '../model/textBox';
import { TextBoxNew } from '../model/textBoxNew';
import { TextBoxUpdate } from '../model/textBoxUpdate';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

import { CaseUtils } from 'app/shared/utils';



@Injectable({
  providedIn: 'root'
})
export class ProjectDiagramService {

    protected basePath = 'http://localhost/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.configuration.withCredentials = true;
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    public copyProjectDiagramRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Copy diagram (between projects)
     * Copy diagram (to another project)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectDiagramCopyInfo Diagram copy info
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyProjectDiagram(projectIdentifier: string, resourceIdentifier: string, projectDiagramCopyInfo: ProjectDiagramCopyInfo, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagram>;
    public copyProjectDiagram(projectIdentifier: string, resourceIdentifier: string, projectDiagramCopyInfo: ProjectDiagramCopyInfo, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagram>>;
    public copyProjectDiagram(projectIdentifier: string, resourceIdentifier: string, projectDiagramCopyInfo: ProjectDiagramCopyInfo, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagram>>;
    public copyProjectDiagram(projectIdentifier: string, resourceIdentifier: string, projectDiagramCopyInfo: ProjectDiagramCopyInfo, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling copyProjectDiagram.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling copyProjectDiagram.');
        }
        if (projectDiagramCopyInfo === null || projectDiagramCopyInfo === undefined) {
            throw new Error('Required parameter projectDiagramCopyInfo was null or undefined when calling copyProjectDiagram.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagram>(`${this.configuration.basePath}/projectdiagramcopy/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramCopyInfo),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagram(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagram(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public copyProjectDiagramFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Copy diagram factor to same or different diagram
     * Copy diagram factor to same or different diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param diagramFactorResourceIdentifier Diagram Factor Unique identifier
     * @param projectDiagramFactorCopyInfo New diagram info
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramFactorCopyInfo: ProjectDiagramFactorCopyInfo, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactor>;
    public copyProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramFactorCopyInfo: ProjectDiagramFactorCopyInfo, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactor>>;
    public copyProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramFactorCopyInfo: ProjectDiagramFactorCopyInfo, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactor>>;
    public copyProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramFactorCopyInfo: ProjectDiagramFactorCopyInfo, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling copyProjectDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling copyProjectDiagramFactor.');
        }
        if (diagramFactorResourceIdentifier === null || diagramFactorResourceIdentifier === undefined) {
            throw new Error('Required parameter diagramFactorResourceIdentifier was null or undefined when calling copyProjectDiagramFactor.');
        }
        if (projectDiagramFactorCopyInfo === null || projectDiagramFactorCopyInfo === undefined) {
            throw new Error('Required parameter projectDiagramFactorCopyInfo was null or undefined when calling copyProjectDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramFactor>(`${this.configuration.basePath}/projectdiagramfactorcopy/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(diagramFactorResourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramFactorCopyInfo),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public copyProjectDiagramFactorChainRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Copy diagram content to same or different diagram
     * Copy diagram content to same or different diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectDiagramFactorChainCopyInfo New diagram info
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainCopyInfo: ProjectDiagramFactorChainCopyInfo, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactorChain>;
    public copyProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainCopyInfo: ProjectDiagramFactorChainCopyInfo, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactorChain>>;
    public copyProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainCopyInfo: ProjectDiagramFactorChainCopyInfo, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactorChain>>;
    public copyProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainCopyInfo: ProjectDiagramFactorChainCopyInfo, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling copyProjectDiagramFactorChain.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling copyProjectDiagramFactorChain.');
        }
        if (projectDiagramFactorChainCopyInfo === null || projectDiagramFactorChainCopyInfo === undefined) {
            throw new Error('Required parameter projectDiagramFactorChainCopyInfo was null or undefined when calling copyProjectDiagramFactorChain.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramFactorChain>(`${this.configuration.basePath}/projectdiagramfactorchaincopy/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramFactorChainCopyInfo),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactorChain(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactorChain(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createAssumptionRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create assumption for Project Diagram
     * Add new Assumption for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param assumptionNew New assumption
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionNew: AssumptionNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactor>;
    public createAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionNew: AssumptionNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactor>>;
    public createAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionNew: AssumptionNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactor>>;
    public createAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionNew: AssumptionNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createAssumption.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createAssumption.');
        }
        if (assumptionNew === null || assumptionNew === undefined) {
            throw new Error('Required parameter assumptionNew was null or undefined when calling createAssumption.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramFactor>(`${this.configuration.basePath}/assumptions/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(assumptionNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createAssumptionSubAssumptionRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create Assumption Sub Assumption
     * Add new Sub Assumption for Assumption
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param subAssumptionNew New Sub Assumption for Assumption
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionNew: SubAssumptionNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SubAssumption>;
    public createAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionNew: SubAssumptionNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SubAssumption>>;
    public createAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionNew: SubAssumptionNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SubAssumption>>;
    public createAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionNew: SubAssumptionNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createAssumptionSubAssumption.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createAssumptionSubAssumption.');
        }
        if (subAssumptionNew === null || subAssumptionNew === undefined) {
            throw new Error('Required parameter subAssumptionNew was null or undefined when calling createAssumptionSubAssumption.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<SubAssumption>(`${this.configuration.basePath}/assumptionsubassumptions/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(subAssumptionNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new SubAssumption(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new SubAssumption(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createGroupBoxRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create group box for Project Diagram
     * Add new group box for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param groupBoxNew New group box
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxNew: GroupBoxNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactor>;
    public createGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxNew: GroupBoxNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactor>>;
    public createGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxNew: GroupBoxNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactor>>;
    public createGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxNew: GroupBoxNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createGroupBox.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createGroupBox.');
        }
        if (groupBoxNew === null || groupBoxNew === undefined) {
            throw new Error('Required parameter groupBoxNew was null or undefined when calling createGroupBox.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramFactor>(`${this.configuration.basePath}/groupboxes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(groupBoxNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectDiagramRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create diagram
     * Add new diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectDiagramNew New diagram factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectDiagram(projectIdentifier: string, projectDiagramNew: ProjectDiagramNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagram>;
    public createProjectDiagram(projectIdentifier: string, projectDiagramNew: ProjectDiagramNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagram>>;
    public createProjectDiagram(projectIdentifier: string, projectDiagramNew: ProjectDiagramNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagram>>;
    public createProjectDiagram(projectIdentifier: string, projectDiagramNew: ProjectDiagramNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectDiagram.');
        }
        if (projectDiagramNew === null || projectDiagramNew === undefined) {
            throw new Error('Required parameter projectDiagramNew was null or undefined when calling createProjectDiagram.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagram>(`${this.configuration.basePath}/projectdiagrams/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectDiagramNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagram(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagram(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectDiagramFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create diagram factor
     * Add new diagram factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectDiagramFactorNew New diagram factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorNew: ProjectDiagramFactorNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactor>;
    public createProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorNew: ProjectDiagramFactorNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactor>>;
    public createProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorNew: ProjectDiagramFactorNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactor>>;
    public createProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorNew: ProjectDiagramFactorNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createProjectDiagramFactor.');
        }
        if (projectDiagramFactorNew === null || projectDiagramFactorNew === undefined) {
            throw new Error('Required parameter projectDiagramFactorNew was null or undefined when calling createProjectDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramFactor>(`${this.configuration.basePath}/projectdiagramfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramFactorNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectDiagramGuideRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create diagram guide
     * Add new diagram guide
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectDiagramGuideContent New diagram guide
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectDiagramGuide(projectIdentifier: string, projectDiagramGuideContent: ProjectDiagramGuideContent, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramGuide>;
    public createProjectDiagramGuide(projectIdentifier: string, projectDiagramGuideContent: ProjectDiagramGuideContent, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramGuide>>;
    public createProjectDiagramGuide(projectIdentifier: string, projectDiagramGuideContent: ProjectDiagramGuideContent, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramGuide>>;
    public createProjectDiagramGuide(projectIdentifier: string, projectDiagramGuideContent: ProjectDiagramGuideContent, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectDiagramGuide.');
        }
        if (projectDiagramGuideContent === null || projectDiagramGuideContent === undefined) {
            throw new Error('Required parameter projectDiagramGuideContent was null or undefined when calling createProjectDiagramGuide.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramGuide>(`${this.configuration.basePath}/projectdiagramguides/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectDiagramGuideContent),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramGuide(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramGuide(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectDiagramLinkRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create diagram link
     * Add new diagram link
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectDiagramLinkNew New diagram link
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, projectDiagramLinkNew: ProjectDiagramLinkNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramLink>;
    public createProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, projectDiagramLinkNew: ProjectDiagramLinkNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramLink>>;
    public createProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, projectDiagramLinkNew: ProjectDiagramLinkNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramLink>>;
    public createProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, projectDiagramLinkNew: ProjectDiagramLinkNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectDiagramLink.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createProjectDiagramLink.');
        }
        if (projectDiagramLinkNew === null || projectDiagramLinkNew === undefined) {
            throw new Error('Required parameter projectDiagramLinkNew was null or undefined when calling createProjectDiagramLink.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramLink>(`${this.configuration.basePath}/projectdiagramlinks/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramLinkNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramLink(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramLink(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createProjectDiagramTagRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create diagram tag for Project
     * Add new diagram tag for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param projectDiagramTagNew New diagram tag
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProjectDiagramTag(projectIdentifier: string, projectDiagramTagNew: ProjectDiagramTagNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramTag>;
    public createProjectDiagramTag(projectIdentifier: string, projectDiagramTagNew: ProjectDiagramTagNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramTag>>;
    public createProjectDiagramTag(projectIdentifier: string, projectDiagramTagNew: ProjectDiagramTagNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramTag>>;
    public createProjectDiagramTag(projectIdentifier: string, projectDiagramTagNew: ProjectDiagramTagNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createProjectDiagramTag.');
        }
        if (projectDiagramTagNew === null || projectDiagramTagNew === undefined) {
            throw new Error('Required parameter projectDiagramTagNew was null or undefined when calling createProjectDiagramTag.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramTag>(`${this.configuration.basePath}/projectdiagramtags/${encodeURIComponent(String(projectIdentifier))}`,
            CaseUtils.toSnake(projectDiagramTagNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramTag(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramTag(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createScopeBoxRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create scope box for Project Diagram
     * Add new scope box for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param scopeBoxNew New scope box
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxNew: ScopeBoxNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactor>;
    public createScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxNew: ScopeBoxNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactor>>;
    public createScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxNew: ScopeBoxNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactor>>;
    public createScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxNew: ScopeBoxNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createScopeBox.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createScopeBox.');
        }
        if (scopeBoxNew === null || scopeBoxNew === undefined) {
            throw new Error('Required parameter scopeBoxNew was null or undefined when calling createScopeBox.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramFactor>(`${this.configuration.basePath}/scopeboxes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(scopeBoxNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createStrategyActivityDiagramFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create diagram factor for Activity (i.e. show Activity on Project diagram)
     * Add diagram factor for Activity for specific Project diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param projectDiagramFactorUpdate New diagram factor for Activity
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStrategyActivityDiagramFactor(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactor>;
    public createStrategyActivityDiagramFactor(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactor>>;
    public createStrategyActivityDiagramFactor(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactor>>;
    public createStrategyActivityDiagramFactor(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createStrategyActivityDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createStrategyActivityDiagramFactor.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling createStrategyActivityDiagramFactor.');
        }
        if (projectDiagramFactorUpdate === null || projectDiagramFactorUpdate === undefined) {
            throw new Error('Required parameter projectDiagramFactorUpdate was null or undefined when calling createStrategyActivityDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramFactor>(`${this.configuration.basePath}/strategyactivitydiagramfactor/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramFactorUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createStressDiagramFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create diagram factor for Stress (i.e. show Stress on Project diagram)
     * Add diagram factor for Stress for specific Project diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param stressResourceIdentifier Stress Unique identifier
     * @param projectDiagramFactorUpdate New diagram factor for Stress
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createStressDiagramFactor(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactor>;
    public createStressDiagramFactor(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactor>>;
    public createStressDiagramFactor(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactor>>;
    public createStressDiagramFactor(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createStressDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createStressDiagramFactor.');
        }
        if (stressResourceIdentifier === null || stressResourceIdentifier === undefined) {
            throw new Error('Required parameter stressResourceIdentifier was null or undefined when calling createStressDiagramFactor.');
        }
        if (projectDiagramFactorUpdate === null || projectDiagramFactorUpdate === undefined) {
            throw new Error('Required parameter projectDiagramFactorUpdate was null or undefined when calling createStressDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramFactor>(`${this.configuration.basePath}/stressdiagramfactor/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(stressResourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramFactorUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createSubAssumptionDiagramFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create diagram factor for Sub Assumption (i.e. show Sub Assumption on Project diagram)
     * Add diagram factor for Sub Assumption for specific Project diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param subAssumptionResourceIdentifier Sub Assumption Unique identifier
     * @param projectDiagramFactorUpdate New diagram factor for Sub Assumption
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createSubAssumptionDiagramFactor(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactor>;
    public createSubAssumptionDiagramFactor(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactor>>;
    public createSubAssumptionDiagramFactor(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactor>>;
    public createSubAssumptionDiagramFactor(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createSubAssumptionDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createSubAssumptionDiagramFactor.');
        }
        if (subAssumptionResourceIdentifier === null || subAssumptionResourceIdentifier === undefined) {
            throw new Error('Required parameter subAssumptionResourceIdentifier was null or undefined when calling createSubAssumptionDiagramFactor.');
        }
        if (projectDiagramFactorUpdate === null || projectDiagramFactorUpdate === undefined) {
            throw new Error('Required parameter projectDiagramFactorUpdate was null or undefined when calling createSubAssumptionDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramFactor>(`${this.configuration.basePath}/subassumptiondiagramfactor/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(subAssumptionResourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramFactorUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public createTextBoxRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Create text box for Project Diagram
     * Add new text box for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param textBoxNew New text box
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxNew: TextBoxNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactor>;
    public createTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxNew: TextBoxNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactor>>;
    public createTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxNew: TextBoxNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactor>>;
    public createTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxNew: TextBoxNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling createTextBox.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling createTextBox.');
        }
        if (textBoxNew === null || textBoxNew === undefined) {
            throw new Error('Required parameter textBoxNew was null or undefined when calling createTextBox.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramFactor>(`${this.configuration.basePath}/textboxes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(textBoxNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteAssumptionRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete assumption
     * Delete specific assumption for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param assumptionResourceIdentifier Assumption Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteAssumption.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteAssumption.');
        }
        if (assumptionResourceIdentifier === null || assumptionResourceIdentifier === undefined) {
            throw new Error('Required parameter assumptionResourceIdentifier was null or undefined when calling deleteAssumption.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/assumptions/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(assumptionResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteAssumptionSubAssumptionRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Assumption Sub Assumption
     * Delete specific Sub Assumption for Assumption
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param subAssumptionResourceIdentifier Sub Assumption Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteAssumptionSubAssumption.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteAssumptionSubAssumption.');
        }
        if (subAssumptionResourceIdentifier === null || subAssumptionResourceIdentifier === undefined) {
            throw new Error('Required parameter subAssumptionResourceIdentifier was null or undefined when calling deleteAssumptionSubAssumption.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/assumptionsubassumptions/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(subAssumptionResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteGroupBoxRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete group box
     * Delete specific group box for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param groupBoxResourceIdentifier Group box Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteGroupBox.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteGroupBox.');
        }
        if (groupBoxResourceIdentifier === null || groupBoxResourceIdentifier === undefined) {
            throw new Error('Required parameter groupBoxResourceIdentifier was null or undefined when calling deleteGroupBox.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/groupboxes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(groupBoxResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectDiagramRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete diagram
     * Delete diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectDiagram(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectDiagram(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectDiagram(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectDiagram(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectDiagram.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectDiagram.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectdiagrams/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectDiagramFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete diagram factor
     * Delete diagram factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param diagramFactorResourceIdentifier Diagram Factor Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectDiagramFactor.');
        }
        if (diagramFactorResourceIdentifier === null || diagramFactorResourceIdentifier === undefined) {
            throw new Error('Required parameter diagramFactorResourceIdentifier was null or undefined when calling deleteProjectDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectdiagramfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(diagramFactorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectDiagramFactorChainRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete diagram content
     * Delete diagram content
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectDiagramFactorChainDeleteInfo Diagram content info to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainDeleteInfo: ProjectDiagramFactorChainDeleteInfo, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainDeleteInfo: ProjectDiagramFactorChainDeleteInfo, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainDeleteInfo: ProjectDiagramFactorChainDeleteInfo, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainDeleteInfo: ProjectDiagramFactorChainDeleteInfo, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectDiagramFactorChain.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectDiagramFactorChain.');
        }
        if (projectDiagramFactorChainDeleteInfo === null || projectDiagramFactorChainDeleteInfo === undefined) {
            throw new Error('Required parameter projectDiagramFactorChainDeleteInfo was null or undefined when calling deleteProjectDiagramFactorChain.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/projectdiagramfactorchaindelete/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramFactorChainDeleteInfo),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectDiagramGuideRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete diagram guide
     * Delete diagram guide
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectDiagramGuide(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectDiagramGuide(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectDiagramGuide(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectDiagramGuide(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectDiagramGuide.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectDiagramGuide.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectdiagramguides/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectDiagramLinkRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete diagram link
     * Delete diagram link
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param diagramLinkResourceIdentifier Diagram Link Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, diagramLinkResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, diagramLinkResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, diagramLinkResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, diagramLinkResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectDiagramLink.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectDiagramLink.');
        }
        if (diagramLinkResourceIdentifier === null || diagramLinkResourceIdentifier === undefined) {
            throw new Error('Required parameter diagramLinkResourceIdentifier was null or undefined when calling deleteProjectDiagramLink.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectdiagramlinks/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(diagramLinkResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteProjectDiagramTagRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete Project diagram tag
     * Delete specific diagram tag for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProjectDiagramTag(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteProjectDiagramTag(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteProjectDiagramTag(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteProjectDiagramTag(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteProjectDiagramTag.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteProjectDiagramTag.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/projectdiagramtags/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteScopeBoxRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete scope box
     * Delete specific scope box for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param scopeBoxResourceIdentifier Scope box Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteScopeBox.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteScopeBox.');
        }
        if (scopeBoxResourceIdentifier === null || scopeBoxResourceIdentifier === undefined) {
            throw new Error('Required parameter scopeBoxResourceIdentifier was null or undefined when calling deleteScopeBox.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/scopeboxes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(scopeBoxResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteStrategyActivityDiagramFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete diagram factor for Activity (i.e. hide Activity on Project diagram)
     * Remove diagram factor for Activity for specific Project diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStrategyActivityDiagramFactor(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteStrategyActivityDiagramFactor(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteStrategyActivityDiagramFactor(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteStrategyActivityDiagramFactor(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteStrategyActivityDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteStrategyActivityDiagramFactor.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling deleteStrategyActivityDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/strategyactivitydiagramfactor/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteStressDiagramFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete diagram factor for Stress (i.e. hide Stress on Project diagram)
     * Remove diagram factor for Stress for specific Project diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param stressResourceIdentifier Stress Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteStressDiagramFactor(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteStressDiagramFactor(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteStressDiagramFactor(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteStressDiagramFactor(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteStressDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteStressDiagramFactor.');
        }
        if (stressResourceIdentifier === null || stressResourceIdentifier === undefined) {
            throw new Error('Required parameter stressResourceIdentifier was null or undefined when calling deleteStressDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/stressdiagramfactor/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(stressResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteSubAssumptionDiagramFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete diagram factor for Sub Assumption (i.e. hide Sub Assumption on Project diagram)
     * Remove diagram factor for Sub Assumption for specific Project diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param subAssumptionResourceIdentifier Sub Assumption Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSubAssumptionDiagramFactor(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteSubAssumptionDiagramFactor(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteSubAssumptionDiagramFactor(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteSubAssumptionDiagramFactor(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteSubAssumptionDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteSubAssumptionDiagramFactor.');
        }
        if (subAssumptionResourceIdentifier === null || subAssumptionResourceIdentifier === undefined) {
            throw new Error('Required parameter subAssumptionResourceIdentifier was null or undefined when calling deleteSubAssumptionDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/subassumptiondiagramfactor/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(subAssumptionResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public deleteTextBoxRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Delete text box
     * Delete specific text box for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param textBoxResourceIdentifier Text box Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling deleteTextBox.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling deleteTextBox.');
        }
        if (textBoxResourceIdentifier === null || textBoxResourceIdentifier === undefined) {
            throw new Error('Required parameter textBoxResourceIdentifier was null or undefined when calling deleteTextBox.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/textboxes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(textBoxResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public generateProjectDiagramFromGuideRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Generate project diagram from guide
     * Generate project diagram from specific diagram guide
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateProjectDiagramFromGuide(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagram>;
    public generateProjectDiagramFromGuide(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagram>>;
    public generateProjectDiagramFromGuide(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagram>>;
    public generateProjectDiagramFromGuide(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling generateProjectDiagramFromGuide.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling generateProjectDiagramFromGuide.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagram>(`${this.configuration.basePath}/projectdiagramguides/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagram(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagram(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getAssumptionRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get assumption
     * Get specific assumption for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param assumptionResourceIdentifier Assumption Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Assumption>;
    public getAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Assumption>>;
    public getAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Assumption>>;
    public getAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getAssumption.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getAssumption.');
        }
        if (assumptionResourceIdentifier === null || assumptionResourceIdentifier === undefined) {
            throw new Error('Required parameter assumptionResourceIdentifier was null or undefined when calling getAssumption.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Assumption>(`${this.configuration.basePath}/assumptions/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(assumptionResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Assumption(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Assumption(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getAssumptionRelevantFactorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get relevant factors for Assumption
     * Get relevant factors for Assumption
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<AssumptionRelevantFactors>;
    public getAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<AssumptionRelevantFactors>>;
    public getAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<AssumptionRelevantFactors>>;
    public getAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getAssumptionRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getAssumptionRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<AssumptionRelevantFactors>(`${this.configuration.basePath}/assumptionrelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new AssumptionRelevantFactors(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new AssumptionRelevantFactors(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getAssumptionSubAssumptionRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Assumption Sub Assumption
     * Get specific Sub Assumption for Assumption
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param subAssumptionResourceIdentifier Sub Assumption Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SubAssumption>;
    public getAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SubAssumption>>;
    public getAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SubAssumption>>;
    public getAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getAssumptionSubAssumption.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getAssumptionSubAssumption.');
        }
        if (subAssumptionResourceIdentifier === null || subAssumptionResourceIdentifier === undefined) {
            throw new Error('Required parameter subAssumptionResourceIdentifier was null or undefined when calling getAssumptionSubAssumption.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<SubAssumption>(`${this.configuration.basePath}/assumptionsubassumptions/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(subAssumptionResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new SubAssumption(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new SubAssumption(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getAssumptionSubAssumptionsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Assumption Sub Assumptions
     * Get list of Sub Assumptions for Assumption
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssumptionSubAssumptions(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<SubAssumption>>;
    public getAssumptionSubAssumptions(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<SubAssumption>>>;
    public getAssumptionSubAssumptions(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<SubAssumption>>>;
    public getAssumptionSubAssumptions(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getAssumptionSubAssumptions.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getAssumptionSubAssumptions.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<SubAssumption>>(`${this.configuration.basePath}/assumptionsubassumptions/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new SubAssumption(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new SubAssumption(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getAssumptionsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Assumption
     * Get Assumptions for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssumptions(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<Assumption>>;
    public getAssumptions(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<Assumption>>>;
    public getAssumptions(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<Assumption>>>;
    public getAssumptions(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getAssumptions.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getAssumptions.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<Assumption>>(`${this.configuration.basePath}/assumptions/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new Assumption(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new Assumption(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getGroupBoxRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get group box
     * Get specific group box for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param groupBoxResourceIdentifier Group box Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GroupBox>;
    public getGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GroupBox>>;
    public getGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GroupBox>>;
    public getGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getGroupBox.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getGroupBox.');
        }
        if (groupBoxResourceIdentifier === null || groupBoxResourceIdentifier === undefined) {
            throw new Error('Required parameter groupBoxResourceIdentifier was null or undefined when calling getGroupBox.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<GroupBox>(`${this.configuration.basePath}/groupboxes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(groupBoxResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new GroupBox(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new GroupBox(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getGroupBoxDiagramFactorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get group box diagram factors
     * Get child diagram factors for group box
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param diagramFactorResourceIdentifier Diagram Factor Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGroupBoxDiagramFactors(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GroupBoxChildDiagramFactors>;
    public getGroupBoxDiagramFactors(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GroupBoxChildDiagramFactors>>;
    public getGroupBoxDiagramFactors(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GroupBoxChildDiagramFactors>>;
    public getGroupBoxDiagramFactors(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getGroupBoxDiagramFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getGroupBoxDiagramFactors.');
        }
        if (diagramFactorResourceIdentifier === null || diagramFactorResourceIdentifier === undefined) {
            throw new Error('Required parameter diagramFactorResourceIdentifier was null or undefined when calling getGroupBoxDiagramFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<GroupBoxChildDiagramFactors>(`${this.configuration.basePath}/groupboxdiagramfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(diagramFactorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new GroupBoxChildDiagramFactors(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new GroupBoxChildDiagramFactors(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getGroupBoxesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get group boxes
     * Get group boxes for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGroupBoxes(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<GroupBox>>;
    public getGroupBoxes(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<GroupBox>>>;
    public getGroupBoxes(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<GroupBox>>>;
    public getGroupBoxes(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getGroupBoxes.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getGroupBoxes.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<GroupBox>>(`${this.configuration.basePath}/groupboxes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new GroupBox(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new GroupBox(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project diagram
     * Get Project diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagram(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagram>;
    public getProjectDiagram(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagram>>;
    public getProjectDiagram(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagram>>;
    public getProjectDiagram(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagram.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectDiagram.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectDiagram>(`${this.configuration.basePath}/projectdiagrams/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagram(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagram(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramColorPalettesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get list of diagram color palettes
     * Get list of diagram color palettes for project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagramColorPalettes(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<DiagramColorPalette>>;
    public getProjectDiagramColorPalettes(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<DiagramColorPalette>>>;
    public getProjectDiagramColorPalettes(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<DiagramColorPalette>>>;
    public getProjectDiagramColorPalettes(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagramColorPalettes.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<DiagramColorPalette>>(`${this.configuration.basePath}/projectdiagramcolorpalettes/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new DiagramColorPalette(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new DiagramColorPalette(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramFactorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get diagram factor
     * Get diagram factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param diagramFactorResourceIdentifier Diagram Factor Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactor>;
    public getProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactor>>;
    public getProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactor>>;
    public getProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectDiagramFactor.');
        }
        if (diagramFactorResourceIdentifier === null || diagramFactorResourceIdentifier === undefined) {
            throw new Error('Required parameter diagramFactorResourceIdentifier was null or undefined when calling getProjectDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectDiagramFactor>(`${this.configuration.basePath}/projectdiagramfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(diagramFactorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramFactorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project diagram factors
     * Get list of diagram factors for Project diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagramFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectDiagramFactor>>;
    public getProjectDiagramFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectDiagramFactor>>>;
    public getProjectDiagramFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectDiagramFactor>>>;
    public getProjectDiagramFactors(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagramFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectDiagramFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectDiagramFactor>>(`${this.configuration.basePath}/projectdiagramfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramGuideRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project diagram guide
     * Get Project diagram guide
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagramGuide(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramGuide>;
    public getProjectDiagramGuide(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramGuide>>;
    public getProjectDiagramGuide(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramGuide>>;
    public getProjectDiagramGuide(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagramGuide.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectDiagramGuide.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectDiagramGuide>(`${this.configuration.basePath}/projectdiagramguides/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramGuide(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramGuide(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramGuidesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project diagram guides
     * Get Project diagram guides
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagramGuides(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectDiagramGuide>>;
    public getProjectDiagramGuides(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectDiagramGuide>>>;
    public getProjectDiagramGuides(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectDiagramGuide>>>;
    public getProjectDiagramGuides(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagramGuides.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectDiagramGuide>>(`${this.configuration.basePath}/projectdiagramguides/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramGuide(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramGuide(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramImagesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project diagram images
     * Get list of project diagram images for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagramImages(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectDiagramImage>>;
    public getProjectDiagramImages(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectDiagramImage>>>;
    public getProjectDiagramImages(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectDiagramImage>>>;
    public getProjectDiagramImages(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagramImages.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectDiagramImage>>(`${this.configuration.basePath}/projectdiagramimages/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramImage(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramImage(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramLinkRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get diagram link
     * Get diagram link
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param diagramLinkResourceIdentifier Diagram Link Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, diagramLinkResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramLink>;
    public getProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, diagramLinkResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramLink>>;
    public getProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, diagramLinkResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramLink>>;
    public getProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, diagramLinkResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagramLink.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectDiagramLink.');
        }
        if (diagramLinkResourceIdentifier === null || diagramLinkResourceIdentifier === undefined) {
            throw new Error('Required parameter diagramLinkResourceIdentifier was null or undefined when calling getProjectDiagramLink.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectDiagramLink>(`${this.configuration.basePath}/projectdiagramlinks/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(diagramLinkResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramLink(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramLink(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramLinksRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project diagram links
     * Get list of diagram links for Project diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagramLinks(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectDiagramLink>>;
    public getProjectDiagramLinks(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectDiagramLink>>>;
    public getProjectDiagramLinks(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectDiagramLink>>>;
    public getProjectDiagramLinks(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagramLinks.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectDiagramLinks.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectDiagramLink>>(`${this.configuration.basePath}/projectdiagramlinks/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramLink(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramLink(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramTagRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project diagram tag
     * Get specific diagram tag for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagramTag(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramTag>;
    public getProjectDiagramTag(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramTag>>;
    public getProjectDiagramTag(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramTag>>;
    public getProjectDiagramTag(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagramTag.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectDiagramTag.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectDiagramTag>(`${this.configuration.basePath}/projectdiagramtags/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramTag(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramTag(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramTagsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project diagram tags
     * Get list of diagram tags for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagramTags(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectDiagramTag>>;
    public getProjectDiagramTags(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectDiagramTag>>>;
    public getProjectDiagramTags(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectDiagramTag>>>;
    public getProjectDiagramTags(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagramTags.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectDiagramTag>>(`${this.configuration.basePath}/projectdiagramtags/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramTag(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramTag(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramZOrderRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project Diagram z-order
     * Returns project diagram z-order
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagramZOrder(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramZOrder>;
    public getProjectDiagramZOrder(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramZOrder>>;
    public getProjectDiagramZOrder(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramZOrder>>;
    public getProjectDiagramZOrder(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagramZOrder.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getProjectDiagramZOrder.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectDiagramZOrder>(`${this.configuration.basePath}/projectdiagramzorder/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramZOrder(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramZOrder(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectDiagramsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get Project diagrams
     * Get Project diagrams
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectDiagrams(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProjectDiagram>>;
    public getProjectDiagrams(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProjectDiagram>>>;
    public getProjectDiagrams(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProjectDiagram>>>;
    public getProjectDiagrams(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectDiagrams.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProjectDiagram>>(`${this.configuration.basePath}/projectdiagrams/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagram(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagram(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getScopeBoxRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get scope box
     * Get specific scope box for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param scopeBoxResourceIdentifier Scope box Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ScopeBox>;
    public getScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ScopeBox>>;
    public getScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ScopeBox>>;
    public getScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getScopeBox.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getScopeBox.');
        }
        if (scopeBoxResourceIdentifier === null || scopeBoxResourceIdentifier === undefined) {
            throw new Error('Required parameter scopeBoxResourceIdentifier was null or undefined when calling getScopeBox.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ScopeBox>(`${this.configuration.basePath}/scopeboxes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(scopeBoxResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ScopeBox(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ScopeBox(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getScopeBoxesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get scope box
     * Get scope boxes for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getScopeBoxes(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ScopeBox>>;
    public getScopeBoxes(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ScopeBox>>>;
    public getScopeBoxes(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ScopeBox>>>;
    public getScopeBoxes(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getScopeBoxes.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getScopeBoxes.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ScopeBox>>(`${this.configuration.basePath}/scopeboxes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ScopeBox(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ScopeBox(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStrategyActivityDiagramFactorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get diagram factor for Activity
     * Get diagram factor for Activity for specific Project diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param activityResourceIdentifier Activity Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStrategyActivityDiagramFactor(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactor>;
    public getStrategyActivityDiagramFactor(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactor>>;
    public getStrategyActivityDiagramFactor(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactor>>;
    public getStrategyActivityDiagramFactor(projectIdentifier: string, resourceIdentifier: string, activityResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStrategyActivityDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStrategyActivityDiagramFactor.');
        }
        if (activityResourceIdentifier === null || activityResourceIdentifier === undefined) {
            throw new Error('Required parameter activityResourceIdentifier was null or undefined when calling getStrategyActivityDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectDiagramFactor>(`${this.configuration.basePath}/strategyactivitydiagramfactor/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(activityResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getStressDiagramFactorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get diagram factor for Stress
     * Get diagram factor for Stress for specific Project diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param stressResourceIdentifier Stress Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStressDiagramFactor(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactor>;
    public getStressDiagramFactor(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactor>>;
    public getStressDiagramFactor(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactor>>;
    public getStressDiagramFactor(projectIdentifier: string, resourceIdentifier: string, stressResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getStressDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getStressDiagramFactor.');
        }
        if (stressResourceIdentifier === null || stressResourceIdentifier === undefined) {
            throw new Error('Required parameter stressResourceIdentifier was null or undefined when calling getStressDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectDiagramFactor>(`${this.configuration.basePath}/stressdiagramfactor/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(stressResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getSubAssumptionDiagramFactorRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get diagram factor for Sub Assumption
     * Get diagram factor for Sub Assumption for specific Project diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param subAssumptionResourceIdentifier Sub Assumption Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubAssumptionDiagramFactor(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactor>;
    public getSubAssumptionDiagramFactor(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactor>>;
    public getSubAssumptionDiagramFactor(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactor>>;
    public getSubAssumptionDiagramFactor(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getSubAssumptionDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getSubAssumptionDiagramFactor.');
        }
        if (subAssumptionResourceIdentifier === null || subAssumptionResourceIdentifier === undefined) {
            throw new Error('Required parameter subAssumptionResourceIdentifier was null or undefined when calling getSubAssumptionDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectDiagramFactor>(`${this.configuration.basePath}/subassumptiondiagramfactor/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(subAssumptionResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getSubAssumptionRelevantFactorsRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get relevant factors for Sub Assumption
     * Get relevant factors for Sub Assumption
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSubAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<SubAssumptionRelevantFactors>;
    public getSubAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<SubAssumptionRelevantFactors>>;
    public getSubAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<SubAssumptionRelevantFactors>>;
    public getSubAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getSubAssumptionRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getSubAssumptionRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<SubAssumptionRelevantFactors>(`${this.configuration.basePath}/subassumptionrelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new SubAssumptionRelevantFactors(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new SubAssumptionRelevantFactors(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getSupportedDiagramFactorTypesRequiredPermission() {
        return 'ViewProjectBasic' || '';
    }

    /**
     * Find supported diagram factor types for Project
     * Returns supported diagram factor types for a specific Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSupportedDiagramFactorTypes(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<DiagramFactorTypeDict>>;
    public getSupportedDiagramFactorTypes(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<DiagramFactorTypeDict>>>;
    public getSupportedDiagramFactorTypes(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<DiagramFactorTypeDict>>>;
    public getSupportedDiagramFactorTypes(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getSupportedDiagramFactorTypes.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<DiagramFactorTypeDict>>(`${this.configuration.basePath}/diagramfactortypes/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new DiagramFactorTypeDict(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new DiagramFactorTypeDict(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getTextBoxRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get text box
     * Get specific text box for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param textBoxResourceIdentifier Text box Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<TextBox>;
    public getTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<TextBox>>;
    public getTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<TextBox>>;
    public getTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getTextBox.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getTextBox.');
        }
        if (textBoxResourceIdentifier === null || textBoxResourceIdentifier === undefined) {
            throw new Error('Required parameter textBoxResourceIdentifier was null or undefined when calling getTextBox.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<TextBox>(`${this.configuration.basePath}/textboxes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(textBoxResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new TextBox(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new TextBox(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getTextBoxesRequiredPermission() {
        return 'ViewProjectDetail' || '';
    }

    /**
     * Get text boxes
     * Get text boxes for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTextBoxes(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<TextBox>>;
    public getTextBoxes(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<TextBox>>>;
    public getTextBoxes(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<TextBox>>>;
    public getTextBoxes(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getTextBoxes.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling getTextBoxes.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<TextBox>>(`${this.configuration.basePath}/textboxes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new TextBox(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new TextBox(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public moveGroupBoxDiagramFactorsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Move group box child diagram factors to different group box (same diagram)
     * Move group box child diagram factors to different group box (same diagram)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param diagramFactorResourceIdentifier Diagram Factor Unique identifier
     * @param groupBoxChildDiagramFactorsMoveInfo New parent Group Box info
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public moveGroupBoxDiagramFactors(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, groupBoxChildDiagramFactorsMoveInfo: GroupBoxChildDiagramFactorsMoveInfo, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public moveGroupBoxDiagramFactors(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, groupBoxChildDiagramFactorsMoveInfo: GroupBoxChildDiagramFactorsMoveInfo, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public moveGroupBoxDiagramFactors(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, groupBoxChildDiagramFactorsMoveInfo: GroupBoxChildDiagramFactorsMoveInfo, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public moveGroupBoxDiagramFactors(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, groupBoxChildDiagramFactorsMoveInfo: GroupBoxChildDiagramFactorsMoveInfo, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling moveGroupBoxDiagramFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling moveGroupBoxDiagramFactors.');
        }
        if (diagramFactorResourceIdentifier === null || diagramFactorResourceIdentifier === undefined) {
            throw new Error('Required parameter diagramFactorResourceIdentifier was null or undefined when calling moveGroupBoxDiagramFactors.');
        }
        if (groupBoxChildDiagramFactorsMoveInfo === null || groupBoxChildDiagramFactorsMoveInfo === undefined) {
            throw new Error('Required parameter groupBoxChildDiagramFactorsMoveInfo was null or undefined when calling moveGroupBoxDiagramFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/groupboxdiagramfactorsmove/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(diagramFactorResourceIdentifier))}`,
            CaseUtils.toSnake(groupBoxChildDiagramFactorsMoveInfo),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public moveProjectDiagramFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Move diagram factor to different diagram
     * Move diagram factor to different diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param diagramFactorResourceIdentifier Diagram Factor Unique identifier
     * @param projectDiagramInfo New diagram info
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public moveProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramInfo: ProjectDiagramInfo, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactor>;
    public moveProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramInfo: ProjectDiagramInfo, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactor>>;
    public moveProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramInfo: ProjectDiagramInfo, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactor>>;
    public moveProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramInfo: ProjectDiagramInfo, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling moveProjectDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling moveProjectDiagramFactor.');
        }
        if (diagramFactorResourceIdentifier === null || diagramFactorResourceIdentifier === undefined) {
            throw new Error('Required parameter diagramFactorResourceIdentifier was null or undefined when calling moveProjectDiagramFactor.');
        }
        if (projectDiagramInfo === null || projectDiagramInfo === undefined) {
            throw new Error('Required parameter projectDiagramInfo was null or undefined when calling moveProjectDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramFactor>(`${this.configuration.basePath}/projectdiagramfactormove/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(diagramFactorResourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramInfo),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public moveProjectDiagramFactorChainRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Move diagram content to different diagram
     * Move diagram content to different diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectDiagramFactorChainCopyInfo New diagram info
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public moveProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainCopyInfo: ProjectDiagramFactorChainCopyInfo, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactorChain>;
    public moveProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainCopyInfo: ProjectDiagramFactorChainCopyInfo, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactorChain>>;
    public moveProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainCopyInfo: ProjectDiagramFactorChainCopyInfo, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactorChain>>;
    public moveProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainCopyInfo: ProjectDiagramFactorChainCopyInfo, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling moveProjectDiagramFactorChain.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling moveProjectDiagramFactorChain.');
        }
        if (projectDiagramFactorChainCopyInfo === null || projectDiagramFactorChainCopyInfo === undefined) {
            throw new Error('Required parameter projectDiagramFactorChainCopyInfo was null or undefined when calling moveProjectDiagramFactorChain.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramFactorChain>(`${this.configuration.basePath}/projectdiagramfactorchainmove/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramFactorChainCopyInfo),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactorChain(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactorChain(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public shareProjectDiagramFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Share diagram factor to different diagram
     * Share diagram factor to different diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param diagramFactorResourceIdentifier Diagram Factor Unique identifier
     * @param projectDiagramInfo New diagram info
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shareProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramInfo: ProjectDiagramInfo, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactor>;
    public shareProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramInfo: ProjectDiagramInfo, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactor>>;
    public shareProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramInfo: ProjectDiagramInfo, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactor>>;
    public shareProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramInfo: ProjectDiagramInfo, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling shareProjectDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling shareProjectDiagramFactor.');
        }
        if (diagramFactorResourceIdentifier === null || diagramFactorResourceIdentifier === undefined) {
            throw new Error('Required parameter diagramFactorResourceIdentifier was null or undefined when calling shareProjectDiagramFactor.');
        }
        if (projectDiagramInfo === null || projectDiagramInfo === undefined) {
            throw new Error('Required parameter projectDiagramInfo was null or undefined when calling shareProjectDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramFactor>(`${this.configuration.basePath}/projectdiagramfactorshare/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(diagramFactorResourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramInfo),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactor(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactor(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public shareProjectDiagramFactorChainRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Share diagram content to different diagram
     * Share diagram content to different diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectDiagramFactorChainCopyInfo New diagram info
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shareProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainCopyInfo: ProjectDiagramFactorChainCopyInfo, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramFactorChain>;
    public shareProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainCopyInfo: ProjectDiagramFactorChainCopyInfo, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramFactorChain>>;
    public shareProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainCopyInfo: ProjectDiagramFactorChainCopyInfo, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramFactorChain>>;
    public shareProjectDiagramFactorChain(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorChainCopyInfo: ProjectDiagramFactorChainCopyInfo, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling shareProjectDiagramFactorChain.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling shareProjectDiagramFactorChain.');
        }
        if (projectDiagramFactorChainCopyInfo === null || projectDiagramFactorChainCopyInfo === undefined) {
            throw new Error('Required parameter projectDiagramFactorChainCopyInfo was null or undefined when calling shareProjectDiagramFactorChain.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramFactorChain>(`${this.configuration.basePath}/projectdiagramfactorchainshare/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramFactorChainCopyInfo),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramFactorChain(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramFactorChain(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateAssumptionRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update assumption
     * Update specific assumption for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param assumptionResourceIdentifier Assumption Unique identifier
     * @param assumptionUpdate Updated assumption
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionResourceIdentifier: string, assumptionUpdate: AssumptionUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionResourceIdentifier: string, assumptionUpdate: AssumptionUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionResourceIdentifier: string, assumptionUpdate: AssumptionUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateAssumption(projectIdentifier: string, resourceIdentifier: string, assumptionResourceIdentifier: string, assumptionUpdate: AssumptionUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateAssumption.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateAssumption.');
        }
        if (assumptionResourceIdentifier === null || assumptionResourceIdentifier === undefined) {
            throw new Error('Required parameter assumptionResourceIdentifier was null or undefined when calling updateAssumption.');
        }
        if (assumptionUpdate === null || assumptionUpdate === undefined) {
            throw new Error('Required parameter assumptionUpdate was null or undefined when calling updateAssumption.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/assumptions/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(assumptionResourceIdentifier))}`,
            CaseUtils.toSnake(assumptionUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateAssumptionRelevantFactorsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update relevant factors for Assumption
     * Update relevant factors for Assumption
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param assumptionRelevantFactors Updated relevant factors for Assumption
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, assumptionRelevantFactors: AssumptionRelevantFactors, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, assumptionRelevantFactors: AssumptionRelevantFactors, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, assumptionRelevantFactors: AssumptionRelevantFactors, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, assumptionRelevantFactors: AssumptionRelevantFactors, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateAssumptionRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateAssumptionRelevantFactors.');
        }
        if (assumptionRelevantFactors === null || assumptionRelevantFactors === undefined) {
            throw new Error('Required parameter assumptionRelevantFactors was null or undefined when calling updateAssumptionRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/assumptionrelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(assumptionRelevantFactors),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateAssumptionSubAssumptionRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Assumption Sub Assumption
     * Update specific Sub Assumption for Assumption
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param subAssumptionResourceIdentifier Sub Assumption Unique identifier
     * @param subAssumptionNew Updated Sub Assumption for Assumption
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, subAssumptionNew: SubAssumptionNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, subAssumptionNew: SubAssumptionNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, subAssumptionNew: SubAssumptionNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateAssumptionSubAssumption(projectIdentifier: string, resourceIdentifier: string, subAssumptionResourceIdentifier: string, subAssumptionNew: SubAssumptionNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateAssumptionSubAssumption.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateAssumptionSubAssumption.');
        }
        if (subAssumptionResourceIdentifier === null || subAssumptionResourceIdentifier === undefined) {
            throw new Error('Required parameter subAssumptionResourceIdentifier was null or undefined when calling updateAssumptionSubAssumption.');
        }
        if (subAssumptionNew === null || subAssumptionNew === undefined) {
            throw new Error('Required parameter subAssumptionNew was null or undefined when calling updateAssumptionSubAssumption.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/assumptionsubassumptions/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(subAssumptionResourceIdentifier))}`,
            CaseUtils.toSnake(subAssumptionNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateGroupBoxRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update group box
     * Update specific group box for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param groupBoxResourceIdentifier Group box Unique identifier
     * @param groupBoxUpdate Updated group box
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxResourceIdentifier: string, groupBoxUpdate: GroupBoxUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxResourceIdentifier: string, groupBoxUpdate: GroupBoxUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxResourceIdentifier: string, groupBoxUpdate: GroupBoxUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateGroupBox(projectIdentifier: string, resourceIdentifier: string, groupBoxResourceIdentifier: string, groupBoxUpdate: GroupBoxUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateGroupBox.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateGroupBox.');
        }
        if (groupBoxResourceIdentifier === null || groupBoxResourceIdentifier === undefined) {
            throw new Error('Required parameter groupBoxResourceIdentifier was null or undefined when calling updateGroupBox.');
        }
        if (groupBoxUpdate === null || groupBoxUpdate === undefined) {
            throw new Error('Required parameter groupBoxUpdate was null or undefined when calling updateGroupBox.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/groupboxes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(groupBoxResourceIdentifier))}`,
            CaseUtils.toSnake(groupBoxUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateGroupBoxDiagramFactorsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update group box diagram factors
     * Update child diagram factors for group box
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param diagramFactorResourceIdentifier Diagram Factor Unique identifier
     * @param groupBoxChildDiagramFactors Updated child diagram factors for Group Box
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGroupBoxDiagramFactors(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, groupBoxChildDiagramFactors: GroupBoxChildDiagramFactors, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateGroupBoxDiagramFactors(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, groupBoxChildDiagramFactors: GroupBoxChildDiagramFactors, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateGroupBoxDiagramFactors(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, groupBoxChildDiagramFactors: GroupBoxChildDiagramFactors, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateGroupBoxDiagramFactors(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, groupBoxChildDiagramFactors: GroupBoxChildDiagramFactors, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateGroupBoxDiagramFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateGroupBoxDiagramFactors.');
        }
        if (diagramFactorResourceIdentifier === null || diagramFactorResourceIdentifier === undefined) {
            throw new Error('Required parameter diagramFactorResourceIdentifier was null or undefined when calling updateGroupBoxDiagramFactors.');
        }
        if (groupBoxChildDiagramFactors === null || groupBoxChildDiagramFactors === undefined) {
            throw new Error('Required parameter groupBoxChildDiagramFactors was null or undefined when calling updateGroupBoxDiagramFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/groupboxdiagramfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(diagramFactorResourceIdentifier))}`,
            CaseUtils.toSnake(groupBoxChildDiagramFactors),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectDiagramRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project diagram
     * Update specific diagram for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectDiagramUpdate Updated project diagram
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectDiagram(projectIdentifier: string, resourceIdentifier: string, projectDiagramUpdate: ProjectDiagramUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectDiagram(projectIdentifier: string, resourceIdentifier: string, projectDiagramUpdate: ProjectDiagramUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectDiagram(projectIdentifier: string, resourceIdentifier: string, projectDiagramUpdate: ProjectDiagramUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectDiagram(projectIdentifier: string, resourceIdentifier: string, projectDiagramUpdate: ProjectDiagramUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectDiagram.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectDiagram.');
        }
        if (projectDiagramUpdate === null || projectDiagramUpdate === undefined) {
            throw new Error('Required parameter projectDiagramUpdate was null or undefined when calling updateProjectDiagram.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectdiagrams/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectDiagramFactorRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update diagram factor
     * Update diagram factor
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param diagramFactorResourceIdentifier Diagram Factor Unique identifier
     * @param projectDiagramFactorUpdate Updated diagram factor
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectDiagramFactor(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramFactorUpdate: ProjectDiagramFactorUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectDiagramFactor.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectDiagramFactor.');
        }
        if (diagramFactorResourceIdentifier === null || diagramFactorResourceIdentifier === undefined) {
            throw new Error('Required parameter diagramFactorResourceIdentifier was null or undefined when calling updateProjectDiagramFactor.');
        }
        if (projectDiagramFactorUpdate === null || projectDiagramFactorUpdate === undefined) {
            throw new Error('Required parameter projectDiagramFactorUpdate was null or undefined when calling updateProjectDiagramFactor.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectdiagramfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(diagramFactorResourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramFactorUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectDiagramFactorNameRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update diagram factor name
     * Update diagram factor identifier / name
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param diagramFactorResourceIdentifier Diagram Factor Unique identifier
     * @param projectDiagramFactorNameUpdate Updated diagram factor identifier / name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectDiagramFactorName(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramFactorNameUpdate: ProjectDiagramFactorNameUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectDiagramFactorName(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramFactorNameUpdate: ProjectDiagramFactorNameUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectDiagramFactorName(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramFactorNameUpdate: ProjectDiagramFactorNameUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectDiagramFactorName(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, projectDiagramFactorNameUpdate: ProjectDiagramFactorNameUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectDiagramFactorName.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectDiagramFactorName.');
        }
        if (diagramFactorResourceIdentifier === null || diagramFactorResourceIdentifier === undefined) {
            throw new Error('Required parameter diagramFactorResourceIdentifier was null or undefined when calling updateProjectDiagramFactorName.');
        }
        if (projectDiagramFactorNameUpdate === null || projectDiagramFactorNameUpdate === undefined) {
            throw new Error('Required parameter projectDiagramFactorNameUpdate was null or undefined when calling updateProjectDiagramFactorName.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectdiagramfactorname/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(diagramFactorResourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramFactorNameUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectDiagramFactorsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update diagram factors
     * Update diagram factors
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectDiagramFactorBulkUpdate Updated diagram factors
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectDiagramFactors(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorBulkUpdate: ProjectDiagramFactorBulkUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectDiagramFactors(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorBulkUpdate: ProjectDiagramFactorBulkUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectDiagramFactors(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorBulkUpdate: ProjectDiagramFactorBulkUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectDiagramFactors(projectIdentifier: string, resourceIdentifier: string, projectDiagramFactorBulkUpdate: ProjectDiagramFactorBulkUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectDiagramFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectDiagramFactors.');
        }
        if (projectDiagramFactorBulkUpdate === null || projectDiagramFactorBulkUpdate === undefined) {
            throw new Error('Required parameter projectDiagramFactorBulkUpdate was null or undefined when calling updateProjectDiagramFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectdiagramfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramFactorBulkUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectDiagramGuideRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project diagram guide
     * Update specific diagram guide for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectDiagramGuideContent Updated project diagram guide
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectDiagramGuide(projectIdentifier: string, resourceIdentifier: string, projectDiagramGuideContent: ProjectDiagramGuideContent, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectDiagramGuide(projectIdentifier: string, resourceIdentifier: string, projectDiagramGuideContent: ProjectDiagramGuideContent, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectDiagramGuide(projectIdentifier: string, resourceIdentifier: string, projectDiagramGuideContent: ProjectDiagramGuideContent, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectDiagramGuide(projectIdentifier: string, resourceIdentifier: string, projectDiagramGuideContent: ProjectDiagramGuideContent, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectDiagramGuide.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectDiagramGuide.');
        }
        if (projectDiagramGuideContent === null || projectDiagramGuideContent === undefined) {
            throw new Error('Required parameter projectDiagramGuideContent was null or undefined when calling updateProjectDiagramGuide.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectdiagramguides/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramGuideContent),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectDiagramLinkRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update diagram link
     * Update diagram link
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param diagramLinkResourceIdentifier Diagram Link Unique identifier
     * @param projectDiagramLinkUpdate Updated diagram link
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, diagramLinkResourceIdentifier: string, projectDiagramLinkUpdate: ProjectDiagramLinkUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, diagramLinkResourceIdentifier: string, projectDiagramLinkUpdate: ProjectDiagramLinkUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, diagramLinkResourceIdentifier: string, projectDiagramLinkUpdate: ProjectDiagramLinkUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectDiagramLink(projectIdentifier: string, resourceIdentifier: string, diagramLinkResourceIdentifier: string, projectDiagramLinkUpdate: ProjectDiagramLinkUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectDiagramLink.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectDiagramLink.');
        }
        if (diagramLinkResourceIdentifier === null || diagramLinkResourceIdentifier === undefined) {
            throw new Error('Required parameter diagramLinkResourceIdentifier was null or undefined when calling updateProjectDiagramLink.');
        }
        if (projectDiagramLinkUpdate === null || projectDiagramLinkUpdate === undefined) {
            throw new Error('Required parameter projectDiagramLinkUpdate was null or undefined when calling updateProjectDiagramLink.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectdiagramlinks/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(diagramLinkResourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramLinkUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectDiagramLinksRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update diagram links
     * Update diagram links
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectDiagramLinkBulkUpdate Updated diagram link
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectDiagramLinks(projectIdentifier: string, resourceIdentifier: string, projectDiagramLinkBulkUpdate: ProjectDiagramLinkBulkUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectDiagramLinks(projectIdentifier: string, resourceIdentifier: string, projectDiagramLinkBulkUpdate: ProjectDiagramLinkBulkUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectDiagramLinks(projectIdentifier: string, resourceIdentifier: string, projectDiagramLinkBulkUpdate: ProjectDiagramLinkBulkUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectDiagramLinks(projectIdentifier: string, resourceIdentifier: string, projectDiagramLinkBulkUpdate: ProjectDiagramLinkBulkUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectDiagramLinks.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectDiagramLinks.');
        }
        if (projectDiagramLinkBulkUpdate === null || projectDiagramLinkBulkUpdate === undefined) {
            throw new Error('Required parameter projectDiagramLinkBulkUpdate was null or undefined when calling updateProjectDiagramLinks.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectdiagramlinks/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramLinkBulkUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectDiagramTagRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project diagram tag
     * Update specific diagram tag for Project
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectDiagramTagNew Updated diagram tag
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectDiagramTag(projectIdentifier: string, resourceIdentifier: string, projectDiagramTagNew: ProjectDiagramTagNew, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateProjectDiagramTag(projectIdentifier: string, resourceIdentifier: string, projectDiagramTagNew: ProjectDiagramTagNew, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateProjectDiagramTag(projectIdentifier: string, resourceIdentifier: string, projectDiagramTagNew: ProjectDiagramTagNew, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateProjectDiagramTag(projectIdentifier: string, resourceIdentifier: string, projectDiagramTagNew: ProjectDiagramTagNew, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectDiagramTag.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectDiagramTag.');
        }
        if (projectDiagramTagNew === null || projectDiagramTagNew === undefined) {
            throw new Error('Required parameter projectDiagramTagNew was null or undefined when calling updateProjectDiagramTag.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/projectdiagramtags/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramTagNew),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateProjectDiagramZOrderRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update Project Diagram z-order
     * Update project diagram z-order
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param projectDiagramZOrderUpdate Diagram z-order update instructions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProjectDiagramZOrder(projectIdentifier: string, resourceIdentifier: string, projectDiagramZOrderUpdate: ProjectDiagramZOrderUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectDiagramZOrder>;
    public updateProjectDiagramZOrder(projectIdentifier: string, resourceIdentifier: string, projectDiagramZOrderUpdate: ProjectDiagramZOrderUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectDiagramZOrder>>;
    public updateProjectDiagramZOrder(projectIdentifier: string, resourceIdentifier: string, projectDiagramZOrderUpdate: ProjectDiagramZOrderUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectDiagramZOrder>>;
    public updateProjectDiagramZOrder(projectIdentifier: string, resourceIdentifier: string, projectDiagramZOrderUpdate: ProjectDiagramZOrderUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateProjectDiagramZOrder.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateProjectDiagramZOrder.');
        }
        if (projectDiagramZOrderUpdate === null || projectDiagramZOrderUpdate === undefined) {
            throw new Error('Required parameter projectDiagramZOrderUpdate was null or undefined when calling updateProjectDiagramZOrder.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<ProjectDiagramZOrder>(`${this.configuration.basePath}/projectdiagramzorder/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(projectDiagramZOrderUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectDiagramZOrder(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectDiagramZOrder(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateScopeBoxRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update scope box
     * Update specific scope box for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param scopeBoxResourceIdentifier Scope box Unique identifier
     * @param scopeBoxUpdate Updated scope box
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxResourceIdentifier: string, scopeBoxUpdate: ScopeBoxUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxResourceIdentifier: string, scopeBoxUpdate: ScopeBoxUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxResourceIdentifier: string, scopeBoxUpdate: ScopeBoxUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateScopeBox(projectIdentifier: string, resourceIdentifier: string, scopeBoxResourceIdentifier: string, scopeBoxUpdate: ScopeBoxUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateScopeBox.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateScopeBox.');
        }
        if (scopeBoxResourceIdentifier === null || scopeBoxResourceIdentifier === undefined) {
            throw new Error('Required parameter scopeBoxResourceIdentifier was null or undefined when calling updateScopeBox.');
        }
        if (scopeBoxUpdate === null || scopeBoxUpdate === undefined) {
            throw new Error('Required parameter scopeBoxUpdate was null or undefined when calling updateScopeBox.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/scopeboxes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(scopeBoxResourceIdentifier))}`,
            CaseUtils.toSnake(scopeBoxUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateSubAssumptionRelevantFactorsRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update relevant factors for Sub Assumption
     * Update relevant factors for Sub Assumption
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param subAssumptionRelevantFactors Updated relevant factors for Sub Assumption
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSubAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, subAssumptionRelevantFactors: SubAssumptionRelevantFactors, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateSubAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, subAssumptionRelevantFactors: SubAssumptionRelevantFactors, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateSubAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, subAssumptionRelevantFactors: SubAssumptionRelevantFactors, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateSubAssumptionRelevantFactors(projectIdentifier: string, resourceIdentifier: string, subAssumptionRelevantFactors: SubAssumptionRelevantFactors, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateSubAssumptionRelevantFactors.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateSubAssumptionRelevantFactors.');
        }
        if (subAssumptionRelevantFactors === null || subAssumptionRelevantFactors === undefined) {
            throw new Error('Required parameter subAssumptionRelevantFactors was null or undefined when calling updateSubAssumptionRelevantFactors.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/subassumptionrelevantfactors/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            CaseUtils.toSnake(subAssumptionRelevantFactors),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateTextBoxRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Update text box
     * Update specific text box for Project Diagram
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param textBoxResourceIdentifier Text box Unique identifier
     * @param textBoxUpdate Updated text box
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxResourceIdentifier: string, textBoxUpdate: TextBoxUpdate, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxResourceIdentifier: string, textBoxUpdate: TextBoxUpdate, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxResourceIdentifier: string, textBoxUpdate: TextBoxUpdate, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateTextBox(projectIdentifier: string, resourceIdentifier: string, textBoxResourceIdentifier: string, textBoxUpdate: TextBoxUpdate, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling updateTextBox.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling updateTextBox.');
        }
        if (textBoxResourceIdentifier === null || textBoxResourceIdentifier === undefined) {
            throw new Error('Required parameter textBoxResourceIdentifier was null or undefined when calling updateTextBox.');
        }
        if (textBoxUpdate === null || textBoxUpdate === undefined) {
            throw new Error('Required parameter textBoxUpdate was null or undefined when calling updateTextBox.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/textboxes/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(textBoxResourceIdentifier))}`,
            CaseUtils.toSnake(textBoxUpdate),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public validateDiagramProjectFactorDeleteRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get Project Diagram Factor delete info
     * Returns flag indicating whether diagram factor (for wrapped factor) can be deleted without warning or not
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param diagramFactorResourceIdentifier Diagram Factor Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateDiagramProjectFactorDelete(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FactorDeleteInfo>;
    public validateDiagramProjectFactorDelete(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FactorDeleteInfo>>;
    public validateDiagramProjectFactorDelete(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FactorDeleteInfo>>;
    public validateDiagramProjectFactorDelete(projectIdentifier: string, resourceIdentifier: string, diagramFactorResourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling validateDiagramProjectFactorDelete.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling validateDiagramProjectFactorDelete.');
        }
        if (diagramFactorResourceIdentifier === null || diagramFactorResourceIdentifier === undefined) {
            throw new Error('Required parameter diagramFactorResourceIdentifier was null or undefined when calling validateDiagramProjectFactorDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FactorDeleteInfo>(`${this.configuration.basePath}/projectdiagramfactorvalidatedelete/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}/${encodeURIComponent(String(diagramFactorResourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new FactorDeleteInfo(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new FactorDeleteInfo(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public validateProjectDiagramDeleteRequiredPermission() {
        return 'EditProjectInfo' || '';
    }

    /**
     * Get Project Diagram delete info
     * Returns flag indicating whether diagram can be deleted without warning or not
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param resourceIdentifier Resource Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateProjectDiagramDelete(projectIdentifier: string, resourceIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<DiagramDeleteInfo>;
    public validateProjectDiagramDelete(projectIdentifier: string, resourceIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<DiagramDeleteInfo>>;
    public validateProjectDiagramDelete(projectIdentifier: string, resourceIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<DiagramDeleteInfo>>;
    public validateProjectDiagramDelete(projectIdentifier: string, resourceIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling validateProjectDiagramDelete.');
        }
        if (resourceIdentifier === null || resourceIdentifier === undefined) {
            throw new Error('Required parameter resourceIdentifier was null or undefined when calling validateProjectDiagramDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<DiagramDeleteInfo>(`${this.configuration.basePath}/projectdiagramvalidatedelete/${encodeURIComponent(String(projectIdentifier))}/${encodeURIComponent(String(resourceIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new DiagramDeleteInfo(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new DiagramDeleteInfo(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

}
