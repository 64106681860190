/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi desktop folder
 */
import { ValidateUtils } from 'app/shared/utils';

export class MiradiDesktopDownloadFolder { 
    /**
     * Folder name
     */
    folder?: string;
    /**
     * Folder description
     */
    description?: string;
    /**
     * Folder contents (aaray of MiradiDesktopDownloadFolder or MiradiDesktopDownloadFile)
     */
    contents?: Array<object>;

    constructor(item?: Partial<MiradiDesktopDownloadFolder>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            folder: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.folder : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            description: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.description : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            contents: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.contents : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: MiradiDesktopDownloadFolder.isValid,
        };
    }

    static isValid(obj: MiradiDesktopDownloadFolder): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = MiradiDesktopDownloadFolder.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.folder.isValid(item);
            result = result && validationObject.description.isValid(item);
            result = result && validationObject.contents.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

