/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi Share User preferences
 */
import { ValidateUtils } from 'app/shared/utils';

export class UserPreferences { 
    /**
     * Client-side json for user preferences
     */
    value?: object;

    constructor(item?: Partial<UserPreferences>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            value: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.value : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: UserPreferences.isValid,
        };
    }

    static isValid(obj: UserPreferences): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = UserPreferences.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.value.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

