/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FactorInfo } from './factorInfo';
import { IndicatorScorecardAggregationMethodEnum } from './indicatorScorecardAggregationMethodEnum';
import { IndicatorScorecardMeasurementCategoryEnum } from './indicatorScorecardMeasurementCategoryEnum';
import { TaxonomyClassificationNew } from './taxonomyClassificationNew';


/**
 * Indicator Scorecard
 */
import { ValidateUtils } from 'app/shared/utils';

export class IndicatorScorecard { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    /**
     * Scorecard name
     */
    readonly name?: string;
    /**
     * Scorecard description
     */
    readonly description?: string;
    factor?: FactorInfo;
    parentFactor?: FactorInfo;
    rollupTaxonomyClassifications?: TaxonomyClassificationNew;
    /**
     * Unique identifier for Indicator primary aggregation Taxonomy Version
     */
    readonly primaryAggregationTaxonomyVersionResourceIdentifier?: string;
    /**
     * Unique identifier for Indicator secondary aggregation Taxonomy Version
     */
    readonly secondaryAggregationTaxonomyVersionResourceIdentifier?: string;
    aggregationMethod?: IndicatorScorecardAggregationMethodEnum;
    measurementCategory?: IndicatorScorecardMeasurementCategoryEnum;
    /**
     * Date scorecard last updated
     */
    readonly lastUpdatedDate?: string;
    /**
     * Resource identifier for User who last updated scorecard (if applicable)
     */
    readonly lastUpdatedByResourceIdentifier?: string;
    /**
     * Full name of User who who last updated scorecard (if applicable)
     */
    readonly lastUpdatedByFullName?: string;

    constructor(item?: Partial<IndicatorScorecard>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 36, 36);
                    }

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            description: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.description : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factor: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factor : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            parentFactor: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.parentFactor : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            rollupTaxonomyClassifications: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.rollupTaxonomyClassifications : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            primaryAggregationTaxonomyVersionResourceIdentifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.primaryAggregationTaxonomyVersionResourceIdentifier : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            secondaryAggregationTaxonomyVersionResourceIdentifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.secondaryAggregationTaxonomyVersionResourceIdentifier : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            aggregationMethod: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.aggregationMethod : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            measurementCategory: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.measurementCategory : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            lastUpdatedDate: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastUpdatedDate : itemOrValue;

                    if (itemOrValue?.lastUpdatedDate != null) {
                        if (itemOrValue.lastUpdatedDate === '') {
                            itemOrValue.lastUpdatedDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.lastUpdatedDate = parseInt(itemOrValue.lastUpdatedDate.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.lastUpdatedDate = parseFloat(itemOrValue.lastUpdatedDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    if (['uuid'].indexOf('date-time') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            lastUpdatedByResourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastUpdatedByResourceIdentifier : itemOrValue;

                    if (itemOrValue?.lastUpdatedByResourceIdentifier != null) {
                        if (itemOrValue.lastUpdatedByResourceIdentifier === '') {
                            itemOrValue.lastUpdatedByResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.lastUpdatedByResourceIdentifier = parseInt(itemOrValue.lastUpdatedByResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.lastUpdatedByResourceIdentifier = parseFloat(itemOrValue.lastUpdatedByResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            lastUpdatedByFullName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastUpdatedByFullName : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: IndicatorScorecard.isValid,
        };
    }

    static isValid(obj: IndicatorScorecard): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = IndicatorScorecard.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.description.isValid(item);
            result = result && validationObject.factor.isValid(item);
            result = result && validationObject.parentFactor.isValid(item);
            result = result && validationObject.rollupTaxonomyClassifications.isValid(item);
            result = result && validationObject.primaryAggregationTaxonomyVersionResourceIdentifier.isValid(item);
            result = result && validationObject.secondaryAggregationTaxonomyVersionResourceIdentifier.isValid(item);
            result = result && validationObject.aggregationMethod.isValid(item);
            result = result && validationObject.measurementCategory.isValid(item);
            result = result && validationObject.lastUpdatedDate.isValid(item);
            result = result && validationObject.lastUpdatedByResourceIdentifier.isValid(item);
            result = result && validationObject.lastUpdatedByFullName.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

