/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Project resource type
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ProjectResourceTypeEnum = 'Person' | 'Group';

export const ProjectResourceTypeEnum = {
    Person: 'Person' as ProjectResourceTypeEnum,
    Group: 'Group' as ProjectResourceTypeEnum
};

marker('Person');
marker('Group');

