import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InfoDialog } from './info.dialog';


@NgModule({
  declarations: [
    InfoDialog,
  ],
  exports: [
    InfoDialog,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    TranslateModule,
  ],
})
export class InfoModule { }
