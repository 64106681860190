/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Program-level feature to which permission can be granted
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ProgramFeature = 'ViewProgramBasics' | 'ViewProgramMembers' | 'CreateProject' | 'EditProgram';

export const ProgramFeature = {
    ViewProgramBasics: 'ViewProgramBasics' as ProgramFeature,
    ViewProgramMembers: 'ViewProgramMembers' as ProgramFeature,
    CreateProject: 'CreateProject' as ProgramFeature,
    EditProgram: 'EditProgram' as ProgramFeature
};



