export class ValidateUtils {

  static isValidType(value: any, type: string): boolean {
    if (!type || !value || typeof value !== 'string') return true;

    if (['int32', 'int64'].indexOf(type) >= 0) {
      return !isNaN(parseInt(value.toString(), 10)) && isFinite(value as any);
    } else if (['float'].indexOf(type) >= 0) {
      return !isNaN(parseFloat(value.toString())) && isFinite(value as any);
    } else if (['email'].indexOf(type) >= 0) {
      return /[^@\s]+@[^@\s]+/.test(value);
    } else if (['uri'].indexOf(type) >= 0) {
      return /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value);
    } else if (['uuid'].indexOf(type) >= 0) {
      return /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/i.test(value);
    } else if (['date', 'date-time'].indexOf(type) >= 0) {
      return !isNaN((new Date(value)).getTime());
    }

    return true;
  }

  static isValidLength(value: any, minLength?: number, maxLength?: number): boolean {
    let result = true;

    if (minLength != null) {
      result = result && (value || '').length >= minLength;
    }
    if (maxLength != null) {
      result = result && (value || '').length <= maxLength;
    }

    return result;
  }

}
