/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TimeframeDateUnitEnum } from './timeframeDateUnitEnum';


/**
 * Strategy / Activity timeframe. The start / end dates on new / update will be validated as follows - for \'Full Project Timespan\' both start / end date will be ignored; for \'Year\' the month component of the end date should be the month prior to that of the start date (day component will be ignored); for \'Quarter\' the month component of the start / end date should align with the corresponding start / end month of the quarter (again, day component will be ignored). In all cases the end date should be on or after the start date.
 */
import { ValidateUtils } from 'app/shared/utils';

export class FactorTimeframeDetail { 
    timeframeDateUnitType?: TimeframeDateUnitEnum;
    /**
     * Timeframe start date
     */
    startDate?: string;
    /**
     * Timeframe end date
     */
    endDate?: string;

    constructor(item?: Partial<FactorTimeframeDetail>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            timeframeDateUnitType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.timeframeDateUnitType : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            startDate: {
                type: 'date',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.startDate : itemOrValue;

                    if (itemOrValue?.startDate != null) {
                        if (itemOrValue.startDate === '') {
                            itemOrValue.startDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date') >= 0) {
                            itemOrValue.startDate = parseInt(itemOrValue.startDate.toString(), 10);
                        } else if (['float'].indexOf('date') >= 0) {
                            itemOrValue.startDate = parseFloat(itemOrValue.startDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date');
                    if (['uuid'].indexOf('date') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            endDate: {
                type: 'date',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.endDate : itemOrValue;

                    if (itemOrValue?.endDate != null) {
                        if (itemOrValue.endDate === '') {
                            itemOrValue.endDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date') >= 0) {
                            itemOrValue.endDate = parseInt(itemOrValue.endDate.toString(), 10);
                        } else if (['float'].indexOf('date') >= 0) {
                            itemOrValue.endDate = parseFloat(itemOrValue.endDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date');
                    if (['uuid'].indexOf('date') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: FactorTimeframeDetail.isValid,
        };
    }

    static isValid(obj: FactorTimeframeDetail): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = FactorTimeframeDetail.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.timeframeDateUnitType.isValid(item);
            result = result && validationObject.startDate.isValid(item);
            result = result && validationObject.endDate.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

