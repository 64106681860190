import { HttpClient } from '@angular/common/http';
import { LanguageService, TranslatePoLoader, TranslatePoParser } from 'app/shared/services';

const DEPLOY_URL = '/static/dist';

export function createTranslateLoader(http: HttpClient) {
	return new TranslatePoLoader(
    http,
    DEPLOY_URL + '/assets/l10n',
    '.po',
  );
}

export function createTranslateParser(languageService: LanguageService) {
	return new TranslatePoParser(languageService);
}
