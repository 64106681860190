/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TimelineEvent } from './timelineEvent';


/**
 * Timeline for Project
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectTimeline { 
    /**
     * Unique identifier
     */
    readonly identifier?: string;
    /**
     * Project name
     */
    readonly name?: string;
    /**
     * List of timeline events
     */
    readonly timelineEvents?: Array<TimelineEvent>;

    constructor(item?: Partial<ProjectTimeline>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            identifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            timelineEvents: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.timelineEvents : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProjectTimeline.isValid,
        };
    }

    static isValid(obj: ProjectTimeline): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectTimeline.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.timelineEvents.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

