import { SelectItem } from 'primeng/api';

export class EnumUtils {

  static toClassString(enumValue: string) {
    return (enumValue || '').replace(/ /g, '-').replace(/\(/g, '').replace(/\)/g, '');
  }

  static toLabelString(enumValue: string, translateService?: any) {
    if (!enumValue) return '';

    let result = enumValue; // do some enum -> label mapping here if required...
    if (!translateService) return result;

    return translateService.instant(result);
  }

  static toIdentifierString(enumValue: string) {
    return (enumValue || '').toLowerCase().replace(/[\()]/g, '').replace(/ /g, '-');
  }

  static toStringArray(enumerator: any) {
    const result: string[] = [];
    for (const key in enumerator){
      if (typeof enumerator[key] === 'number') {
        result.push(key.replace(/_/g, ' '));
      }
    }

    if (!result.length) {
      // open api generated "enums"
      for (const key in enumerator){
        result.push(enumerator[key]);
      }
    }
    return result.sort((a: string, b: string) => {
      return (a || '').localeCompare(b || '');
    });
  }

  static toSelectItemArray(enumerator: any, translateService?: any) {
    const result: SelectItem[] = [];
    for (const key in enumerator) {
      if (typeof enumerator[key] === 'number') {
        result.push({
          label: translateService ? translateService.instant(key.replace(/_/g, ' ')) : key.replace(/_/g, ' '),
          value: enumerator[key],
        });
      }
    }

    if (!result.length) {
      // if no results were returned from the above for loop, it means the enum value is a string...
      // so just return all
      for (const key in enumerator) {
        result.push({
          label: translateService ? translateService.instant(enumerator[key].replace(/_/g, ' ')) : enumerator[key].replace(/_/g, ' '),
          value: enumerator[key],
        });
      }
    }
    return result;
  }

}
