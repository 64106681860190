import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../api-generated';
import { AuthService } from '../auth/auth.service';
import { NotificationService } from '../notification/notification.service';



@Injectable({
  providedIn: 'root'
})
export class AuthRouteGuardService  {

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.getSessionUser(true)
    .pipe(
      map((user: User) => {
        if (user) {
          return true;
        } else {
          this.authService.loginOrRegister('login');

          return false;
        }
      }),
      catchError((error: any) => {
        this.notificationService.apiError(error);

        return of(this.router.createUrlTree(['/']));
      })
    );
  }
}
