/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Measurement / future status ratings
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ViabilityRatingEnum = 'Poor' | 'Fair' | 'Good' | 'Very Good';

export const ViabilityRatingEnum = {
    Poor: 'Poor' as ViabilityRatingEnum,
    Fair: 'Fair' as ViabilityRatingEnum,
    Good: 'Good' as ViabilityRatingEnum,
    VeryGood: 'Very Good' as ViabilityRatingEnum
};

marker('Poor');
marker('Fair');
marker('Good');
marker('Very Good');

