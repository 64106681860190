<div class="form-field flex-col gap-1"
  [class.invalid]="!checkValidity(inputElement)"
  (click)="isTouched = true;">

  <div *ngIf="hint || (label && (type !== 'checkbox' || checkboxForceEmptyLabel))"
    class="flex-col">
    <div class="flex-row middle">
      <label *ngIf="label"
        class="label"
        [innerHTML]="(type !== 'checkbox' || !editActive) ? label : '&nbsp;'">
      </label>

      <sup *ngIf="required && type !== 'checkbox'" class="label">*</sup>

      <i *ngIf="labelIconRight"
        style="margin: 0 0 0.5rem 0.5rem;"
        [ngClass]="labelIconRight"
        (click)="labelIconRightClick.emit($event)"
        [pTooltip]="labelIconRightTooltip">
      </i>

      <a *ngIf="isLink && checkLinkValidity(value)"
        [style.margin-left]="'0.5rem'"
        [href]="value"
        target="_blank">
        <i class="fas fa-external-link-alt icon-external-link"></i>
      </a>
    </div>

    <label *ngIf="hint"
      class="label small"
      [innerHTML]="hint">
    </label>
  </div>

  <div *ngIf="!editActive"
    class="value">
    <div class="flex-row gap-1 middle">
      <i *ngIf="icon" [ngClass]="icon"></i>

      <div *ngIf="type === 'multi-select'"
        [innerHTML]="!(value || []).length ? noValueHtml : value.join('<br>')">
      </div>
      <div *ngIf="type !== 'date' && type !== 'multi-select'"
        [class.wrap-link]="isLink"
        [innerHTML]="dropdownValue != null ? dropdownValue : (value != null && value !== '') ? value : noValueHtml">
      </div>
      <div *ngIf="type === 'date' && dateValue">
        {{ (dateValue | amDateFormat:(dateFormat || 'lll')) }}
      </div>
      <div *ngIf="type === 'date' && !dateValue"
        [innerHTML]="noValueHtml">
      </div>
    </div>
  </div>

  <p-checkbox *ngIf="type === 'checkbox'"
    binary="true"
    [disabled]="disabled"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)"
    [label]="label">
  </p-checkbox>

  <p-calendar *ngIf="editActive && type === 'date'"
    [appendTo]="appendTo"
    styleClass="calendar"
    dateFormat="mm/dd/yy"
    [disabled]="disabled"
    [inline]="inlineDatePicker"
    [minDate]="minDate | parseDate"
    [maxDate]="maxDate | parseDate"
    [(ngModel)]="dateValue"
    (onBlur)="updateValueWithDate(dateValue)"
    (onSelect)="updateValueWithDate(dateValue)">
  </p-calendar>
  <i *ngIf="editActive && type === 'date'"
    class="calendar-icon link far fa-calendar-alt">
  </i>

  <div *ngIf="editActive && type === 'color'"
    class="flex-1 color-selector"
    [class.open]="isColorPickerOpen"
    [(colorPicker)]="value"
    (colorPickerChange)="colorValueChanged.next($event)"
    (colorPickerClose)="isColorPickerOpen = false"
    (colorPickerOpen)="isColorPickerOpen = true"
    [cpAlphaChannel]="'disabled'"
    [cpColorMode]="colorMode"
    [cpEyeDropper]="colorMode === 'color'"
    [cpOutputFormat]="'hex'"
    [cpPosition]="'auto'"
    [cpPresetColors]="options"
    [cpPresetLabel]="''"
    [cpWidth]="'198px'">

    <div class="flex-row middle"
      style="line-height: 36px; min-height: 36px;">
      <div *ngIf="value"
        class="color-circle"
        [style]="'background-color: ' + value">
      </div>

      <div *ngIf="!value"
        class="placeholder">
        {{ placeholder }}
      </div>

      <div class="flex-1"></div>

      <i class="pi pi-chevron-down"
        style="color: #848484; width: 1.3rem;">
      </i>
    </div>
  </div>

  <p-dropdown *ngIf="editActive && type === 'dropdown'"
    [appendTo]="appendTo"
    styleClass="dropdown"
    panelStyleClass="dropdown-panel"
    [autoDisplayFirst]="false"
    [disabled]="disabled"
    [filter]="dropdownFilter !== false && options?.length > 7"
    [options]="options"
    [optionLabel]="optionLabel"
    [overlayOptions]="{ baseZIndex: 2000 }"
    [placeholder]="placeholder || ''"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    [showClear]="dropdownShowClear"
    [virtualScroll]="options?.length > 100"
    [virtualScrollItemSize]="49"
    [virtualScrollOptions]="{
      lazy: true,
      numToleratedItems: 4,
    }">
    <ng-template let-item pTemplate="selectedItem">
      <div *ngIf="item"
        class="flex-row middle">
        <div *ngIf="item.icon" class="dropdown-icon" [ngClass]="item.icon"></div>

        <div class="ellipsis">
          {{ item[optionLabel || 'label'] }}
        </div>
      </div>
    </ng-template>

    <ng-template let-item pTemplate="item">
      <div *ngIf="item"
        class="flex-row middle"
        style="min-height: 2rem">
        <div *ngIf="item.icon" class="dropdown-icon" [ngClass]="item.icon"></div>

        <div [class.ellipsis]="item.title || item.description">
          {{ item[optionLabel || 'label'] }}
        </div>
      </div>

      <div class="description"
        [innerHTML]="item.title || item.description || ''">
      </div>
    </ng-template>
  </p-dropdown>

  <p-listbox *ngIf="editActive && type === 'listbox'"
    #inputElement
    [appendTo]="appendTo"
    styleClass="listbox"
    [disabled]="disabled"
    [filter]="options?.length > 7"
    [options]="options"
    [optionLabel]="optionLabel"
    [placeholder]="placeholder || ''"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    [showClear]="dropdownShowClear">
    <ng-template let-item pTemplate="item">
      <div *ngIf="item"
        class="flex-row middle"
        style="min-height: 2rem">
        <div *ngIf="item.icon" class="dropdown-icon" [ngClass]="item.icon"></div>

        <div [class.ellipsis]="item.title || item.description">
          {{ item[optionLabel || 'label'] }}
        </div>
      </div>

      <div *ngIf="item"
        class="description"
        [innerHTML]="item.title || item.description || ''">
      </div>
    </ng-template>
  </p-listbox>

  <p-multiSelect *ngIf="editActive && type === 'multi-select'"
    styleClass="multiselect"
    [appendTo]="appendTo"
    [disabled]="disabled"
    [filter]="options?.length > 7"
    [maxSelectedLabels]="3"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)"
    [options]="options"
    [optionLabel]="optionLabel"
    [overlayOptions]="{ baseZIndex: 2000 }"
    [panelStyleClass]="!multiSelectShowHeader ? 'p-multiselect-no-header' : options?.length <= 7 ? 'multiselect-toggle-all-but-no-filter' : ''"
    [placeholder]="placeholder || '&nbsp;'"
    [showToggleAll]="true">
    <ng-template let-values pTemplate="selectedItems">
      <div *ngIf="!values?.length">
        {{ placeholder || '&nbsp;' }}
      </div>
      <div class="flex-row">
        <div *ngFor="let value of values; let i = index">
          <div class="flex-row middle"
            style="min-height: 2rem; margin-right: .5rem;">
            <div *ngIf="findOptionIconByValue(value)" class="dropdown-icon" [ngClass]="findOptionIconByValue(value)"></div>

            <div class="title">{{ findOptionLabelByValue(value) }}</div>

            <div *ngIf="i < values.length - 1">,</div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template let-item pTemplate="item">
      <div *ngIf="item"
        class="flex-row middle"
        style="min-height: 2rem">
        <div *ngIf="item.icon" class="dropdown-icon" [ngClass]="item.icon"></div>

        <div [class.ellipsis]="item.title || item.description">
          {{ item[optionLabel || 'label'] }}
        </div>
      </div>

      <div class="description"
        [innerHTML]="item.title || item.description || ''">
      </div>
    </ng-template>
  </p-multiSelect>

  <miradi-rtf-editor *ngIf="editActive && type === 'richText'"
    class="rtf-editor"
    [alwaysShowToolbar]="richTextAlwaysShowToolbar"
    [autoFocus]="autoFocus"
    [disabled]="disabled"
    [(value)]="value"
    (valueChange)="onChange($event)"
    [placeholder]="placeholder || ''">
  </miradi-rtf-editor>

  <textarea *ngIf="editActive && type === 'textarea'"
    #inputElement
    pInputText
    name="textarea"
    [autocomplete]="autocomplete"
    [autofocus]="autoFocus"
    class="input"
    [disabled]="disabled"
    [minlength]="validationObject?.minLength"
    [maxlength]="validationObject?.maxLength"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    [placeholder]="placeholder || ''"
    [rows]="rows || 4">
  </textarea>

  <p-triStateCheckbox *ngIf="type === 'triStateCheckbox'"
    class="tri-state"
    [class.checked]="value === true"
    [class.unchecked]="value === false"
    [class.mixed]="value == null"
    [disabled]="disabled"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)"
    [label]="label">
  </p-triStateCheckbox>

  <input *ngIf="editActive && (!type || type === 'text')"
    #inputElement
    pInputText
    name="text"
    [autocomplete]="autocomplete"
    [autofocus]="autoFocus"
    (blur)="['int32', 'int64'].indexOf(validationObject?.type) >= 0 ? roundToInt() : null;"
    class="input"
    [disabled]="disabled"
    [minlength]="validationObject?.minLength"
    [maxlength]="!validationObject?.maxLengthWarningOnly ? validationObject?.maxLength : undefined"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    [placeholder]="placeholder || ''"
    [required]="validationObject?.minLength > 0"
    [step]="validationObject?.type === 'float' ? 'any' : ['int32', 'int64'].indexOf(validationObject?.type) >= 0 ? '1' : ''"
    [type]="['int32', 'int64', 'float'].indexOf(validationObject?.type) >= 0 ? 'number' : validationObject?.type || 'text'" />

  <div *ngIf="editActive && validationObject?.maxLength"
    class="char-count"
    [class.error]="!validationObject?.maxLengthWarningOnly && (value?.length || 0) > validationObject?.maxLength"
    [class.warn]="validationObject?.maxLengthWarningOnly && (value?.length || 0) > validationObject?.maxLength">
    {{ value?.length || 0 }} / {{ validationObject?.maxLength }}
  </div>

  <p-chips *ngIf="editActive && type === 'tags'"
    [addOnTab]="true"
    [allowDuplicate]="false"
    [disabled]="disabled"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    styleClass="tags">
  </p-chips>
</div>
