/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi scope box types
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ScopeBoxType = 'Biodiversity' | 'HumanWellbeing';

export const ScopeBoxType = {
    Biodiversity: 'Biodiversity' as ScopeBoxType,
    HumanWellbeing: 'HumanWellbeing' as ScopeBoxType
};

marker('Biodiversity');
marker('HumanWellbeing');

