/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi Share User (abbreviated for search results)
 */
import { ValidateUtils } from 'app/shared/utils';

export class UserSearchResult { 
    /**
     * Unique identifier
     */
    readonly identifier?: string;
    /**
     * First name
     */
    readonly firstName?: string;
    /**
     * Last name
     */
    readonly lastName?: string;
    /**
     * Organization identifier
     */
    readonly organizationIdentifier?: string;
    /**
     * Organization name
     */
    readonly organizationName?: string;

    constructor(item?: Partial<UserSearchResult>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            identifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    if (itemOrValue?.identifier != null) {
                        if (itemOrValue.identifier === '') {
                            itemOrValue.identifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.identifier = parseInt(itemOrValue.identifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.identifier = parseFloat(itemOrValue.identifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            firstName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.firstName : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            lastName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastName : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            organizationIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.organizationIdentifier : itemOrValue;

                    if (itemOrValue?.organizationIdentifier != null) {
                        if (itemOrValue.organizationIdentifier === '') {
                            itemOrValue.organizationIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.organizationIdentifier = parseInt(itemOrValue.organizationIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.organizationIdentifier = parseFloat(itemOrValue.organizationIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            organizationName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.organizationName : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: UserSearchResult.isValid,
        };
    }

    static isValid(obj: UserSearchResult): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = UserSearchResult.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.firstName.isValid(item);
            result = result && validationObject.lastName.isValid(item);
            result = result && validationObject.organizationIdentifier.isValid(item);
            result = result && validationObject.organizationName.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

