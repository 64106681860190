import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { ProgramChangeMessage } from 'app/shared/models';
import { ProjectChangeMessage } from 'app/shared/models/project-change-message.model';
import { BrowserUtils } from 'app/shared/utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Project, ProjectPermission } from '../api-generated';
import { AuthService } from '../auth/auth.service';
import { ProgramsCachedService } from './programs-cached.service';
import { ProjectsCachedService } from './projects-cached.service';
import { ActivatedRoute } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AppService {

  static readonly queryMainNavParam = 'nav1';
  static readonly querySubNavParam = 'nav2';

  set globalInfoDialog(value: BaseDialog) {
    this._globalInfoDialog = value;
  }
  get globalInfoDialog(): BaseDialog {
    return this._globalInfoDialog;
  }
  private _globalInfoDialog: BaseDialog;

  private dismissPillSubject = new Subject<string>();
  private eulaAcceptedSubject = new Subject<void>();
  private myProjectsSubject = new BehaviorSubject<Project[]>(null);
  private programSubject = new BehaviorSubject(new ProgramChangeMessage());
  private projectSubject = new BehaviorSubject(new ProjectChangeMessage());
  private mainTabSubject = new BehaviorSubject('');

  private pageTitle: string;
  private subPageTitleMap: any;

  projectEditEventDatetime: string;

  constructor(
    private authService: AuthService,
    private programsCachedService: ProgramsCachedService,
    private projectsCachedService: ProjectsCachedService,
    private titleService: Title,
    private translateService: TranslateService,
  ) {
  }

  listenToDismissPill(id: string): Observable<string> {
    return this.dismissPillSubject.asObservable()
    .pipe(
      filter((param: string) => {
        return id === param;
      })
    );
  }

  emitDismissPill(id: string): void {
    this.dismissPillSubject.next(id);
  }

  listenToEulaAccepted(): Observable<void> {
    return this.eulaAcceptedSubject.asObservable();
  }

  emitEulaAccepted(): void {
    this.eulaAcceptedSubject.next();
  }

  listenToMyProjectsReady(): Observable<Project[]> {
    return this.myProjectsSubject.asObservable();
  }

  emitMyProjectsReady(myProjects?: Project[]): void {
    this.myProjectsSubject.next(myProjects);
  }

  listenToProgramChangeMessage(): Observable<ProgramChangeMessage> {
    return this.programSubject.asObservable();
  }

  emitProgramChangeMessage(programChangeMessage: ProgramChangeMessage, forceGetProgramPermissions: boolean) {
    this.authService.getProgramPermissions(programChangeMessage.program.identifier, forceGetProgramPermissions)
    .subscribe(() => {
      if (programChangeMessage.source !== 'router') this.programsCachedService.invalidateCache();

      this.projectSubject.next(new ProjectChangeMessage());
      this.programSubject.next(programChangeMessage);
    });
  }

  listenToProjectChangeMessage(): Observable<ProjectChangeMessage> {
    return this.projectSubject.asObservable();
  }

  emitProjectChangeMessage(projectChangeMessage: ProjectChangeMessage, forceGetProjectPermissions: boolean) {
    this.authService.getProjectPermissions(projectChangeMessage.project.identifier, forceGetProjectPermissions)
    .subscribe((pp: ProjectPermission) => {
      if (projectChangeMessage.source !== 'router') this.projectsCachedService.invalidateCache();

      this.programSubject.next(new ProgramChangeMessage());
      this.projectSubject.next(projectChangeMessage);
    });
  }

  listenToActiveMainTabChanges(): Observable<string> {
    return this.mainTabSubject.asObservable();
  }

  setActiveMainTab(tabKey: string) {
    this.mainTabSubject.next(tabKey);
  }

  setTitle(pageTitle?: string) {
    this.pageTitle = pageTitle != null ? pageTitle : this.pageTitle;

    const params = BrowserUtils.getQueryParams();
    const isProgramPage = window.location.href.indexOf('/program/') >= 0;
    const nav1Key = params[AppService.queryMainNavParam];
    const nav2Key = params[AppService.querySubNavParam];

    let subTitle;

    if (!this.subPageTitleMap) this.buildSubPageMap();

    if (nav1Key && nav2Key) {
      subTitle = this.subPageTitleMap[(isProgramPage ? 'program' : 'project') + '-' + nav1Key + '-' + nav2Key] || (nav1Key + '/' + nav2Key);
    }

    this.titleService.setTitle(
      `${this.pageTitle || ''}` +
      `${subTitle ? ' - ' + subTitle : ''}` +
      `${this.pageTitle || subTitle ? ' - ' : ''}` +
      `Miradi Share 2.0`
    );
  }

  private buildSubPageMap() {
    this.subPageTitleMap = {
      // program
      'program-overview-summary': this.translateService.instant('Program Overview') + '/' + this.translateService.instant('Summary'),
      'program-overview-map': this.translateService.instant('Program Overview') + '/' + this.translateService.instant('Map'),
      'program-overview-people': this.translateService.instant('Program Overview') + '/' + this.translateService.instant('People & Groups'),
      'program-overview-files': this.translateService.instant('Program Overview') + '/' + this.translateService.instant('Files & Links'),

      'program-projects-all': this.translateService.instant('Projects') + '/' + this.translateService.instant('All'),
      'program-projects-portfolio': this.translateService.instant('Projects') + '/' + this.translateService.instant('By Portfolio'),

      'program-report-dashboard': this.translateService.instant('Reports & Data') + '/' + this.translateService.instant('Dashboard'),
      'program-report-highlights': this.translateService.instant('Reports & Data') + '/' + this.translateService.instant('Highlights'),
      'program-report-action-chart': this.translateService.instant('Reports & Data') + '/' + this.translateService.instant('Action Chart'),
      'program-report-data-views': this.translateService.instant('Reports & Data') + '/' + this.translateService.instant('Data Views'),
      'program-report-documents': this.translateService.instant('Reports & Data') + '/' + this.translateService.instant('Report Document Export'),
      'program-report-classifications': this.translateService.instant('Reports & Data') + '/' + this.translateService.instant('Classifications'),

      'program-admin-access': this.translateService.instant('Program Admin') + '/' + this.translateService.instant('Miradi Access'),
      'program-admin-team': this.translateService.instant('Program Admin') + '/' + this.translateService.instant('Teams & Partners'),
      'program-admin-highlights': this.translateService.instant('Program Admin') + '/' + this.translateService.instant('Highlights'),
      'program-admin-portfolios': this.translateService.instant('Program Admin') + '/' + this.translateService.instant('Portfolios'),
      'program-admin-custom-data-views': this.translateService.instant('Program Admin') + '/' + this.translateService.instant('Custom Data Views'),
      'program-admin-templates': this.translateService.instant('Program Admin') + '/' + this.translateService.instant('Report Document Templates'),
      'program-admin-import': this.translateService.instant('Program Admin') + '/' + this.translateService.instant('Bulk Import'),

      'project-overview-summary': this.translateService.instant('Project Overview') + '/' + this.translateService.instant('Summary'),
      'project-overview-scope': this.translateService.instant('Project Overview') + '/' + this.translateService.instant('Scope'),
      'project-overview-map': this.translateService.instant('Project Overview') + '/' + this.translateService.instant('Map'),
      'project-overview-people': this.translateService.instant('Project Overview') + '/' + this.translateService.instant('Peope & Groups'),
      'project-overview-files': this.translateService.instant('Project Overview') + '/' + this.translateService.instant('Files & Resources'),
      'project-overview-timeline': this.translateService.instant('Project Overview') + '/' + this.translateService.instant('Miradi Project Versions'),

      'project-situation-situation': this.translateService.instant('Situation Assessment') + '/' + this.translateService.instant('Situation Models'),
      'project-situation-viability': this.translateService.instant('Situation Assessment') + '/' + this.translateService.instant('Target Viability'),
      'project-situation-threats': this.translateService.instant('Situation Assessment') + '/' + this.translateService.instant('Threat Analysis'),
      'project-situation-monitoring-plan': this.translateService.instant('Situation Assessment') + '/' + this.translateService.instant('Assumptions'),
      'project-situation-strategies': this.translateService.instant('Situation Assessment') + '/' + this.translateService.instant('Strategy Effectiveness'),

      'project-toc-diagrams': this.translateService.instant('Theory of Change') + '/' + this.translateService.instant('Results Chain Diagram'),
      'project-toc-results-chain-tracking': this.translateService.instant('Theory of Change') + '/' + this.translateService.instant('Results Chain Tracking'),
      'project-toc-monitoring-plan': this.translateService.instant('Theory of Change') + '/' + this.translateService.instant('Assumptions'),

      'project-work-actions': this.translateService.instant('Work Planning') + '/' + this.translateService.instant('Actions'),
      'project-work-timeframes': this.translateService.instant('Work Planning') + '/' + this.translateService.instant('Timeframes'),
      'project-work-team': this.translateService.instant('Work Planning') + '/' + this.translateService.instant('Team & Partners'),
      'project-work-strategies': this.translateService.instant('Work Planning') + '/' + this.translateService.instant('Strategy Effectiveness'),

      'project-progress-project': this.translateService.instant('Progress Tracking') + '/' + this.translateService.instant('Project'),
      'project-progress-results-chain-tracking': this.translateService.instant('Progress Tracking') + '/' + this.translateService.instant('Results Chains'),
      'project-progress-actions': this.translateService.instant('Progress Tracking') + '/' + this.translateService.instant('Actions'),
      'project-progress-viability': this.translateService.instant('Progress Tracking') + '/' + this.translateService.instant('Target Impact'),

      'project-report-dashboard': this.translateService.instant('Reports & Data') + '/' + this.translateService.instant('Dashboard'),
      'project-report-highlights': this.translateService.instant('Reports & Data') + '/' + this.translateService.instant('Highlights'),
      'project-report-data-views': this.translateService.instant('Reports & Data') + '/' + this.translateService.instant('Data Views'),
      'project-report-documents': this.translateService.instant('Reports & Data') + '/' + this.translateService.instant('Report Document Export'),
      'project-report-factors': this.translateService.instant('Reports & Data') + '/' + this.translateService.instant('Factors'),
      'project-report-classifications': this.translateService.instant('Reports & Data') + '/' + this.translateService.instant('Classifications'),
      'project-report-indicators-scorecard': this.translateService.instant('Reports & Data') + '/' + this.translateService.instant('Indicator scorecard'),

      'project-admin-timeline': this.translateService.instant('Project Admin') + '/' + this.translateService.instant('Miradi Project Versions'),
      'project-admin-access': this.translateService.instant('Project Admin') + '/' + this.translateService.instant('Miradi Access'),
      'project-admin-team': this.translateService.instant('Project Admin') + '/' + this.translateService.instant('Team & Partners'),
      'project-admin-highlights': this.translateService.instant('Project Admin') + '/' + this.translateService.instant('Highlights'),
      'project-admin-custom-data-views': this.translateService.instant('Project Admin') + '/' + this.translateService.instant('Custom Data Views'),
      'project-admin-scorecards': this.translateService.instant('Project Admin') + '/' + this.translateService.instant('Scorecard Index'),
    }
  }

}
