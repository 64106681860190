/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Project publication status
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ProjectPublishStatusEnum = 'Draft' | 'Review' | 'Publish' | 'Archive';

export const ProjectPublishStatusEnum = {
    Draft: 'Draft' as ProjectPublishStatusEnum,
    Review: 'Review' as ProjectPublishStatusEnum,
    Publish: 'Publish' as ProjectPublishStatusEnum,
    Archive: 'Archive' as ProjectPublishStatusEnum
};

marker('Draft');
marker('Review');
marker('Publish');
marker('Archive');

