import { NgModule } from '@angular/core';
import { AppSharedModule } from 'app/shared/app-shared.module';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialog } from './confirm.dialog';


@NgModule({
  imports: [
    AppSharedModule,
    ButtonModule,
    DialogModule,
  ],
  declarations: [
    ConfirmDialog,
  ],
  exports: [
    ConfirmDialog,
  ]
})
export class ConfirmModule { }
