/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeatureCollection } from './featureCollection';
import { FactorType } from './factorType';


/**
 * Factor map data
 */
import { ValidateUtils } from 'app/shared/utils';

export class FactorFootprint { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    factorType?: FactorType;
    /**
     * Factor footprint identifier
     */
    readonly factorFootprintId?: number;
    /**
     * Factor identifier
     */
    identifier?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    name?: string;
    /**
     * Factor footprint layer options (color, fillColor, weight, etc.)
     */
    readonly layerOptions?: object;
    factorFootprint?: FeatureCollection;

    constructor(item?: Partial<FactorFootprint>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 36, 36);
                    }

                    return result;
                }
            },
            factorType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorType : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorFootprintId: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorFootprintId : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            identifier: {
                
                minLength: 0,
                maxLength: 255,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 0, 255);
                    }

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            layerOptions: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.layerOptions : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorFootprint: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorFootprint : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: FactorFootprint.isValid,
        };
    }

    static isValid(obj: FactorFootprint): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = FactorFootprint.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.factorType.isValid(item);
            result = result && validationObject.factorFootprintId.isValid(item);
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.layerOptions.isValid(item);
            result = result && validationObject.factorFootprint.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

