import { Injectable, Injector } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { NotificationService } from '../notification/notification.service';
import { StorageService } from '../storage/storage.service';

export interface Terminology {
  programIdentifier?: string;
  name?: string;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private readonly defaultLanguage = 'en';
  private readonly languageStorageKey = 'miradi_lang';
  private readonly terminologyStorageKey = 'miradi_terminology';

  private notificationService: NotificationService;
  private storageService: StorageService;
  private translateService: TranslateService;

  currentLanguage: string;
  currentTerminology: Terminology;

  constructor(
    private injector: Injector,
  ) {

  }

  init(): void {
    this.notificationService = this.injector.get(NotificationService);
    this.storageService = this.injector.get(StorageService);
    this.translateService = this.injector.get(TranslateService);

    const locale = this.translateService.getBrowserLang(); // this.translateService.getBrowserCultureLang();

    this.translateService.setDefaultLang(this.defaultLanguage);

    console.log('Browser Locale: ' + locale);
    moment.locale(locale);

    try {
      this.currentLanguage = this.storageService.getItem(this.languageStorageKey) || locale;
      this.translateService.use(this.currentLanguage);

      this.currentTerminology = this.storageService.getItem(this.terminologyStorageKey) || {};
    } catch (error) {
      this.showError(error);

      if ((window as any).appInsights) {
        (window as any).appInsights.trackTrace({
          message: error && error.message ? error.message : error,
          severityLevel: SeverityLevel.Warning,
        });
      }

      this.translateService.use(locale);
      this.currentTerminology = {};
    }
  }

  use(lang: string): Observable<any> {
    this.currentLanguage = lang;
    try {
      this.storageService.setItem(this.languageStorageKey, this.currentLanguage);
    } catch (error) {
      this.showError(error);

      if ((window as any).appInsights) {
        (window as any).appInsights.trackTrace({
          message: error && error.message ? error.message : error,
          severityLevel: SeverityLevel.Warning,
        });
      }
    }

    return this.translateService.use(this.currentLanguage);
  }

  setDefaultTerminology(programIdentifier: string, name: string) {
    this.currentTerminology = {
      programIdentifier: programIdentifier,
      name: name,
    };
    try {
      this.storageService.setItem(this.terminologyStorageKey, this.currentTerminology);
    } catch (error) {
      this.showError(error);

      if ((window as any).appInsights) {
        (window as any).appInsights.trackTrace({
          message: error && error.message ? error.message : error,
          severityLevel: SeverityLevel.Warning,
        });
      }
    }
  }

  private showError(error: any) {
    console.warn(error);

    setTimeout(() => {
      this.notificationService.error(
        'Site Data / Cookies',
        'Your browser is currently set to block cookies. If you have issues accessing the site, please contact support.',
      );
    }, 1000);
  }

}
