/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StrategyObjectiveHierarchy } from './strategyObjectiveHierarchy';


/**
 * Project strategy objectives
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectStrategyObjective { 
    /**
     * Unique identifier for Project
     */
    readonly projectIdentifier?: string;
    /**
     * Project name
     */
    readonly projectName?: string;
    /**
     * Strategies for Project
     */
    strategies?: Array<StrategyObjectiveHierarchy>;

    constructor(item?: Partial<ProjectStrategyObjective>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            projectIdentifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectIdentifier : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            projectName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectName : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            strategies: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.strategies : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProjectStrategyObjective.isValid,
        };
    }

    static isValid(obj: ProjectStrategyObjective): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectStrategyObjective.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.projectIdentifier.isValid(item);
            result = result && validationObject.projectName.isValid(item);
            result = result && validationObject.strategies.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

