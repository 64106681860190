<div class="flex-full-screen">
  <ngx-loading class="global-busy" [show]="busy?.show" [text]="busy?.text"></ngx-loading>

  <p-toast class="toast-global"
    key="global"
    position="top-center"
    [preventOpenDuplicates]="true">
    <ng-template let-message pTemplate="message">
      <div *ngIf="message.data?.isProjectEditStatus"
        class="project-edit-status flex-row flex-1">
        <span class="p-toast-icon fas fa-sync-alt">
        </span>

        <div class="p-toast-message-text">
          <div class="p-toast-summary">{{ message.summary }}</div>
          <a href="javascript: void(0);" (click)="refreshProject()">{{ message.detail }}</a>
        </div>
      </div>

      <div *ngIf="!message.data?.isProjectEditStatus"
        class="flex-row flex-1">
        <span [class]="'p-toast-message-icon pi' + (message.icon ? ' ' + message.icon : '')"
          [ngClass]="{'pi-info-circle': message.severity == 'info', 'pi-exclamation-triangle': message.severity == 'warn',
          'pi-times-circle': message.severity == 'error', 'pi-check' :message.severity == 'success'}">
        </span>

        <div class="p-toast-message-text">
            <div class="p-toast-summary">{{ message.summary }}</div>
            <div class="p-toast-detail">{{ message.detail }}</div><!-- keep this like this (no spaces) (white-space: pre-wrap)-->
        </div>
      </div>
    </ng-template>
  </p-toast>

  <div class="body">
    <miradi-maintenance-banner></miradi-maintenance-banner>
    <miradi-eula-banner [hidden]="(currentPageUrl || '').indexOf('/account-setup') >= 0"></miradi-eula-banner>
    <miradi-survey-banner></miradi-survey-banner>
    <router-outlet></router-outlet>
  </div>
</div>

<miradi-info-dialog></miradi-info-dialog>

<div id="plugin-scripts"></div>


