/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Indicator rating sources
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type IndicatorRatingSourceEnum = 'Rough Guess' | 'Expert Knowledge' | 'External Research' | 'On-site Research';

export const IndicatorRatingSourceEnum = {
    RoughGuess: 'Rough Guess' as IndicatorRatingSourceEnum,
    ExpertKnowledge: 'Expert Knowledge' as IndicatorRatingSourceEnum,
    ExternalResearch: 'External Research' as IndicatorRatingSourceEnum,
    OnSiteResearch: 'On-site Research' as IndicatorRatingSourceEnum
};

marker('Rough Guess');
marker('Expert Knowledge');
marker('External Research');
marker('On-site Research');

