/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ViabilityRatingEnum } from './viabilityRatingEnum';
import { FactorProjectResource } from './factorProjectResource';
import { ViabilityStatusEnum } from './viabilityStatusEnum';
import { ViabilityModeEnum } from './viabilityModeEnum';
import { ResultReport } from './resultReport';
import { ProgressReport } from './progressReport';
import { FactorType } from './factorType';
import { ProgressPercent } from './progressPercent';


/**
 * Factor results tracking hierarchy
 */
import { ValidateUtils } from 'app/shared/utils';

export class FactorResultsTrackingHierarchy { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    factorType?: FactorType;
    /**
     * Miradi object resource identifier
     */
    diagramFactorResourceIdentifier?: string;
    /**
     * Factor identifier
     */
    identifier?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    name?: string;
    latestProgressReport?: ProgressReport;
    latestResultReport?: ResultReport;
    latestProgressPercent?: ProgressPercent;
    /**
     * Miradi object resource identifier
     */
    leaderResourceIdentifier?: string;
    /**
     * Resources assigned (only populated for strategies / activities)
     */
    readonly assignedResources?: Array<FactorProjectResource>;
    viabilityMode?: ViabilityModeEnum;
    calculatedViabilityStatus?: ViabilityStatusEnum;
    calculatedViabilityFutureStatus?: ViabilityStatusEnum;
    latestMeasurementRating?: ViabilityRatingEnum;
    latestFutureStatusRating?: ViabilityRatingEnum;
    /**
     * Child Factors results tracking hierarchy
     */
    readonly childFactorResultsTrackingHierarchy?: Array<FactorResultsTrackingHierarchy>;

    constructor(item?: Partial<FactorResultsTrackingHierarchy>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 36, 36);
                    }

                    return result;
                }
            },
            factorType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorType : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            diagramFactorResourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.diagramFactorResourceIdentifier : itemOrValue;

                    if (itemOrValue?.diagramFactorResourceIdentifier != null) {
                        if (itemOrValue.diagramFactorResourceIdentifier === '') {
                            itemOrValue.diagramFactorResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.diagramFactorResourceIdentifier = parseInt(itemOrValue.diagramFactorResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.diagramFactorResourceIdentifier = parseFloat(itemOrValue.diagramFactorResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 36, 36);
                    }

                    return result;
                }
            },
            identifier: {
                
                minLength: 0,
                maxLength: 255,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 0, 255);
                    }

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            latestProgressReport: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.latestProgressReport : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            latestResultReport: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.latestResultReport : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            latestProgressPercent: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.latestProgressPercent : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            leaderResourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.leaderResourceIdentifier : itemOrValue;

                    if (itemOrValue?.leaderResourceIdentifier != null) {
                        if (itemOrValue.leaderResourceIdentifier === '') {
                            itemOrValue.leaderResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.leaderResourceIdentifier = parseInt(itemOrValue.leaderResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.leaderResourceIdentifier = parseFloat(itemOrValue.leaderResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 36, 36);
                    }

                    return result;
                }
            },
            assignedResources: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.assignedResources : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            viabilityMode: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.viabilityMode : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            calculatedViabilityStatus: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.calculatedViabilityStatus : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            calculatedViabilityFutureStatus: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.calculatedViabilityFutureStatus : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            latestMeasurementRating: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.latestMeasurementRating : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            latestFutureStatusRating: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.latestFutureStatusRating : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            childFactorResultsTrackingHierarchy: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.childFactorResultsTrackingHierarchy : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: FactorResultsTrackingHierarchy.isValid,
        };
    }

    static isValid(obj: FactorResultsTrackingHierarchy): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = FactorResultsTrackingHierarchy.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.factorType.isValid(item);
            result = result && validationObject.diagramFactorResourceIdentifier.isValid(item);
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.latestProgressReport.isValid(item);
            result = result && validationObject.latestResultReport.isValid(item);
            result = result && validationObject.latestProgressPercent.isValid(item);
            result = result && validationObject.leaderResourceIdentifier.isValid(item);
            result = result && validationObject.assignedResources.isValid(item);
            result = result && validationObject.viabilityMode.isValid(item);
            result = result && validationObject.calculatedViabilityStatus.isValid(item);
            result = result && validationObject.calculatedViabilityFutureStatus.isValid(item);
            result = result && validationObject.latestMeasurementRating.isValid(item);
            result = result && validationObject.latestFutureStatusRating.isValid(item);
            result = result && validationObject.childFactorResultsTrackingHierarchy.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

