/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Taxonomy element selection
 */
import { ValidateUtils } from 'app/shared/utils';

export class TaxonomyElementSelection { 
    /**
     * Miradi object resource identifier
     */
    taxonomyElementResourceIdentifier?: string;
    /**
     * Taxonomy Element code
     */
    readonly taxonomyElementCode?: string;
    /**
     * Taxonomy Element name
     */
    readonly taxonomyElementName?: string;

    constructor(item?: Partial<TaxonomyElementSelection>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            taxonomyElementResourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.taxonomyElementResourceIdentifier : itemOrValue;

                    if (itemOrValue?.taxonomyElementResourceIdentifier != null) {
                        if (itemOrValue.taxonomyElementResourceIdentifier === '') {
                            itemOrValue.taxonomyElementResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.taxonomyElementResourceIdentifier = parseInt(itemOrValue.taxonomyElementResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.taxonomyElementResourceIdentifier = parseFloat(itemOrValue.taxonomyElementResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 36, 36);
                    }

                    return result;
                }
            },
            taxonomyElementCode: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.taxonomyElementCode : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            taxonomyElementName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.taxonomyElementName : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: TaxonomyElementSelection.isValid,
        };
    }

    static isValid(obj: TaxonomyElementSelection): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = TaxonomyElementSelection.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.taxonomyElementResourceIdentifier.isValid(item);
            result = result && validationObject.taxonomyElementCode.isValid(item);
            result = result && validationObject.taxonomyElementName.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

