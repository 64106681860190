import { NgModule } from '@angular/core';
import { AppSharedModule } from 'app/shared/app-shared.module';
import { ConfirmModule } from 'app/shared/dialogs/confirm/confirm.module';
import { ButtonModule } from 'primeng/button';
import { EulaBannerComponent } from './eula-banner.component';



@NgModule({
  declarations: [
    EulaBannerComponent,
  ],
  exports: [
    EulaBannerComponent,
  ],
  imports: [
    AppSharedModule,

    ButtonModule,
    ConfirmModule,
  ],
})
export class EulaBannerModule { }
