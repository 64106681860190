import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateUtils, ValidateUtils } from 'app/shared/utils';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { RtfEditorComponent } from '../rtf-editor/rtf-editor.component';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FormFieldComponent),
  multi: true
};

@Component({
  selector: 'miradi-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class FormFieldComponent implements ControlValueAccessor {

  @ViewChild('inputElement', { static: false }) inputElement: any;
  @ViewChild(RtfEditorComponent, { static: false }) rtfEditorComponent: RtfEditorComponent;

  @Input() appendTo: any;
  @Input() autocomplete: string = 'off';
  @Input() set autoFocus(value: boolean) {
    this._autoFocus = value;

    if (this._autoFocus) {
      this.tryToFocusInputElement();
    }
  }
  get autoFocus(): boolean {
    return this._autoFocus;
  }
  private _autoFocus: boolean;

  @Input() checkboxForceEmptyLabel: boolean;
  @Input() colorMode: 'color' | 'presets';
  @Input() dateFormat: string;
  @Input() disabled: boolean;
  @Input() dropdownFilter: boolean;
  @Input() dropdownShowClear: boolean;
  @Input() editActive: boolean;
  @Input() hint: string;
  @Input() icon: string;
  @Input() inlineDatePicker: boolean;
  @Input() isLink: boolean;
  @Input() isTouched: boolean;
  @Input() label: string;
  @Input() labelIconRight: string;
  @Input() labelIconRightTooltip: string;
  @Input() set maxDate(value: Date | string) {
    this._maxDate = this.getDateValue(value);
  }
  get maxDate(): Date | string {
    return this._maxDate;
  }
  private _maxDate: Date | string;
  @Input() set minDate(value: Date | string) {
    this._minDate = this.getDateValue(value);
  }
  get minDate(): Date | string {
    return this._minDate;
  }
  private _minDate: Date | string;
  @Input() multiSelectShowHeader: boolean;
  @Input() options: SelectItem[] | any[];
  @Input() optionLabel: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() richTextAlwaysShowToolbar: boolean;
  @Input() rows: number;
  @Input() type: 'checkbox' | 'color' | 'date' | 'dropdown' | 'listbox' | 'multi-select' | 'richText' | 'tags' | 'text' | 'textarea' | 'triStateCheckbox';
  @Input() validationObject: any;

  @Output() labelIconRightClick = new EventEmitter<MouseEvent>();

  colorValueChanged: Subject<string>;
  dateValue: Date;
  dropdownValue: string;
  isColorPickerOpen: boolean;
  noValueHtml: string;

  onChange: (x: any) => {};
  onTouched: () => {};
  value: any;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
    this.noValueHtml = `<span class="no-value">-</span>`;

    this.colorValueChanged = new Subject();
    this.colorValueChanged.pipe(debounceTime(250))
    .subscribe((color: string) => {
      this.onChange(color);
    });
  }

  private getDateValue(value: Date | string): Date {
    if (!value) return undefined;

    if (value instanceof Date) {
      return value;
    } else {
      return new Date(value + (value.indexOf('T') < 0 ? 'T00:00:00' : ''));
    }
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (this.value !== value) {
      this.value = value;

      if (this.value && this.type === 'date') {
        this.dateValue = this.getDateValue(this.value);
        this.updateValueWithDate(this.dateValue);
      } else if (this.type === 'checkbox') {
        this.value = this.value === 'true' || this.value === true;
      } else if (this.type === 'dropdown') {
        const option = (this.options || []).find((item: any) => {
          return item.value != null ? item.value == this.value : item === this.value;
        });
        this.dropdownValue = option ? option[this.optionLabel || 'label'] : undefined;
      } else if (this.value == null || this.value === '') {
        this.dateValue = undefined;
        this.isTouched = false;
      }

      this.cdr.markForCheck();
      setTimeout(() => {
        this.cdr.markForCheck();
      });
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChange = (newValue: any) => {
      const option = (this.options || []).find((item: any) => {
        return item.value != null ? item.value == newValue : item === newValue;
      });
      this.dropdownValue = option ? option[this.optionLabel || 'label'] : undefined;

      return fn(newValue);
    };
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  updateValueWithDate(dt: Date) {
    this.value = DateUtils.getAgnosticDateString(dt, 'yy-mm-dd');

    this.onChange(this.value);
    this.cdr.markForCheck();
  }

  roundToInt() {
    const tempValue = parseInt(this.value, 10);
    if (!isNaN(tempValue)) {
      this.value = tempValue.toString();
    } else {
      this.value = undefined;
    }

    this.onChange(this.value);
    this.cdr.markForCheck();
  }

  tryToFocusInputElement(): void {
    setTimeout(() => {
      const el = this.inputElement?.el?.nativeElement || this.inputElement?.nativeElement;
      if (el) {
        el.focus();
        this.cdr.markForCheck();
      }
    }, 400);
  }

  checkLinkValidity(url: string): boolean {
    return url && ValidateUtils.isValidType(url, 'uri');
  }

  checkValidity(inputElement: any): boolean {
    return !this.isTouched || !inputElement ||
    (inputElement.nativeElement &&
    inputElement.nativeElement.validity &&
    inputElement.nativeElement.validity.valid &&
    (!this.validationObject || !this.validationObject.isValid || this.validationObject.isValid(this.value)));
  }

  forceValidate() {
    this.isTouched = true;
    this.cdr.markForCheck();
  }

  findOptionIconByValue(value: any) {
    const option = this.options.find((item: any) => {
      if (this.optionLabel) {
        return item === value;
      } else {
        return item.value === value;
      }
    });
    return option?.icon;;
  }

  findOptionLabelByValue(value: any) {
    const option = this.options.find((item: any) => {
      if (this.optionLabel) {
        return item === value;
      } else {
        return item.value === value;
      }
    });
    return option ? option[this.optionLabel || 'label'] : '';
  }

}

