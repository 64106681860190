import { Inject, Injectable, Optional } from '@angular/core';
import { ILoadingConfig, LoadingConfig } from './ngx-loading.config';



@Injectable({
    providedIn: 'root'
})
export class LoadingConfigService {
    public loadingConfig: ILoadingConfig;

    constructor( @Optional() @Inject('loadingConfig') config: ILoadingConfig) {
        this.loadingConfig = config || new LoadingConfig();
    }
}