import { Injectable } from '@angular/core';
import { Observer } from 'rxjs';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DiagramResultsTracking, FactorType, ProjectResultsTracking, ProjectsService } from '../api-generated';


@Injectable({
  providedIn: 'root'
})
export class ProjectsCachedService {

  readonly invalidateCacheTimeout = 5 * 60 * 1000;

  private cachedCallRequestDateMap: any;
  private cachedCallResponseMap: any;
  private cachedProjectIdentifier: string;


  constructor(
    private projectsService: ProjectsService,
  ) {
    this.invalidateCache();
  }

  invalidateCache(key?: string, ...params: any) {
    if (key) {
      const cacheKey = key + (this.cachedProjectIdentifier || '') + params.join('');
      delete this.cachedCallRequestDateMap[cacheKey];
      delete this.cachedCallResponseMap[cacheKey];
    } else {
      this.cachedProjectIdentifier = undefined;
      this.cachedCallRequestDateMap = {};
      this.cachedCallResponseMap = {};
    }
  }

  private callApiOrReturnCachedValue(key: string, projectIdentifier: string, ...params: any) {
    const cacheKey = key + projectIdentifier + params.join('');
    if (
      !this.cachedProjectIdentifier ||
      this.cachedProjectIdentifier !== projectIdentifier ||
      Date.now() - (this.cachedCallRequestDateMap[cacheKey] || 0) > this.invalidateCacheTimeout
    ) {
      if (this.cachedProjectIdentifier !== projectIdentifier) {
        this.invalidateCache();
      }

      this.cachedProjectIdentifier = projectIdentifier;

      this.cachedCallRequestDateMap[cacheKey] = Date.now();
      delete this.cachedCallResponseMap[cacheKey];
      return this.projectsService[key](projectIdentifier, ...params)
      .pipe(
        map((response: any) => {
          this.cachedCallResponseMap[cacheKey] = response;
          return response;
        })
      );
    } else if (this.cachedCallResponseMap[cacheKey]) {
      return of(this.cachedCallResponseMap[cacheKey]);
    } else {
      // a previous request is currently in progress, so try again in a few milliseconds to see if the result has become available
      return new Observable((observer: Observer<any>) => {
        setTimeout(() => {
          (params?.length ? this.callApiOrReturnCachedValue(key, projectIdentifier, params) : this.callApiOrReturnCachedValue(key, projectIdentifier))
          .subscribe((response: any) => {
            observer.next(response);
            observer.complete();
          });
        }, 250);
      });
    }
  }

  getProjects(programIdentifier: string) {
    const key = 'getProjects';
    return this.callApiOrReturnCachedValue(key, programIdentifier);
  }

  getProjectActionTrackingTimeframes(projectIdentifier: string) {
    const key = 'getProjectActionTrackingTimeframes';
    return this.callApiOrReturnCachedValue(key, projectIdentifier);
  }

  getProjectCustomReports(projectIdentifier: string) {
    const key = 'getProjectCustomReports';
    return this.callApiOrReturnCachedValue(key, projectIdentifier);
  }

  getProjectFactorTaxonomiesForFactorType(projectIdentifier: string, factorType: FactorType) {
    const key = 'getProjectFactorTaxonomiesForFactorType';
    return this.callApiOrReturnCachedValue(key, projectIdentifier, factorType);
  }

  getProjectIndicatorScorecardFactors(projectIdentifier: string) {
    const key = 'getProjectIndicatorScorecardFactors';
    return this.callApiOrReturnCachedValue(key, projectIdentifier);
  }

  getProjectProgress(projectIdentifier: string) {
    const key = 'getProjectProgress';
    return this.callApiOrReturnCachedValue(key, projectIdentifier);
  }

  getProjectProgressReports(projectIdentifier: string) {
    const key = 'getProjectProgressReports';
    return this.callApiOrReturnCachedValue(key, projectIdentifier);
  }

  getProjectResources(projectIdentifier: string) {
    const key = 'getProjectResources';
    return this.callApiOrReturnCachedValue(key, projectIdentifier);
  }

  getProjectTeamMembers(projectIdentifier: string) {
    const key = 'getProjectTeamMembers';
    return this.callApiOrReturnCachedValue(key, projectIdentifier);
  }

  getProjectTimeframe(projectIdentifier: string) {
    const key = 'getProjectTimeframe';
    return this.callApiOrReturnCachedValue(key, projectIdentifier);
  }

  getProjectResults(projectIdentifier: string) {
    const key = 'getProjectResults';
    return this.callApiOrReturnCachedValue(key, projectIdentifier);
  }
  getProjectTargets(projectIdentifier: string) {
    const key = 'getProjectTargets';
    return this.callApiOrReturnCachedValue(key, projectIdentifier);
  }

  getProjectResultsTracking(projectIdentifier: string): ProjectResultsTracking {
    const key = 'getProjectResultsTracking';
    return this.callApiOrReturnCachedValue(key, projectIdentifier)
    .pipe(
      map((projectResultsTracking: ProjectResultsTracking) => {
        for (const diagramResultTracking of projectResultsTracking.resultsChains) {
          const cacheKey = 'getProjectDiagramResultsTracking' + projectIdentifier + diagramResultTracking.resourceIdentifier;
          this.cachedCallRequestDateMap[cacheKey] = Date.now();
          this.cachedCallResponseMap[cacheKey] = diagramResultTracking;
        }
        return projectResultsTracking;
      })
    );
  }

  getProjectDiagramResultsTracking(projectIdentifier: string, resourceIdentifier: string): DiagramResultsTracking {
    const key = 'getProjectDiagramResultsTracking';
    return this.callApiOrReturnCachedValue(key, projectIdentifier, resourceIdentifier);
  }


}
