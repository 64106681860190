/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectFeaturePermission } from './projectFeaturePermission';


/**
 * Miradi Share Project Permission
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectPermission { 
    /**
     * Unique identifier for Project
     */
    readonly projectIdentifier?: string;
    /**
     * Project name
     */
    readonly projectName?: string;
    /**
     * List of feature permissions
     */
    readonly featurePermissions?: Array<ProjectFeaturePermission>;

    constructor(item?: Partial<ProjectPermission>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            projectIdentifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectIdentifier : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            projectName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectName : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            featurePermissions: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.featurePermissions : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProjectPermission.isValid,
        };
    }

    static isValid(obj: ProjectPermission): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectPermission.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.projectIdentifier.isValid(item);
            result = result && validationObject.projectName.isValid(item);
            result = result && validationObject.featurePermissions.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

