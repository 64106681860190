/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProgramStrategyActivityProgressReportAllOf } from './programStrategyActivityProgressReportAllOf';
import { Report } from './report';
import { ProjectStrategyActivityProgress } from './projectStrategyActivityProgress';


/**
 * Program strategy / activity progress report
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProgramStrategyActivityProgressReport { 
    /**
     * Datetime that report data was materialized
     */
    readonly asOfDate?: string;
    /**
     * Strategy / activity progress for Projects
     */
    readonly projectStrategyActivityProgress: Array<ProjectStrategyActivityProgress>;

    constructor(item?: Partial<ProgramStrategyActivityProgressReport>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            asOfDate: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.asOfDate : itemOrValue;

                    if (itemOrValue?.asOfDate != null) {
                        if (itemOrValue.asOfDate === '') {
                            itemOrValue.asOfDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.asOfDate = parseInt(itemOrValue.asOfDate.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.asOfDate = parseFloat(itemOrValue.asOfDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    if (['uuid'].indexOf('date-time') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            projectStrategyActivityProgress: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectStrategyActivityProgress : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProgramStrategyActivityProgressReport.isValid,
        };
    }

    static isValid(obj: ProgramStrategyActivityProgressReport): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProgramStrategyActivityProgressReport.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.asOfDate.isValid(item);
            result = result && validationObject.projectStrategyActivityProgress.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

