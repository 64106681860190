/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Project associated link (new / update)
 */
import { ValidateUtils } from 'app/shared/utils';

export class AssociatedLinkNew { 
    /**
     * Link uri
     */
    uri?: string;
    /**
     * Link label
     */
    label?: string;

    constructor(item?: Partial<AssociatedLinkNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            uri: {
                type: 'uri',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.uri : itemOrValue;

                    if (itemOrValue?.uri != null) {
                        if (itemOrValue.uri === '') {
                            itemOrValue.uri = undefined;
                        } else if (['int32', 'int64'].indexOf('uri') >= 0) {
                            itemOrValue.uri = parseInt(itemOrValue.uri.toString(), 10);
                        } else if (['float'].indexOf('uri') >= 0) {
                            itemOrValue.uri = parseFloat(itemOrValue.uri.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uri');
                    if (['uuid'].indexOf('uri') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            label: {
                
                minLength: 0,
                maxLength: 1024,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.label : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 0, 1024);
                    }

                    return result;
                }
            },
            isValid: AssociatedLinkNew.isValid,
        };
    }

    static isValid(obj: AssociatedLinkNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = AssociatedLinkNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.uri.isValid(item);
            result = result && validationObject.label.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

