import { NgModule } from '@angular/core';
import { AppSharedModule } from 'app/shared/app-shared.module';
import { MaintenanceBannerComponent } from './survey-banner.component';
import { ButtonModule } from 'primeng/button';



@NgModule({
  declarations: [
    MaintenanceBannerComponent,
  ],
  exports: [
    MaintenanceBannerComponent,
  ],
  imports: [
    AppSharedModule,
    ButtonModule,
  ],
})
export class SurveyBannerModule { }
