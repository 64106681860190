import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { createTranslateLoader, createTranslateParser } from 'app/app.providers';
import { CookieModule } from 'ngx-cookie';
import { MomentModule } from 'ngx-moment';
import { CardModule } from 'primeng/card';
import { TooltipModule } from 'primeng/tooltip';
import { NgxLoadingModule } from './components/ngx-loading/ngx-loading.module';
import { InfoModule } from './dialogs/info/info.module';
import { BlobErrorHttpInterceptor } from './interceptors';
import { MiradiHttpInterceptor } from './interceptors/http.interceptor';
import { PipesModule } from './pipes/pipes.module';
import { LanguageService } from './services/app/language.service';


@NgModule({
  exports: [
    CardModule,
    CommonModule,
    CookieModule,
    FormsModule,
    InfoModule,
    MomentModule,
    NgxLoadingModule,
    PipesModule,
    RouterModule,
    TooltipModule,
    TranslateModule,
  ],
  imports: [
    CardModule,
    CommonModule,
    CookieModule.forRoot(),
    FormsModule,
    InfoModule,
    MomentModule,
    NgxLoadingModule,
    PipesModule,
    RouterModule,
    TooltipModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      parser: {
        provide: TranslateParser,
        useFactory: createTranslateParser,
        deps: [LanguageService]
      }
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MiradiHttpInterceptor, multi: true }, // order of requests: A -> B -> C, order of responses: C -> B -> A
    { provide: HTTP_INTERCEPTORS, useClass: BlobErrorHttpInterceptor, multi: true },
  ],
})
export class AppSharedModule { }
