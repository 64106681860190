/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Taxonomy configuration type
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type TaxonomyConfigurationTypeEnum = 'Rollup Classification' | 'Accounting Classification';

export const TaxonomyConfigurationTypeEnum = {
    RollupClassification: 'Rollup Classification' as TaxonomyConfigurationTypeEnum,
    AccountingClassification: 'Accounting Classification' as TaxonomyConfigurationTypeEnum
};

marker('Rollup Classification');
marker('Accounting Classification');

