/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Indicator scorecard measurement category
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type IndicatorScorecardMeasurementCategoryEnum = 'Ascending' | 'Descending';

export const IndicatorScorecardMeasurementCategoryEnum = {
    Ascending: 'Ascending' as IndicatorScorecardMeasurementCategoryEnum,
    Descending: 'Descending' as IndicatorScorecardMeasurementCategoryEnum
};

marker('Ascending');
marker('Descending');

