/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Result report status
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ResultReportStatusEnum = 'Not Known' | 'Not Yet' | 'Not Achieved' | 'Partially Achieved' | 'On Track' | 'Achieved' | 'No Longer Relevant';

export const ResultReportStatusEnum = {
    NotKnown: 'Not Known' as ResultReportStatusEnum,
    NotYet: 'Not Yet' as ResultReportStatusEnum,
    NotAchieved: 'Not Achieved' as ResultReportStatusEnum,
    PartiallyAchieved: 'Partially Achieved' as ResultReportStatusEnum,
    OnTrack: 'On Track' as ResultReportStatusEnum,
    Achieved: 'Achieved' as ResultReportStatusEnum,
    NoLongerRelevant: 'No Longer Relevant' as ResultReportStatusEnum
};

marker('Not Known');
marker('Not Yet');
marker('Not Achieved');
marker('Partially Achieved');
marker('On Track');
marker('Achieved');
marker('No Longer Relevant');

