/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Timeline event location
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type TimelineEventLocationEnum = 'Miradi' | 'Miradi Share';

export const TimelineEventLocationEnum = {
    Miradi: 'Miradi' as TimelineEventLocationEnum,
    MiradiShare: 'Miradi Share' as TimelineEventLocationEnum
};

marker('Miradi');
marker('Miradi Share');

