/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Measurement trend
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type MeasurementTrendEnum = 'Unknown' | 'Strong Increase' | 'Mild Increase' | 'Flat' | 'Mild Decrease' | 'Strong Decrease';

export const MeasurementTrendEnum = {
    Unknown: 'Unknown' as MeasurementTrendEnum,
    StrongIncrease: 'Strong Increase' as MeasurementTrendEnum,
    MildIncrease: 'Mild Increase' as MeasurementTrendEnum,
    Flat: 'Flat' as MeasurementTrendEnum,
    MildDecrease: 'Mild Decrease' as MeasurementTrendEnum,
    StrongDecrease: 'Strong Decrease' as MeasurementTrendEnum
};

marker('Unknown');
marker('Strong Increase');
marker('Mild Increase');
marker('Flat');
marker('Mild Decrease');
marker('Strong Decrease');

