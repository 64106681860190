/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ViabilityRatingEnum } from './viabilityRatingEnum';
import { MeasurementSourceEnum } from './measurementSourceEnum';
import { MeasurementPrecisionTypeEnum } from './measurementPrecisionTypeEnum';
import { IndicatorFutureStatusAllOf } from './indicatorFutureStatusAllOf';
import { MeasurementTrendEnum } from './measurementTrendEnum';
import { IndicatorMeasurementNew } from './indicatorMeasurementNew';


/**
 * Indicator measurement
 */
import { ValidateUtils } from 'app/shared/utils';

export class IndicatorMeasurement { 
    /**
     * Measurement name
     */
    name?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    details?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    comments?: string;
    /**
     * Measurement date
     */
    measurementDate?: string;
    /**
     * Measurement value
     */
    value?: string;
    rating?: ViabilityRatingEnum;
    source?: MeasurementSourceEnum;
    trend?: MeasurementTrendEnum;
    samplePrecisionType?: MeasurementPrecisionTypeEnum;
    /**
     * Measurement sample precision
     */
    samplePrecision?: number | null;
    /**
     * Measurement sample size
     */
    sampleSize?: number | null;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    evidenceNotes?: string;
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier: string;

    constructor(item?: Partial<IndicatorMeasurement>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            details: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.details : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            comments: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.comments : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            measurementDate: {
                type: 'date',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.measurementDate : itemOrValue;

                    if (itemOrValue?.measurementDate != null) {
                        if (itemOrValue.measurementDate === '') {
                            itemOrValue.measurementDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date') >= 0) {
                            itemOrValue.measurementDate = parseInt(itemOrValue.measurementDate.toString(), 10);
                        } else if (['float'].indexOf('date') >= 0) {
                            itemOrValue.measurementDate = parseFloat(itemOrValue.measurementDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date');
                    if (['uuid'].indexOf('date') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            value: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.value : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            rating: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.rating : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            source: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.source : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            trend: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.trend : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            samplePrecisionType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.samplePrecisionType : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            samplePrecision: {
                type: 'float',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.samplePrecision : itemOrValue;

                    if (itemOrValue?.samplePrecision != null) {
                        if (itemOrValue.samplePrecision === '') {
                            itemOrValue.samplePrecision = undefined;
                        } else if (['int32', 'int64'].indexOf('float') >= 0) {
                            itemOrValue.samplePrecision = parseInt(itemOrValue.samplePrecision.toString(), 10);
                        } else if (['float'].indexOf('float') >= 0) {
                            itemOrValue.samplePrecision = parseFloat(itemOrValue.samplePrecision.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'float');
                    if (['uuid'].indexOf('float') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            sampleSize: {
                type: 'int32',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.sampleSize : itemOrValue;

                    if (itemOrValue?.sampleSize != null) {
                        if (itemOrValue.sampleSize === '') {
                            itemOrValue.sampleSize = undefined;
                        } else if (['int32', 'int64'].indexOf('int32') >= 0) {
                            itemOrValue.sampleSize = parseInt(itemOrValue.sampleSize.toString(), 10);
                        } else if (['float'].indexOf('int32') >= 0) {
                            itemOrValue.sampleSize = parseFloat(itemOrValue.sampleSize.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'int32');
                    if (['uuid'].indexOf('int32') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            evidenceNotes: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.evidenceNotes : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 36, 36);
                    }

                    return result;
                }
            },
            isValid: IndicatorMeasurement.isValid,
        };
    }

    static isValid(obj: IndicatorMeasurement): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = IndicatorMeasurement.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.details.isValid(item);
            result = result && validationObject.comments.isValid(item);
            result = result && validationObject.measurementDate.isValid(item);
            result = result && validationObject.value.isValid(item);
            result = result && validationObject.rating.isValid(item);
            result = result && validationObject.source.isValid(item);
            result = result && validationObject.trend.isValid(item);
            result = result && validationObject.samplePrecisionType.isValid(item);
            result = result && validationObject.samplePrecision.isValid(item);
            result = result && validationObject.sampleSize.isValid(item);
            result = result && validationObject.evidenceNotes.isValid(item);
            result = result && validationObject.resourceIdentifier.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

