/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Project access status
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ProjectAccessEnum = 'Private' | 'Program' | 'Organization' | 'Public';

export const ProjectAccessEnum = {
    Private: 'Private' as ProjectAccessEnum,
    Program: 'Program' as ProjectAccessEnum,
    Organization: 'Organization' as ProjectAccessEnum,
    Public: 'Public' as ProjectAccessEnum
};

marker('Private');
marker('Program');
marker('Organization');
marker('Public');

