/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Guide factor link
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectDiagramGuideFactorLink { 
    /**
     * Miradi object resource identifier
     */
    fromDiagramGuideFactor?: string;
    /**
     * Miradi object resource identifier
     */
    toDiagramGuideFactor?: string;

    constructor(item?: Partial<ProjectDiagramGuideFactorLink>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            fromDiagramGuideFactor: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.fromDiagramGuideFactor : itemOrValue;

                    if (itemOrValue?.fromDiagramGuideFactor != null) {
                        if (itemOrValue.fromDiagramGuideFactor === '') {
                            itemOrValue.fromDiagramGuideFactor = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.fromDiagramGuideFactor = parseInt(itemOrValue.fromDiagramGuideFactor.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.fromDiagramGuideFactor = parseFloat(itemOrValue.fromDiagramGuideFactor.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 36, 36);
                    }

                    return result;
                }
            },
            toDiagramGuideFactor: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.toDiagramGuideFactor : itemOrValue;

                    if (itemOrValue?.toDiagramGuideFactor != null) {
                        if (itemOrValue.toDiagramGuideFactor === '') {
                            itemOrValue.toDiagramGuideFactor = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.toDiagramGuideFactor = parseInt(itemOrValue.toDiagramGuideFactor.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.toDiagramGuideFactor = parseFloat(itemOrValue.toDiagramGuideFactor.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 36, 36);
                    }

                    return result;
                }
            },
            isValid: ProjectDiagramGuideFactorLink.isValid,
        };
    }

    static isValid(obj: ProjectDiagramGuideFactorLink): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectDiagramGuideFactorLink.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.fromDiagramGuideFactor.isValid(item);
            result = result && validationObject.toDiagramGuideFactor.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

