/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Evidence confidence (assumption / sub assumption)
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type EvidenceConfidenceAssumptionEnum = 'Very Confident' | 'Confident' | 'Need More Info' | 'Not Confident';

export const EvidenceConfidenceAssumptionEnum = {
    VeryConfident: 'Very Confident' as EvidenceConfidenceAssumptionEnum,
    Confident: 'Confident' as EvidenceConfidenceAssumptionEnum,
    NeedMoreInfo: 'Need More Info' as EvidenceConfidenceAssumptionEnum,
    NotConfident: 'Not Confident' as EvidenceConfidenceAssumptionEnum
};

marker('Very Confident');
marker('Confident');
marker('Need More Info');
marker('Not Confident');

