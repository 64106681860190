import { ProgramEx } from 'app/program/models/program-ex.model';

export class ProgramChangeMessage {

  options?: any;
  program: ProgramEx;
  source?: string;

  constructor(item?: any) {
    Object.assign(this, item);
  }

}
