import { FactorTaxonomyClassification, TaxonomyClassification, TaxonomyElement, TaxonomyElementSelection } from 'app/shared/services';


export class FlatFactorTaxonomyClassification {

  projectIdentifier: string;
  factorResourceIdentifier: string;
  factorType: string;
  factorIdentifier: string;
  factorName: string;
  isStandardClassification: boolean;
  taxonomyClassificationIdentifier: string;
  taxonomyClassificationLabel: string;
  taxonomyElementSelections: TaxonomyElementSelection[];

  constructor(
    projectIdentifier: string,
    factor: FactorTaxonomyClassification,
    classification: TaxonomyClassification,
  ) {
    this.projectIdentifier = projectIdentifier;

    if (factor && classification) {
      this.factorResourceIdentifier = factor.resourceIdentifier;
      this.factorType = factor.factorType;
      this.factorIdentifier = factor.identifier;
      this.factorName = factor.name;

      this.isStandardClassification = classification.isStandardClassification
      this.taxonomyClassificationIdentifier = classification.taxonomyVersionResourceIdentifier;
      this.taxonomyClassificationLabel = classification.taxonomyVersionLabel;
      this.taxonomyElementSelections = classification.taxonomyElementSelections || [];
    }
  }

}
