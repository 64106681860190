/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ThreatRating } from './threatRating';
import { ThreatRatingModeEnum } from './threatRatingModeEnum';


/**
 * Threat Ratings for Project
 */
import { ValidateUtils } from 'app/shared/utils';

export class ThreatRatings { 
    threatRatingMode?: ThreatRatingModeEnum;
    /**
     * Threat Ratings
     */
    readonly threatRatings?: Array<ThreatRating>;

    constructor(item?: Partial<ThreatRatings>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            threatRatingMode: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.threatRatingMode : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            threatRatings: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.threatRatings : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ThreatRatings.isValid,
        };
    }

    static isValid(obj: ThreatRatings): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ThreatRatings.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.threatRatingMode.isValid(item);
            result = result && validationObject.threatRatings.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

