/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectScopeProtectedAreaCategoryTypeEnum } from './projectScopeProtectedAreaCategoryTypeEnum';


/**
 * Project scope protected area information
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectScopeProtectedAreaInformation { 
    /**
     * Scope protected area categories
     */
    protectedAreaCategories?: Array<ProjectScopeProtectedAreaCategoryTypeEnum>;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    protectedAreaCategoryNotes?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    legalStatus?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    legislativeContext?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    physicalDescription?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    biologicalDescription?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    socioEconomicInformation?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    historicalDescription?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    culturalDescription?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    currentLandUses?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    managementResources?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    accessInformation?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    visitationInformation?: string;

    constructor(item?: Partial<ProjectScopeProtectedAreaInformation>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            protectedAreaCategories: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.protectedAreaCategories : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            protectedAreaCategoryNotes: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.protectedAreaCategoryNotes : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            legalStatus: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.legalStatus : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            legislativeContext: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.legislativeContext : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            physicalDescription: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.physicalDescription : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            biologicalDescription: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.biologicalDescription : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            socioEconomicInformation: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.socioEconomicInformation : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            historicalDescription: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.historicalDescription : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            culturalDescription: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.culturalDescription : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            currentLandUses: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.currentLandUses : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            managementResources: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.managementResources : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            accessInformation: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.accessInformation : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            visitationInformation: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.visitationInformation : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProjectScopeProtectedAreaInformation.isValid,
        };
    }

    static isValid(obj: ProjectScopeProtectedAreaInformation): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectScopeProtectedAreaInformation.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.protectedAreaCategories.isValid(item);
            result = result && validationObject.protectedAreaCategoryNotes.isValid(item);
            result = result && validationObject.legalStatus.isValid(item);
            result = result && validationObject.legislativeContext.isValid(item);
            result = result && validationObject.physicalDescription.isValid(item);
            result = result && validationObject.biologicalDescription.isValid(item);
            result = result && validationObject.socioEconomicInformation.isValid(item);
            result = result && validationObject.historicalDescription.isValid(item);
            result = result && validationObject.culturalDescription.isValid(item);
            result = result && validationObject.currentLandUses.isValid(item);
            result = result && validationObject.managementResources.isValid(item);
            result = result && validationObject.accessInformation.isValid(item);
            result = result && validationObject.visitationInformation.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

