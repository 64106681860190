import { NgModule } from '@angular/core';
import { AppSharedModule } from 'app/shared/app-shared.module';
import { MaintenanceBannerComponent } from './maintenance-banner.component';



@NgModule({
  declarations: [
    MaintenanceBannerComponent,
  ],
  exports: [
    MaintenanceBannerComponent,
  ],
  imports: [
    AppSharedModule,
  ],
})
export class MaintenanceBannerModule { }
