import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { FactorInfo } from 'app/shared/services';
import { AuthService } from 'app/shared/services/auth/auth.service';


@Component({
  selector: 'miradi-confirm-dialog',
  templateUrl: './confirm.dialog.html',
  styleUrls: ['./confirm.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialog extends BaseDialog {

  @Input() appendTo: 'body' | null;

  escapeKeyCallback = this.no.bind(this);
  enterKeyCallback = this.yes.bind(this);

  data: {
    dismissOnEscape: boolean;
    factor: FactorInfo;
    message: string;
    noLabel: string;
    title: string;
    yesLabel: string;
  }

  constructor(
    authService: AuthService,
    cdr: ChangeDetectorRef,
    el: ElementRef,
  ) {
    super(authService, cdr, el);
  }

  init(data: any) {
    if (this.data?.dismissOnEscape) this.escapeKeyCallback = this.dismiss.bind(this);
    else this.escapeKeyCallback = this.no.bind(this);
  }

  no() {
    this.close(false);
  }

  yes() {
    this.close(true);
  }

}
