/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi diagram factor z order
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type DiagramFactorZOrderEnum = 'Back' | 'Front';

export const DiagramFactorZOrderEnum = {
    Back: 'Back' as DiagramFactorZOrderEnum,
    Front: 'Front' as DiagramFactorZOrderEnum
};

marker('Back');
marker('Front');

