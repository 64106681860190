/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeatureCollection } from './featureCollection';


/**
 * Project footprint
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectFootprint { 
    /**
     * Unique identifier for Project
     */
    readonly projectIdentifier?: string;
    /**
     * Project name
     */
    readonly name?: string;
    /**
     * Project footprint identifier
     */
    readonly projectFootprintId?: number;
    /**
     * Project footprint layer options (color, fillColor, weight, etc.)
     */
    readonly layerOptions?: object;
    projectFootprint?: FeatureCollection;

    constructor(item?: Partial<ProjectFootprint>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            projectIdentifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectIdentifier : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            projectFootprintId: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectFootprintId : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            layerOptions: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.layerOptions : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            projectFootprint: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectFootprint : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProjectFootprint.isValid,
        };
    }

    static isValid(obj: ProjectFootprint): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectFootprint.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.projectIdentifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.projectFootprintId.isValid(item);
            result = result && validationObject.layerOptions.isValid(item);
            result = result && validationObject.projectFootprint.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

