export class DateUtils {

  static isValidDate(date: Date) {
    return date instanceof Date && !isNaN((date as any));
  }

  static addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  static daysDiff(date1: Date, date2: Date) {
    const oneInterval = 1000 * 60 * 60 * 24; // Get 1 day in milliseconds
    const differenceMs = date2.getTime() - date1.getTime();
    return Math.round(differenceMs / oneInterval);
  }

  static minutesDiff(date1: Date, date2: Date) {
    const oneInterval = 1000 * 60; // Get 1 minute in milliseconds
    const differenceMs = date2.getTime() - date1.getTime();
    return Math.round(differenceMs / oneInterval);
  }

  static getAgnosticDateString(dt: Date, format?: string) {
    if (dt) {
      format = format || 'yy-mm-dd';
      return format
        .replace('dd', this.twoDigit(dt.getDate()))
        .replace('mm', this.twoDigit(dt.getMonth() + 1))
        .replace('yy', this.twoDigit(dt.getFullYear()));
    } else {
      return undefined;
    }
  }

  private static twoDigit(n) {
    return n < 10 ? '0'+n:''+n;
  }

  static getLocalISOString(dt: string, displaySeconds = true) {
    if (dt && dt.length) {
      const tzOffset = (new Date()).getTimezoneOffset() * 60000; // local timezone offset in milliseconds
      return (new Date(new Date(dt).getTime() - tzOffset))
        .toISOString()
        .substring(0, displaySeconds ? 19 : 16)
        .replace('T', ' ');
    } else {
      return dt;
    }
  }

  static getLocalISODate(dt: string) {
    return this.getLocalISOString(dt)?.substring(0, 10) || '';
  }

  static nowAsISOString() {
    const now = new Date();
    return now.toISOString();
  }

  static nowAsFileNameString() {
    const now = new Date();
    return now.toISOString().replace(/-/g, '').replace(/T/g, '').replace(/:/g, '').substring(0, 14);
  }

}
