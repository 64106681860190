/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Support request type
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type SupportRequestTypeEnum = 'General Feedback' | 'Bug' | 'New Feature' | 'Subscription Query' | 'Other';

export const SupportRequestTypeEnum = {
    GeneralFeedback: 'General Feedback' as SupportRequestTypeEnum,
    Bug: 'Bug' as SupportRequestTypeEnum,
    NewFeature: 'New Feature' as SupportRequestTypeEnum,
    SubscriptionQuery: 'Subscription Query' as SupportRequestTypeEnum,
    Other: 'Other' as SupportRequestTypeEnum
};

marker('General Feedback');
marker('Bug');
marker('New Feature');
marker('Subscription Query');
marker('Other');

