/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Import status for Project (file)
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectImportStatus { 
    /**
     * Unique identifier
     */
    readonly identifier?: string;
    /**
     * Project name
     */
    readonly name?: string;
    /**
     * Flag indicating whether Project is currently pending import
     */
    readonly isPendingImport?: boolean;
    /**
     * File import start datetime
     */
    readonly startImportDatetime?: string | null;
    /**
     * File import complete datetime
     */
    readonly endImportDatetime?: string | null;

    constructor(item?: Partial<ProjectImportStatus>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            identifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isPendingImport: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isPendingImport : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            startImportDatetime: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.startImportDatetime : itemOrValue;

                    if (itemOrValue?.startImportDatetime != null) {
                        if (itemOrValue.startImportDatetime === '') {
                            itemOrValue.startImportDatetime = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.startImportDatetime = parseInt(itemOrValue.startImportDatetime.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.startImportDatetime = parseFloat(itemOrValue.startImportDatetime.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    if (['uuid'].indexOf('date-time') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            endImportDatetime: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.endImportDatetime : itemOrValue;

                    if (itemOrValue?.endImportDatetime != null) {
                        if (itemOrValue.endImportDatetime === '') {
                            itemOrValue.endImportDatetime = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.endImportDatetime = parseInt(itemOrValue.endImportDatetime.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.endImportDatetime = parseFloat(itemOrValue.endImportDatetime.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    if (['uuid'].indexOf('date-time') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProjectImportStatus.isValid,
        };
    }

    static isValid(obj: ProjectImportStatus): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectImportStatus.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.isPendingImport.isValid(item);
            result = result && validationObject.startImportDatetime.isValid(item);
            result = result && validationObject.endImportDatetime.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

