<p-dialog styleClass="confirm-dialog"
  appendTo="body"
  [header]="data?.title"
  [closable]="false"
  [closeOnEscape]="false"
  [modal]="true"
  [(visible)]="isVisible">

  <div class="flex-col gap-2">
    <div *ngIf="data?.factor"
      class="flex-row gap-1 middle">
      <i class="factor-type-icon"
        [ngClass]="(data.factor?.diagramFactorType || data.factor?.factorType) | toClassString"
        [pTooltip]="(data.factor?.diagramFactorType || data.factor?.factorType) | translate">
      </i>

      <h3 class="factor-type-name-v2"
        [pTooltip]="(data.factor | concatenateIdAndName) || (('Unnamed' | translate) + ' ' + ((data.factor?.factorType | toLabelString)))">
        {{ (data.factor | concatenateIdAndName) || (('Unnamed' | translate) + ' ' + ((data.factor?.factorType | toLabelString))) }}
      </h3>
    </div>

    <div [innerHTML]="data?.message">
    </div>
  </div>

  <p-footer
   (keydown.escape)="no()"
   (keydown.enter)="yes()">
    <p-button
      [label]="data?.noLabel || ('No' | translate)"
      (click)="no()"
      tabindex="-1"
      [text]="true">
    </p-button>

    <p-button
      [label]="data?.yesLabel || ('Yes' | translate)"
      (click)="yes()"
      severity="primary"
      tabindex="-1">
    </p-button>
  </p-footer>
</p-dialog>
