/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi Share project team roles
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ProjectRoleEnum = 'Project Viewer' | 'Project Partner' | 'Project Editor' | 'Project Manager' | 'Project Owner';

export const ProjectRoleEnum = {
    Viewer: 'Project Viewer' as ProjectRoleEnum,
    Partner: 'Project Partner' as ProjectRoleEnum,
    Editor: 'Project Editor' as ProjectRoleEnum,
    Manager: 'Project Manager' as ProjectRoleEnum,
    Owner: 'Project Owner' as ProjectRoleEnum
};

marker('Project Viewer');
marker('Project Partner');
marker('Project Editor');
marker('Project Manager');
marker('Project Owner');

