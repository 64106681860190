/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * KEA Type
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type KeyEcologicalAttributeTypeEnum = 'Size' | 'Condition' | 'Landscape Context';

export const KeyEcologicalAttributeTypeEnum = {
    Size: 'Size' as KeyEcologicalAttributeTypeEnum,
    Condition: 'Condition' as KeyEcologicalAttributeTypeEnum,
    LandscapeContext: 'Landscape Context' as KeyEcologicalAttributeTypeEnum
};

marker('Size');
marker('Condition');
marker('Landscape Context');

