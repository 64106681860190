import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppService, Busy, FactorType, GlobalBusyService, LanguageService, NotificationService, Project, SystemSettings } from 'app/shared/services';
import { environment } from 'environments/environment';
import { BaseComponent } from './shared/components/base/base.component';
import { InfoDialog } from './shared/dialogs';
import { BaseDialog } from './shared/dialogs/base/base.dialog';
import { ProjectChangeMessage } from './shared/models';
import { SystemCachedService } from './shared/services/app/system-cached.service';
import { AuthService } from './shared/services/auth/auth.service';
import { BrowserUtils } from './shared/utils';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

// extra translation strings...
marker('Access Level');
marker('Access State');
marker('Check-in State');
marker('Checkin Status');
marker('Filters');
marker('Goals');
marker('Goal Progress Percent');
marker('Indicator Progress Status');
marker('Indicator (Simple)');
marker('Indicator (KEA)');
marker('Key Attributes & Indicators');
marker('Nested Targets');
marker('Objective Progress Percent');
marker('Project Threat Rating');
marker('Stresses');
marker('Strategy Progress Status');
marker('SubAssumptions');
marker('Target Threats');
marker('Target Threat Rating');
marker('Target Viability Status');
marker('Threat Rating');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends BaseComponent implements OnInit {

  @ViewChild(InfoDialog, { static: true }) infoDialog: BaseDialog;

  busy: Busy;
  currentPageUrl: string;
  private project: Project;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    authService: AuthService,
    private globalBusyService: GlobalBusyService,
    private cdr: ChangeDetectorRef,
    private languageService: LanguageService,
    private notificationService: NotificationService,
    private router: Router,
    private systemCachedService: SystemCachedService,
    private translateService: TranslateService,
  ) {
    super(authService);

    this.systemCachedService.getSettings()
    .subscribe((systemSettings: SystemSettings) => {
      // just pre-fecthing the systemSettings to be used later...
    });
  }

  ngOnInit() {
    this.languageService.init();

    if (BrowserUtils.isInIframe()){
      window.top.postMessage(
        { purchaseSubscriptionRedirectUrl: window.location.href },
        window.location.origin
      );
      return;
    }

    if (BrowserUtils.detectIE()) {
      setTimeout(() => {
        this.notificationService.warn(
          this.translateService.instant('Browser Compatibility'),
          this.translateService.instant('Internet Explorer support has been discontinued. Please use another browser...'),
          0,
        );

        this.cdr.markForCheck();
      }, 500);
      return;
    }

    this.subscriptions.push(
      this.appService.listenToProjectChangeMessage()
      .subscribe((pcm: ProjectChangeMessage) => {
        this.project = pcm?.project;
      }),

      this.globalBusyService.listenToBusyChanges()
      .subscribe((busy: Busy) => {
        this.busy = busy;
        this.cdr.markForCheck();
      }),

      this.router.events
      .subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          if (environment.googleAnalyticsKey) {
            (window as any).gtag('event', 'page_view', {
              page_path: (environment.appBaseHref || '') + (event.urlAfterRedirects || ''),
            });
          }

          this.appService.setTitle();

          this.checkForNotificationQueryParam();

          this.currentPageUrl = event.url;
        } else if (event instanceof NavigationError) {
          if (event.error.message.indexOf('Loading chunk') >= 0) {
            this.handleLoadingChunkError(event);
          }
        }
        this.cdr.markForCheck();
      })
    );

    this.appService.globalInfoDialog = this.infoDialog;
  }

  private handleLoadingChunkError(ne: NavigationError) {
    this.globalBusyService.setBusy(false);
    this.infoDialog.show({
      title: this.translateService.instant('Network Error'),
      message: this.translateService.instant('Sorry we were unable to load the page you were trying to visit.'),
      buttonText: this.translateService.instant('Retry'),
    });
    this.infoDialog.onClose = () => {
      this.router.navigateByUrl(ne.url)
    };
    this.cdr.markForCheck();
  }

  refreshProject() {
    this.appService.emitProjectChangeMessage({ project: this.project, source: 'project-header', options: { fullRefresh: true } }, false);
  }

  private checkForNotificationQueryParam() {
    const successToastMessage = BrowserUtils.getQueryParams().showSuccessToast;
    if (!successToastMessage) return;

    this.notificationService.success(
      this.translateService.instant('Success'),
      successToastMessage,
    );
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          showSuccessToast: undefined,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true, // set to true to not update browser history
        skipLocationChange: false,
      }
    );
  }

}
