<p-editor #container
  [class.active]="!disabled && (alwaysShowToolbar || active)"
  [modules]="modules"
  [(ngModel)]="value"
  (ngModelChange)="valueChange.emit($event || '')"
  (onInit)="onEditorInit($event)"
  [placeholder]="placeholder"
  [readonly]="disabled">

  <ng-template pTemplate="header">
    <span class="ql-formats">
      <button class="ql-bold"></button>
      <button class="ql-italic"></button>
      <button class="ql-underline"></button>
      <button class="ql-strike"></button>
    </span>

    <span class="ql-formats">
      <button class="ql-list" value="ordered"></button>
      <button class="ql-list" value="bullet"></button>
    </span>

    <span class="ql-formats">
      <button class="ql-link"></button>
    </span>
  </ng-template>
</p-editor>
