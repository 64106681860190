/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Project progress percent
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProgressPercentNew { 
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    details?: string;
    /**
     * Progress report date
     */
    progressDate?: string;
    /**
     * Progress percent complete
     */
    percentComplete?: number | null;

    constructor(item?: Partial<ProgressPercentNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            details: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.details : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            progressDate: {
                type: 'date',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.progressDate : itemOrValue;

                    if (itemOrValue?.progressDate != null) {
                        if (itemOrValue.progressDate === '') {
                            itemOrValue.progressDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date') >= 0) {
                            itemOrValue.progressDate = parseInt(itemOrValue.progressDate.toString(), 10);
                        } else if (['float'].indexOf('date') >= 0) {
                            itemOrValue.progressDate = parseFloat(itemOrValue.progressDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date');
                    if (['uuid'].indexOf('date') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            percentComplete: {
                type: 'float',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.percentComplete : itemOrValue;

                    if (itemOrValue?.percentComplete != null) {
                        if (itemOrValue.percentComplete === '') {
                            itemOrValue.percentComplete = undefined;
                        } else if (['int32', 'int64'].indexOf('float') >= 0) {
                            itemOrValue.percentComplete = parseInt(itemOrValue.percentComplete.toString(), 10);
                        } else if (['float'].indexOf('float') >= 0) {
                            itemOrValue.percentComplete = parseFloat(itemOrValue.percentComplete.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'float');
                    if (['uuid'].indexOf('float') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProgressPercentNew.isValid,
        };
    }

    static isValid(obj: ProgressPercentNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProgressPercentNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.details.isValid(item);
            result = result && validationObject.progressDate.isValid(item);
            result = result && validationObject.percentComplete.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

