/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Notification interval
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type NotificationIntervalEnum = 'Daily' | 'Weekly' | 'Monthly';

export const NotificationIntervalEnum = {
    Daily: 'Daily' as NotificationIntervalEnum,
    Weekly: 'Weekly' as NotificationIntervalEnum,
    Monthly: 'Monthly' as NotificationIntervalEnum
};

marker('Daily');
marker('Weekly');
marker('Monthly');

