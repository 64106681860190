<div *ngIf="showBanner"
  class="flex-row gap-1 center middle eula-banner">

  <div class="label">
    {{ 'Miradi has a new end-user licensing agreement. Please review and confirm your acceptance of the terms.' | translate }}
  </div>

  <p-button
    [label]="'Review' | translate"
    (click)="review()"
    [raised]="true"
    severity="secondary">
  </p-button>
</div>

<miradi-confirm-dialog></miradi-confirm-dialog>
