import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserService } from 'app/shared/services';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { BaseComponent } from '../base/base.component';
import { environment } from 'environments/environment';



@Component({
  selector: 'miradi-survey-banner',
  templateUrl: './survey-banner.component.html',
  styleUrls: ['./survey-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceBannerComponent extends BaseComponent implements OnInit {

  readonly id = 'topbanner-survey';

  hidden: boolean;

  constructor(
    authService: AuthService,
    private cdr: ChangeDetectorRef,
    private userService: UserService,
  ) {
    super(authService);
  }

  ngOnInit() {
    this.refresh();
  }

  refresh(options?: any) {
    const userPreferences = this.authService.currentUserPreferences;
    if (!userPreferences) {
      this.hidden = true;
      setTimeout(() => {
        this.refresh();
      }, 1000);
      return;
    }

    // always hidding the survey since it's not active anymore
    this.hidden = true; // !this.authService.currentUser || (userPreferences?.dismissedUxElements || []).indexOf(this.id) >= 0;

    this.cdr.markForCheck();
  }

  dismiss(id?: string) {
    id = id || this.id;
    const userPreferences = this.authService.currentUserPreferences;
    userPreferences.dismissedUxElements = userPreferences.dismissedUxElements || [];
    if (!userPreferences.dismissedUxElements.some(x => x === id)) {
      userPreferences.dismissedUxElements.push(id);
    }

    this.userService.updateUserPreferences({ value: this.authService.currentUserPreferences })
    .subscribe(() => {
      this.hidden = true;
      this.cdr.markForCheck();
    });
  }

  takeSurvey(url: string) {
    window.open(url, '_blank', environment.googleAnalyticsKey ? 'gaEventCategory=outbound-topbanner' : undefined);
  }

}

