/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Program / Project slide show image
 */
import { ValidateUtils } from 'app/shared/utils';

export class SlideShowImage { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    /**
     * Url for full-size image
     */
    readonly imageFullUrl?: string;
    /**
     * Height (pixels) for full-size image
     */
    readonly imageFullHeight?: number;
    /**
     * Width (pixels) for full-size image
     */
    readonly imageFullWidth?: number;
    /**
     * Url for resized image (600px height)
     */
    readonly imageResizedUrl?: string;
    /**
     * Url for thumbnail-size image
     */
    readonly imageThumbnailUrl?: string;
    /**
     * Height (pixels) for thumbnail-size image
     */
    readonly imageThumbnailHeight?: number;
    /**
     * Width (pixels) for thumbnail-size image
     */
    readonly imageThumbnailWidth?: number;
    /**
     * Url for download of image
     */
    readonly imageDownloadUrl?: string;
    /**
     * Image caption
     */
    readonly caption?: string;
    /**
     * Image order
     */
    readonly imageOrder?: number;
    /**
     * Image file name
     */
    readonly fileName?: string;
    /**
     * Image file size
     */
    readonly imageFileSize?: string;

    constructor(item?: Partial<SlideShowImage>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 36, 36);
                    }

                    return result;
                }
            },
            imageFullUrl: {
                type: 'uri',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageFullUrl : itemOrValue;

                    if (itemOrValue?.imageFullUrl != null) {
                        if (itemOrValue.imageFullUrl === '') {
                            itemOrValue.imageFullUrl = undefined;
                        } else if (['int32', 'int64'].indexOf('uri') >= 0) {
                            itemOrValue.imageFullUrl = parseInt(itemOrValue.imageFullUrl.toString(), 10);
                        } else if (['float'].indexOf('uri') >= 0) {
                            itemOrValue.imageFullUrl = parseFloat(itemOrValue.imageFullUrl.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uri');
                    if (['uuid'].indexOf('uri') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            imageFullHeight: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageFullHeight : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            imageFullWidth: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageFullWidth : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            imageResizedUrl: {
                type: 'uri',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageResizedUrl : itemOrValue;

                    if (itemOrValue?.imageResizedUrl != null) {
                        if (itemOrValue.imageResizedUrl === '') {
                            itemOrValue.imageResizedUrl = undefined;
                        } else if (['int32', 'int64'].indexOf('uri') >= 0) {
                            itemOrValue.imageResizedUrl = parseInt(itemOrValue.imageResizedUrl.toString(), 10);
                        } else if (['float'].indexOf('uri') >= 0) {
                            itemOrValue.imageResizedUrl = parseFloat(itemOrValue.imageResizedUrl.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uri');
                    if (['uuid'].indexOf('uri') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            imageThumbnailUrl: {
                type: 'uri',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageThumbnailUrl : itemOrValue;

                    if (itemOrValue?.imageThumbnailUrl != null) {
                        if (itemOrValue.imageThumbnailUrl === '') {
                            itemOrValue.imageThumbnailUrl = undefined;
                        } else if (['int32', 'int64'].indexOf('uri') >= 0) {
                            itemOrValue.imageThumbnailUrl = parseInt(itemOrValue.imageThumbnailUrl.toString(), 10);
                        } else if (['float'].indexOf('uri') >= 0) {
                            itemOrValue.imageThumbnailUrl = parseFloat(itemOrValue.imageThumbnailUrl.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uri');
                    if (['uuid'].indexOf('uri') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            imageThumbnailHeight: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageThumbnailHeight : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            imageThumbnailWidth: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageThumbnailWidth : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            imageDownloadUrl: {
                type: 'uri',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageDownloadUrl : itemOrValue;

                    if (itemOrValue?.imageDownloadUrl != null) {
                        if (itemOrValue.imageDownloadUrl === '') {
                            itemOrValue.imageDownloadUrl = undefined;
                        } else if (['int32', 'int64'].indexOf('uri') >= 0) {
                            itemOrValue.imageDownloadUrl = parseInt(itemOrValue.imageDownloadUrl.toString(), 10);
                        } else if (['float'].indexOf('uri') >= 0) {
                            itemOrValue.imageDownloadUrl = parseFloat(itemOrValue.imageDownloadUrl.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uri');
                    if (['uuid'].indexOf('uri') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            caption: {
                
                minLength: 0,
                maxLength: 1024,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.caption : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 0, 1024);
                    }

                    return result;
                }
            },
            imageOrder: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageOrder : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            fileName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.fileName : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            imageFileSize: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.imageFileSize : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: SlideShowImage.isValid,
        };
    }

    static isValid(obj: SlideShowImage): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = SlideShowImage.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.imageFullUrl.isValid(item);
            result = result && validationObject.imageFullHeight.isValid(item);
            result = result && validationObject.imageFullWidth.isValid(item);
            result = result && validationObject.imageResizedUrl.isValid(item);
            result = result && validationObject.imageThumbnailUrl.isValid(item);
            result = result && validationObject.imageThumbnailHeight.isValid(item);
            result = result && validationObject.imageThumbnailWidth.isValid(item);
            result = result && validationObject.imageDownloadUrl.isValid(item);
            result = result && validationObject.caption.isValid(item);
            result = result && validationObject.imageOrder.isValid(item);
            result = result && validationObject.fileName.isValid(item);
            result = result && validationObject.imageFileSize.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

