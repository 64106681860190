/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ScopeBoxType } from './scopeBoxType';
import { DiagramFactorType } from './diagramFactorType';
import { DiagramFactorSizeType } from './diagramFactorSizeType';
import { DiagramFactorLocationType } from './diagramFactorLocationType';
import { DiagramFactor } from './diagramFactor';
import { ThreatRatingDisplayEnum } from './threatRatingDisplayEnum';
import { ProgressReport } from './progressReport';
import { DiagramFactorFontSizeEnum } from './diagramFactorFontSizeEnum';
import { StrategyPriorityRatingEnum } from './strategyPriorityRatingEnum';
import { ViabilityStatusEnum } from './viabilityStatusEnum';
import { ViabilityModeEnum } from './viabilityModeEnum';
import { DiagramChildFactor } from './diagramChildFactor';
import { DiagramFactorZOrderEnum } from './diagramFactorZOrderEnum';
import { ResultReport } from './resultReport';
import { DiagramFactorFontStyleEnum } from './diagramFactorFontStyleEnum';


/**
 * Project diagram factor
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectDiagramFactor { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    diagramFactorType?: DiagramFactorType;
    location?: DiagramFactorLocationType;
    size?: DiagramFactorSizeType;
    factor?: DiagramFactor;
    factorViabilityMode?: ViabilityModeEnum;
    factorViabilityStatus?: ViabilityStatusEnum;
    factorThreatRating?: ThreatRatingDisplayEnum | null;
    factorStrategyRating?: StrategyPriorityRatingEnum;
    factorScopeBoxType?: ScopeBoxType;
    /**
     * Flag indicating whether wrapped factor is referenced in any results chain diagrams (returned for strategies)
     */
    isResultsChainStrategy?: boolean;
    /**
     * Diagram factor diagram references
     */
    readonly factorDiagramResourceIdentifiers?: Array<string>;
    /**
     * Flag indicating whether wrapped factor is referenced across multiple diagrams
     */
    isSharedFactor?: boolean;
    /**
     * Flag indicating whether diagram factor should be displayed as a shared factor
     */
    displayAsSharedFactor?: boolean;
    /**
     * Activities for diagram factor (Strategy)
     */
    readonly factorActivities?: Array<DiagramChildFactor>;
    /**
     * Nested targets for diagram factor (Target)
     */
    readonly factorNestedTargets?: Array<DiagramChildFactor>;
    /**
     * Stresses for diagram factor (Target)
     */
    readonly factorStresses?: Array<DiagramChildFactor>;
    /**
     * Goals for diagram factor
     */
    readonly factorGoals?: Array<DiagramChildFactor>;
    /**
     * Objectives for diagram factor
     */
    readonly factorObjectives?: Array<DiagramChildFactor>;
    /**
     * Indicators for diagram factor
     */
    readonly factorIndicators?: Array<DiagramChildFactor>;
    /**
     * Key Ecological Attributes for diagram factor (Target)
     */
    readonly factorKeas?: Array<DiagramChildFactor>;
    /**
     * Outputs for diagram factor (Strategy / Activity)
     */
    readonly factorOutputs?: Array<DiagramChildFactor>;
    /**
     * SubAssumptions for diagram factor (Assumption)
     */
    readonly factorSubAssumptions?: Array<DiagramChildFactor>;
    factorLatestProgressReport?: ProgressReport;
    factorLatestResultReport?: ResultReport;
    fontSize?: DiagramFactorFontSizeEnum;
    fontStyle?: DiagramFactorFontStyleEnum;
    /**
     * Diagram factor font color
     */
    fontColor?: string;
    /**
     * Diagram factor background color
     */
    backgroundColor?: string;
    /**
     * Diagram factor header height (only applies to group boxes)
     */
    groupBoxHeaderHeight?: number | null;
    textBoxZOrder?: DiagramFactorZOrderEnum;
    /**
     * Diagram factor z-index
     */
    zIndex?: number;
    /**
     * Diagram factor selected tags
     */
    readonly selectedTagResourceIdentifiers?: Array<string>;
    /**
     * Child diagram factors - only populated for group boxes
     */
    readonly childDiagramFactorResourceIdentifiers?: Array<string>;

    constructor(item?: Partial<ProjectDiagramFactor>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 36, 36);
                    }

                    return result;
                }
            },
            diagramFactorType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.diagramFactorType : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            location: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.location : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            size: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.size : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factor: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factor : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorViabilityMode: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorViabilityMode : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorViabilityStatus: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorViabilityStatus : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorThreatRating: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorThreatRating : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorStrategyRating: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorStrategyRating : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorScopeBoxType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorScopeBoxType : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isResultsChainStrategy: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isResultsChainStrategy : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorDiagramResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorDiagramResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.factorDiagramResourceIdentifiers != null) {
                        if (itemOrValue.factorDiagramResourceIdentifiers === '') {
                            itemOrValue.factorDiagramResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.factorDiagramResourceIdentifiers = parseInt(itemOrValue.factorDiagramResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.factorDiagramResourceIdentifiers = parseFloat(itemOrValue.factorDiagramResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isSharedFactor: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isSharedFactor : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            displayAsSharedFactor: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.displayAsSharedFactor : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorActivities: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorActivities : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorNestedTargets: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorNestedTargets : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorStresses: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorStresses : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorGoals: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorGoals : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorObjectives: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorObjectives : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorIndicators: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorIndicators : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorKeas: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorKeas : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorOutputs: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorOutputs : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorSubAssumptions: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorSubAssumptions : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorLatestProgressReport: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorLatestProgressReport : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorLatestResultReport: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorLatestResultReport : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            fontSize: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.fontSize : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            fontStyle: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.fontStyle : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            fontColor: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.fontColor : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            backgroundColor: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.backgroundColor : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            groupBoxHeaderHeight: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.groupBoxHeaderHeight : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            textBoxZOrder: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.textBoxZOrder : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            zIndex: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.zIndex : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            selectedTagResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.selectedTagResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.selectedTagResourceIdentifiers != null) {
                        if (itemOrValue.selectedTagResourceIdentifiers === '') {
                            itemOrValue.selectedTagResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.selectedTagResourceIdentifiers = parseInt(itemOrValue.selectedTagResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.selectedTagResourceIdentifiers = parseFloat(itemOrValue.selectedTagResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            childDiagramFactorResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.childDiagramFactorResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.childDiagramFactorResourceIdentifiers != null) {
                        if (itemOrValue.childDiagramFactorResourceIdentifiers === '') {
                            itemOrValue.childDiagramFactorResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.childDiagramFactorResourceIdentifiers = parseInt(itemOrValue.childDiagramFactorResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.childDiagramFactorResourceIdentifiers = parseFloat(itemOrValue.childDiagramFactorResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProjectDiagramFactor.isValid,
        };
    }

    static isValid(obj: ProjectDiagramFactor): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectDiagramFactor.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.diagramFactorType.isValid(item);
            result = result && validationObject.location.isValid(item);
            result = result && validationObject.size.isValid(item);
            result = result && validationObject.factor.isValid(item);
            result = result && validationObject.factorViabilityMode.isValid(item);
            result = result && validationObject.factorViabilityStatus.isValid(item);
            result = result && validationObject.factorThreatRating.isValid(item);
            result = result && validationObject.factorStrategyRating.isValid(item);
            result = result && validationObject.factorScopeBoxType.isValid(item);
            result = result && validationObject.isResultsChainStrategy.isValid(item);
            result = result && validationObject.factorDiagramResourceIdentifiers.isValid(item);
            result = result && validationObject.isSharedFactor.isValid(item);
            result = result && validationObject.displayAsSharedFactor.isValid(item);
            result = result && validationObject.factorActivities.isValid(item);
            result = result && validationObject.factorNestedTargets.isValid(item);
            result = result && validationObject.factorStresses.isValid(item);
            result = result && validationObject.factorGoals.isValid(item);
            result = result && validationObject.factorObjectives.isValid(item);
            result = result && validationObject.factorIndicators.isValid(item);
            result = result && validationObject.factorKeas.isValid(item);
            result = result && validationObject.factorOutputs.isValid(item);
            result = result && validationObject.factorSubAssumptions.isValid(item);
            result = result && validationObject.factorLatestProgressReport.isValid(item);
            result = result && validationObject.factorLatestResultReport.isValid(item);
            result = result && validationObject.fontSize.isValid(item);
            result = result && validationObject.fontStyle.isValid(item);
            result = result && validationObject.fontColor.isValid(item);
            result = result && validationObject.backgroundColor.isValid(item);
            result = result && validationObject.groupBoxHeaderHeight.isValid(item);
            result = result && validationObject.textBoxZOrder.isValid(item);
            result = result && validationObject.zIndex.isValid(item);
            result = result && validationObject.selectedTagResourceIdentifiers.isValid(item);
            result = result && validationObject.childDiagramFactorResourceIdentifiers.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

