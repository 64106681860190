/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';
import { map }                                               from 'rxjs/operators';


import { MiradiLicense } from '../model/miradiLicense';
import { ProblemDetail } from '../model/problemDetail';
import { ProgramPermission } from '../model/programPermission';
import { ProjectPermission } from '../model/projectPermission';
import { SupportRequest } from '../model/supportRequest';
import { User } from '../model/user';
import { UserPreferences } from '../model/userPreferences';
import { UserSearchResult } from '../model/userSearchResult';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';

import { CaseUtils } from 'app/shared/utils';



@Injectable({
  providedIn: 'root'
})
export class UserService {

    protected basePath = 'http://localhost/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.configuration.withCredentials = true;
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    public acceptMiradiLicenseRequiredPermission() {
        return '' || '';
    }

    /**
     * Accept Miradi license
     * Accept current Miradi license for end-user (must be authenticated)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acceptMiradiLicense(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public acceptMiradiLicense(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public acceptMiradiLicense(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public acceptMiradiLicense(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/miradilicense`,
            null,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public contactSupportRequiredPermission() {
        return '' || '';
    }

    /**
     * Contact Support
     * Contact Support
     * @param supportRequest Message for Support
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contactSupport(supportRequest: SupportRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public contactSupport(supportRequest: SupportRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public contactSupport(supportRequest: SupportRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public contactSupport(supportRequest: SupportRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (supportRequest === null || supportRequest === undefined) {
            throw new Error('Required parameter supportRequest was null or undefined when calling contactSupport.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/supportrequest`,
            CaseUtils.toSnake(supportRequest),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getAllProgramPermissionsRequiredPermission() {
        return '' || '';
    }

    /**
     * Get program-level feature permissions for end-user for all Programs
     * Get program-level feature permissions for end-user (must be authenticated) for all Programs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllProgramPermissions(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<ProgramPermission>>;
    public getAllProgramPermissions(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<ProgramPermission>>>;
    public getAllProgramPermissions(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<ProgramPermission>>>;
    public getAllProgramPermissions(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<ProgramPermission>>(`${this.configuration.basePath}/programpermissions`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramPermission(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramPermission(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getMiradiLicenseRequiredPermission() {
        return '' || '';
    }

    /**
     * Get Miradi license
     * Get current Miradi license
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMiradiLicense(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<MiradiLicense>;
    public getMiradiLicense(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<MiradiLicense>>;
    public getMiradiLicense(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<MiradiLicense>>;
    public getMiradiLicense(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<MiradiLicense>(`${this.configuration.basePath}/miradilicense`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new MiradiLicense(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new MiradiLicense(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProgramPermissionsRequiredPermission() {
        return '' || '';
    }

    /**
     * Get program-level feature permissions for end-user
     * Get program-level feature permissions for end-user
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProgramPermissions(programIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProgramPermission>;
    public getProgramPermissions(programIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProgramPermission>>;
    public getProgramPermissions(programIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProgramPermission>>;
    public getProgramPermissions(programIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling getProgramPermissions.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProgramPermission>(`${this.configuration.basePath}/programpermissions/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProgramPermission(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProgramPermission(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getProjectPermissionsRequiredPermission() {
        return '' || '';
    }

    /**
     * Get project-level feature permissions for end-user
     * Get project-level feature permissions for end-user
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjectPermissions(projectIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ProjectPermission>;
    public getProjectPermissions(projectIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ProjectPermission>>;
    public getProjectPermissions(projectIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ProjectPermission>>;
    public getProjectPermissions(projectIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling getProjectPermissions.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<ProjectPermission>(`${this.configuration.basePath}/projectpermissions/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new ProjectPermission(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new ProjectPermission(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getUserRequiredPermission() {
        return '' || '';
    }

    /**
     * Get profile for end-user
     * Get profile for end-user (must be authenticated)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<User>;
    public getUser(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<User>>;
    public getUser(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<User>>;
    public getUser(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<User>(`${this.configuration.basePath}/user`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new User(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new User(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public getUserPreferencesRequiredPermission() {
        return '' || '';
    }

    /**
     * Get user preferences for end-user
     * Returns user preferences for end-user (must be authenticated)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserPreferences(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UserPreferences>;
    public getUserPreferences(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UserPreferences>>;
    public getUserPreferences(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UserPreferences>>;
    public getUserPreferences(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<UserPreferences>(`${this.configuration.basePath}/userpreferences`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new UserPreferences(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new UserPreferences(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public searchProgramUsersRequiredPermission() {
        return '' || 'EditProgram';
    }

    /**
     * Search for Program members
     * Search for Program members
     * @param programIdentifier Program\&#39;s Unique identifier
     * @param userSearchArg User search arguments (email address, full name or username)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchProgramUsers(programIdentifier: string, userSearchArg: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<UserSearchResult>>;
    public searchProgramUsers(programIdentifier: string, userSearchArg: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<UserSearchResult>>>;
    public searchProgramUsers(programIdentifier: string, userSearchArg: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<UserSearchResult>>>;
    public searchProgramUsers(programIdentifier: string, userSearchArg: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (programIdentifier === null || programIdentifier === undefined) {
            throw new Error('Required parameter programIdentifier was null or undefined when calling searchProgramUsers.');
        }
        if (userSearchArg === null || userSearchArg === undefined) {
            throw new Error('Required parameter userSearchArg was null or undefined when calling searchProgramUsers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (userSearchArg !== undefined && userSearchArg !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userSearchArg, 'user_search_arg');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<UserSearchResult>>(`${this.configuration.basePath}/programusersearch/${encodeURIComponent(String(programIdentifier))}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new UserSearchResult(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new UserSearchResult(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public searchProjectUsersRequiredPermission() {
        return 'AddTeamMember' || '';
    }

    /**
     * Search for end-users (to add to project team)
     * Search for end-users (to add to project team)
     * @param projectIdentifier Project\&#39;s Unique identifier
     * @param userSearchArg User search arguments (email address, full name or username)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchProjectUsers(projectIdentifier: string, userSearchArg: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<UserSearchResult>>;
    public searchProjectUsers(projectIdentifier: string, userSearchArg: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<UserSearchResult>>>;
    public searchProjectUsers(projectIdentifier: string, userSearchArg: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<UserSearchResult>>>;
    public searchProjectUsers(projectIdentifier: string, userSearchArg: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (projectIdentifier === null || projectIdentifier === undefined) {
            throw new Error('Required parameter projectIdentifier was null or undefined when calling searchProjectUsers.');
        }
        if (userSearchArg === null || userSearchArg === undefined) {
            throw new Error('Required parameter userSearchArg was null or undefined when calling searchProjectUsers.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (userSearchArg !== undefined && userSearchArg !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>userSearchArg, 'user_search_arg');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<UserSearchResult>>(`${this.configuration.basePath}/projectusersearch/${encodeURIComponent(String(projectIdentifier))}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(new UserSearchResult(CaseUtils.toCamel(item)));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return new UserSearchResult(CaseUtils.toCamel(response));
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

    public updateUserPreferencesRequiredPermission() {
        return '' || '';
    }

    /**
     * Update user preferences for end-user
     * Update user preferences for end-user
     * @param userPreferences Updated user preferences
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserPreferences(userPreferences: UserPreferences, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateUserPreferences(userPreferences: UserPreferences, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateUserPreferences(userPreferences: UserPreferences, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateUserPreferences(userPreferences: UserPreferences, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (userPreferences === null || userPreferences === undefined) {
            throw new Error('Required parameter userPreferences was null or undefined when calling updateUserPreferences.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/userpreferences`,
            CaseUtils.toSnake(userPreferences),
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map((response: any) => {
                if (response) {
                    if (Array.isArray(response)) {
                        let result = [];
                        for (const item of response) {
                            result.push(CaseUtils.toCamel(item));
                        }
                        return result;
                    } else if (!(response instanceof Blob) && !(response instanceof HttpResponse)) {
                        return CaseUtils.toCamel(response);
                    } else {
                        return response;
                    }
                } else {
                    return null;
                }
            }),
        );
    }

}
