/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi diagram factor font size
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type DiagramFactorFontSizeEnum = 'Smallest' | 'Very Small' | 'Small' | 'Medium' | 'Large' | 'Very Large' | 'Largest';

export const DiagramFactorFontSizeEnum = {
    Smallest: 'Smallest' as DiagramFactorFontSizeEnum,
    VerySmall: 'Very Small' as DiagramFactorFontSizeEnum,
    Small: 'Small' as DiagramFactorFontSizeEnum,
    Medium: 'Medium' as DiagramFactorFontSizeEnum,
    Large: 'Large' as DiagramFactorFontSizeEnum,
    VeryLarge: 'Very Large' as DiagramFactorFontSizeEnum,
    Largest: 'Largest' as DiagramFactorFontSizeEnum
};

marker('Smallest');
marker('Very Small');
marker('Small');
marker('Medium');
marker('Large');
marker('Very Large');
marker('Largest');

