/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Target viability mode
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ViabilityModeEnum = 'Simple' | 'KEA';

export const ViabilityModeEnum = {
    Simple: 'Simple' as ViabilityModeEnum,
    Kea: 'KEA' as ViabilityModeEnum
};

marker('Simple');
marker('KEA');

