/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Progress report status
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ProgressReportStatusEnum = 'Planned' | 'Major Issues' | 'Minor Issues' | 'On Track' | 'Completed' | 'Abandoned' | 'Not Known' | 'Not Specified';

export const ProgressReportStatusEnum = {
    Planned: 'Planned' as ProgressReportStatusEnum,
    MajorIssues: 'Major Issues' as ProgressReportStatusEnum,
    MinorIssues: 'Minor Issues' as ProgressReportStatusEnum,
    OnTrack: 'On Track' as ProgressReportStatusEnum,
    Completed: 'Completed' as ProgressReportStatusEnum,
    Abandoned: 'Abandoned' as ProgressReportStatusEnum,
    NotKnown: 'Not Known' as ProgressReportStatusEnum,
    NotSpecified: 'Not Specified' as ProgressReportStatusEnum
};

marker('Planned');
marker('Major Issues');
marker('Minor Issues');
marker('On Track');
marker('Completed');
marker('Abandoned');
marker('Not Known');
marker('Not Specified');

