/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FactorTaxonomyConfiguration } from './factorTaxonomyConfiguration';


/**
 * Template taxonomy configurations by Factor type
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProgramTemplateTaxonomy { 
    /**
     * Template taxonomy set name
     */
    readonly name?: string;
    /**
     * Taxonomy configurations for each Factor type
     */
    factorTaxonomyConfigurations?: Array<FactorTaxonomyConfiguration>;

    constructor(item?: Partial<ProgramTemplateTaxonomy>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorTaxonomyConfigurations: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorTaxonomyConfigurations : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProgramTemplateTaxonomy.isValid,
        };
    }

    static isValid(obj: ProgramTemplateTaxonomy): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProgramTemplateTaxonomy.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.factorTaxonomyConfigurations.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

