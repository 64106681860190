import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { FactorType, ProgramsService, ProgramTemplate, ProgramTemplateTaxonomy } from '../api-generated';


@Injectable({
  providedIn: 'root'
})
export class ProgramsCachedService {

  readonly invalidateCacheTimeout = 5 * 60 * 1000;

  private cachedCallRequestDateMap: any;
  private cachedCallResponseMap: any;
  private cachedProgramIdentifier: string;


  constructor(
    private programsService: ProgramsService,
  ) {
    this.invalidateCache();
  }

  invalidateCache(key?: string, ...params: any) {
    if (key) {
      const cacheKey = key + (this.cachedProgramIdentifier || '') + params.join('');
      delete this.cachedCallRequestDateMap[cacheKey];
      delete this.cachedCallResponseMap[cacheKey];
    } else {
      this.cachedProgramIdentifier = undefined;
      this.cachedCallRequestDateMap = {};
      this.cachedCallResponseMap = {};
    }
  }

  private callApiOrReturnCachedValue(key: string, programIdentifier: string, ...params: any) {
    const cacheKey = key + programIdentifier + params.join('');
    if (
      !this.cachedProgramIdentifier ||
      this.cachedProgramIdentifier !== programIdentifier ||
      Date.now() - (this.cachedCallRequestDateMap[cacheKey] || 0) > this.invalidateCacheTimeout
    ) {
      if (this.cachedProgramIdentifier !== programIdentifier) {
        this.invalidateCache();
      }

      this.cachedProgramIdentifier = programIdentifier;

      this.cachedCallRequestDateMap[cacheKey] = Date.now();
      delete this.cachedCallResponseMap[cacheKey];
      return this.programsService[key](programIdentifier, ...params)
      .pipe(
        map((response: any) => {
          this.cachedCallResponseMap[cacheKey] = response;
          return response;
        })
      );
    } else if (this.cachedCallResponseMap[cacheKey]) {
      return of(this.cachedCallResponseMap[cacheKey]);
    } else {
      // a previous request is currently in progress, so try again in a few milliseconds to see if the result has become available
      return new Observable((observer: Observer<any>) => {
        setTimeout(() => {
          (params?.length ? this.callApiOrReturnCachedValue(key, programIdentifier, params) : this.callApiOrReturnCachedValue(key, programIdentifier))
          .subscribe((response: any) => {
            observer.next(response);
            observer.complete();
          });
        }, 250);
      });
    }
  }

  getProgramCustomReports(programIdentifier: string) {
    const key = 'getProgramCustomReports';
    return this.callApiOrReturnCachedValue(key, programIdentifier);
  }

  getProgramMembers(programIdentifier: string) {
    const key = 'getProgramMembers';
    return this.callApiOrReturnCachedValue(key, programIdentifier);
  }

  getProgramResources(programIdentifier: string) {
    const key = 'getProgramResources';
    return this.callApiOrReturnCachedValue(key, programIdentifier);
  }

  getProgramPortfolios(programIdentifier: string) {
    const key = 'getProgramPortfolios';
    return this.callApiOrReturnCachedValue(key, programIdentifier);
  }

  getProgramProjectTeamMembers(programIdentifier: string) {
    const key = 'getProgramProjectTeamMembers';
    return this.callApiOrReturnCachedValue(key, programIdentifier);
  }

  getProgramLatestFactorTaxonomies(programIdentifier: string, factorType?: FactorType) {
    const key1 = 'getProgramTemplates';
    const key2 = 'getProgramTemplateTaxonomies';
    return this.callApiOrReturnCachedValue(key1, programIdentifier)
    .pipe(
      mergeMap((pt: ProgramTemplate[]) => {
        if (pt && pt.length) {
          return this.callApiOrReturnCachedValue(key2, programIdentifier, pt[0].identifier);
        } else {
          return of({});
        }
      }),
      map((ptt: ProgramTemplateTaxonomy) => {
        if (ptt && ptt.factorTaxonomyConfigurations) {
          const result = [];
          for (const ftc of ptt.factorTaxonomyConfigurations) {
            if (!factorType || ftc.factorType === factorType) {
              result.push(...ftc.factorTaxonomies);
            }
          }
          return result;
        } else {
          return [];
        }
      })
    );
  }

  getProgramTemplates(programIdentifier: string) {
    const key = 'getProgramTemplates';
    return this.callApiOrReturnCachedValue(key, programIdentifier);
  }

}
