import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ProblemDetail } from '../api-generated/model/problemDetail';



@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {

  }

  apiError(error: ProblemDetail | any, life?: number, clearOtherMessages = true): void {
    if (!error?.errorCode && !error?.detail) return;

    const errorSummary = error.status === 400 ?
      this.translateService.instant('Invalid Request') :
      error.status === 401 ?
      this.translateService.instant('Logon Required') :
      error.status === 403 ?
      this.translateService.instant('Forbidden') :
      error.title;
    const errorDetail = error.status === 401 ?
      this.translateService.instant('This action requires an active session. Redirecting you to logon...') :
      error.status === 403 ?
      this.translateService.instant('You don’t have the permission to access the requested resource.') :
      error.errorCode && this.translateService.instant(error.errorCode) !== error.errorCode ?
      this.translateService.instant(error.errorCode) :
      this.translateService.instant('Something went wrong. If the problem persists, please contact support.');

    if (clearOtherMessages) {
      this.clear('global');
    }

    this.messageService.add({
      closable: true,
      detail: errorDetail,
      key: 'global',
      severity: error.status === 401 ? 'info' : 'error',
      summary: errorSummary,
      life: life || this.getDefaultDuration(errorDetail),
      sticky: life === 0,
    });
  }

  clear(key = 'global'): void {
    this.messageService.clear(key);
  }

  error(summary: string, detail: string, life?: number): void {
    this.messageService.add({
      closable: true,
      detail: detail,
      key: 'global',
      severity: 'error',
      summary: summary,
      life: life || this.getDefaultDuration(detail),
      sticky: life === 0,
    });
  }

  warn(summary: string, detail: string, life?: number): void {
    this.messageService.add({
      closable: true,
      detail: detail,
      key: 'global',
      severity: 'warn',
      summary: summary,
      life: life || this.getDefaultDuration(detail),
      sticky: life === 0,
    });
  }

  info(summary: string, detail: string, life?: number, data?: any, key = 'global'): void {
    this.messageService.add({
      closable: true,
      data: data,
      detail: detail,
      key: key,
      severity: 'info',
      summary: summary,
      life: life || this.getDefaultDuration(detail),
      sticky: life === 0,
    });
  }

  success(summary: string, detail: string, life?: number): void {
    this.messageService.add({
      closable: true,
      detail: detail,
      key: 'global',
      severity: 'success',
      summary: summary,
      life: life || this.getDefaultDuration(detail),
      sticky: life === 0,
    });
  }

  private getDefaultDuration(text: string): number {
    // Calculate the amount of time needed to read the notification
    const wpm = 180; // readable words per minute
    const wordLength = 5;  // standardized number of chars in calculable word
    const words = (text || '').length / wordLength;
    const wordsTime = ((words / wpm) * 60) * 1000;

    const delay = 500; // milliseconds before user starts reading the notification
    const bonus = 500; // extra time

    return delay + wordsTime + bonus;
  }
}
