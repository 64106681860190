/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi diagram z order update action
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type DiagramZOrderUpdateEnum = 'Bring To Front' | 'Bring Forward' | 'Send To Back' | 'Send Backward';

export const DiagramZOrderUpdateEnum = {
    BringToFront: 'Bring To Front' as DiagramZOrderUpdateEnum,
    BringForward: 'Bring Forward' as DiagramZOrderUpdateEnum,
    SendToBack: 'Send To Back' as DiagramZOrderUpdateEnum,
    SendBackward: 'Send Backward' as DiagramZOrderUpdateEnum
};

marker('Bring To Front');
marker('Bring Forward');
marker('Send To Back');
marker('Send Backward');

