<p-dialog
  styleClass="edit-custom-report"
  [closable]="false"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="false"
  [(visible)]="isVisible"
  (onHide)="editableItem = undefined">

  <p-header>
    <div>
      {{ editableItem?.resourceIdentifier ? ('Edit Custom Data View' | translate) : ('Add New Custom Data View' | translate) }}
    </div>

    <p-button *ngIf="editableItem?.resourceIdentifier"
      class="tertiary-action"
      [text]="true"
      icon="fas fa-trash"
      [pTooltip]="'Delete Custom Data View' | translate"
      tooltipPosition="left"
      tabindex="-1"
      (click)="deleteConfirmMode = true">
    </p-button>

    <div *ngIf="deleteConfirmMode"
      class="confirm-header-bar">
      <div class="flex-row middle" style="height: 100%">
        <i class="icon fas fa-exclamation-circle"></i>

        <div>
          {{ 'Are you sure you wish to delete this data view?' | translate }}
        </div>

        <div class="flex-1"></div>

        <p-button class="button-cancel"
          [text]="true"
          [label]="'Cancel' | translate"
          (click)="deleteConfirmMode = false">
        </p-button>

        <p-button
          icon="fas fa-trash"
          [label]="'Delete' | translate"
          (click)="delete()"
          [raised]="true"
          severity="primary">
        </p-button>
      </div>
    </div>
  </p-header>

  <p-messages
    [closable]="false"
    [(value)]="validationMessages">
  </p-messages>

  <div *ngIf="editableItem"
    class="content">

    <miradi-form-field
      [autoFocus]="true"
      [disabled]="deleteConfirmMode"
      [editActive]="true"
      [label]="'Name' | translate"
      type="text"
      [validationObject]="validationObject.name"
      [(ngModel)]="editableItem.name">
    </miradi-form-field>

    <miradi-form-field
      [disabled]="deleteConfirmMode"
      [editActive]="true"
      [label]="'Description' | translate"
      type="textarea"
      [validationObject]="validationObject.description"
      [(ngModel)]="editableItem.description">
    </miradi-form-field>

    <miradi-form-field
      [disabled]="deleteConfirmMode"
      [editActive]="true"
      [label]="'Guidance' | translate"
      type="textarea"
      [validationObject]="validationObject.guidance"
      [(ngModel)]="editableItem.guidance">
    </miradi-form-field>

    <miradi-form-field
      [disabled]="deleteConfirmMode"
      [editActive]="true"
      [label]="'Tags (hit ENTER to complete entry)' | translate"
      type="tags"
      [validationObject]="validationObject.filterTags"
      [(ngModel)]="editableItem.filterTags">
    </miradi-form-field>

  </div>

  <p-footer>
    <p-button
      [text]="true"
      [disabled]="deleteConfirmMode"
      [label]="'Cancel' | translate"
      (click)="!deleteConfirmMode ? dismiss() : null">
    </p-button>

    <p-button
      icon="fas fa-check"
      [disabled]="deleteConfirmMode"
      [label]="'Save' | translate"
      (click)="!deleteConfirmMode ? save() : null"
      [raised]="true"
      severity="primary">
    </p-button>
  </p-footer>
</p-dialog>
