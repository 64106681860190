/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Custom report types
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ReportType = 'Factor Summary' | 'Factor Classifications' | 'Assumptions' | 'Factor Evidence' | 'Target Summary' | 'Viability Assessment' | 'Viability Assessment (All)' | 'Threat Summary' | 'Project Threat Summary' | 'Goal Objective Assessment' | 'Goal Objective Assessment (All)' | 'Goal Objective Action Progress' | 'Strategy Activity Progress' | 'Strategy Activity Progress Chart' | 'Strategy Activity Work Plan' | 'Results Status' | 'Results Status (All)';

export const ReportType = {
    FactorSummary: 'Factor Summary' as ReportType,
    FactorClassifications: 'Factor Classifications' as ReportType,
    Assumptions: 'Assumptions' as ReportType,
    FactorEvidence: 'Factor Evidence' as ReportType,
    TargetSummary: 'Target Summary' as ReportType,
    ViabilityAssessment: 'Viability Assessment' as ReportType,
    ViabilityAssessmentAll: 'Viability Assessment (All)' as ReportType,
    ThreatSummary: 'Threat Summary' as ReportType,
    ProjectThreatSummary: 'Project Threat Summary' as ReportType,
    GoalObjectiveAssessment: 'Goal Objective Assessment' as ReportType,
    GoalObjectiveAssessmentAll: 'Goal Objective Assessment (All)' as ReportType,
    GoalObjectiveActionProgress: 'Goal Objective Action Progress' as ReportType,
    StrategyActivityProgress: 'Strategy Activity Progress' as ReportType,
    StrategyActivityProgressChart: 'Strategy Activity Progress Chart' as ReportType,
    StrategyActivityWorkPlan: 'Strategy Activity Work Plan' as ReportType,
    ResultsStatus: 'Results Status' as ReportType,
    ResultsStatusAll: 'Results Status (All)' as ReportType
};

marker('Factor Summary');
marker('Factor Classifications');
marker('Assumptions');
marker('Factor Evidence');
marker('Target Summary');
marker('Viability Assessment');
marker('Viability Assessment (All)');
marker('Threat Summary');
marker('Project Threat Summary');
marker('Goal Objective Assessment');
marker('Goal Objective Assessment (All)');
marker('Goal Objective Action Progress');
marker('Strategy Activity Progress');
marker('Strategy Activity Progress Chart');
marker('Strategy Activity Work Plan');
marker('Results Status');
marker('Results Status (All)');

