export class BrowserUtils {

  static scrollbarWidth: number;

  static getQueryParams(): any {
    if (window.location.search?.length) {
      return window.location.search.substr(1).split('&').reduce((q, query) => {
        const chunks = query.split('=');
        const key = chunks[0];
        const value = decodeURIComponent(chunks[1]);
        return (q[key] = value, q);
      }, {});
    } else {
      return {};
    }
  }

  static getScrollbarWidth() {
    if (BrowserUtils.scrollbarWidth != null) {
      return BrowserUtils.scrollbarWidth;
    }

    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    (outer.style as any).msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    BrowserUtils.scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return BrowserUtils.scrollbarWidth;
  }

  static getNavigatorLanguage(): string {
    return (navigator as any).userLanguage || navigator.language || (navigator as any).browserLanguage || navigator.languages?.[0] || 'en';
  }

  static isInIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  static detectIE() {
    return navigator.userAgent.indexOf('MSIE') >= 0 || // IE 6-10
      navigator.appVersion.indexOf('Trident/') >= 0  || // IE 11
      (!!(window as any).MSInputMethodContext && !!(document as any).documentMode) // IE 11 (alternative check)
  }

  static isMac() {
    return navigator.platform.indexOf('Mac') >= 0;
  }

}
