import { NgModule } from '@angular/core';
import { ConcatenateIdAndNamePipe } from './concatenate-id-and-name.pipe';
import { DecodeTextPipe } from './decode-text.pipe';
import { HighlightPipe } from './highlight.pipe';
import { LocaleDatePipe } from './locale-date.pipe';
import { ParseDatePipe } from './parse-date.pipe';
import { ToClassStringPipe } from './to-class-string.pipe';
import { ToLabelStringPipe } from './to-label-string.pipe';


@NgModule({
  declarations: [
    ConcatenateIdAndNamePipe,
    DecodeTextPipe,
    LocaleDatePipe,
    HighlightPipe,
    ParseDatePipe,
    ToClassStringPipe,
    ToLabelStringPipe,
  ],
  exports: [
    ConcatenateIdAndNamePipe,
    DecodeTextPipe,
    LocaleDatePipe,
    HighlightPipe,
    ParseDatePipe,
    ToClassStringPipe,
    ToLabelStringPipe,
  ],
  imports: [

  ],
})
export class PipesModule { }
