import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplicationInsights, IDependencyTelemetry, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { TranslateLoader, TranslateModule, TranslateParser, TranslateService } from '@ngx-translate/core';
import { createTranslateLoader, createTranslateParser } from 'app/app.providers';
import { BASE_PATH, GlobalBusyService, LanguageService, NotificationService } from 'app/shared/services';
import { environment } from 'environments/environment';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MessageService, TreeDragDropService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppSharedModule } from './shared/app-shared.module';
import { EulaBannerModule } from './shared/components/eula-banner/eula-banner.module';
import { MaintenanceBannerModule } from './shared/components/maintenance-banner/maintenance-banner.module';
import { SurveyBannerModule } from './shared/components/survey-banner/survey-banner.module';
const { name, version } = require('../../package.json');



if (environment.appInsightsKey) {
  initAppInsights();
}

function initAppInsights() {
  const appInsights = new ApplicationInsights({ config: {
    appId: name + '@' + version,
    enableAutoRouteTracking: true,
    maxAjaxCallsPerView: -1,
    connectionString: `InstrumentationKey=${environment.appInsightsKey};IngestionEndpoint=https://westus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus.livediagnostics.monitor.azure.com/`
  } });

  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((telemetryItem: ITelemetryItem) => {
    if (
      telemetryItem?.baseData &&
      [0, 401].indexOf((telemetryItem.baseData as IDependencyTelemetry).responseCode) >= 0
    ) {
      return false;
    }

    if ((window as any).diagramUndoRedo) {
      telemetryItem.data.diagramUndoRedo = (window as any).diagramUndoRedo;
    }
  });

  (window as any).appInsights = appInsights;
}



@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  private globalBusyService: GlobalBusyService;
  private notificationService: NotificationService;
  private translateService: TranslateService;

  constructor(
    private injector: Injector,
  ) {
    this.globalBusyService = this.injector.get(GlobalBusyService);
    this.notificationService = this.injector.get(NotificationService);
    this.translateService = this.injector.get(TranslateService);
  }

  handleError(error: any) {
    if (
      error &&
      error.status !== 401 && // Unauthorized
      error.status !== 403 && // Forbidden
      error.status !== 404 && // Not Found (can easily happen when looking for a unexisting .po file)
      (error.message || '').indexOf('ViewDestroyedError: Attempt to use a destroyed view: detectChanges') < 0 && // issue in the ngx-loading package...waiting for it to be updated.
      (error.message || '').indexOf('ExpressionChangedAfterItHasBeenCheckedError') < 0 && // this only happens in dev angular build - I'm sure
      (error.message || '').indexOf('Loading chunk') < 0 // also ignore loading chunk errors as they're handled in app.component NavigationError event
    ) {
      // IE Bug
      if ((error.message || '').indexOf('available to complete this operation.') >= 0) {
        this.notificationService.error(
          this.translateService.instant('Internet Explorer Error'),
          error.message,
        );
      }

      console.error(error);

      if ((window as any).appInsights) {
        (window as any).appInsights.trackException({
          exception: error.originalError || error
        });
      }
    } else if (error) {
      console.warn(error);
      this.globalBusyService.setBusy(false);
    }
  }

}

@NgModule({
  bootstrap: [
    AppComponent,
  ],
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    AppSharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    EulaBannerModule,
    HttpClientModule,
    MaintenanceBannerModule,
    NgCircleProgressModule.forRoot({
      animation: false,
      maxPercent: 100,
      outerStrokeWidth: 12,
      outerStrokeColor: '#A970AD', // '#3496A1', // 'green',
      outerStrokeLinecap: 'butt',
      innerStrokeColor: '#e7e8ea',
      innerStrokeWidth: 12,
      radius: 6,
      space: -12,
      showBackground: false,
      showTitle: false,
      showSubtitle: false,
      showUnits: false,
      responsive: false,
    }),
    SurveyBannerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      parser: {
        provide: TranslateParser,
        useFactory: createTranslateParser,
        deps: [LanguageService]
      }
    }),
    ToastModule,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: environment.appBaseHref,
    },
    {
      provide: BASE_PATH,
      useValue: environment.environmentBaseUrl + environment.apiBaseUrl,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },

    MessageService,
    TreeDragDropService,
  ],
})
export class AppModule { }
