
export class ArrayUtils {

  static uniqBy(array: any[], getKeyValueCallback: Function): any[] {
    const seen = {};
    return array.filter((item: any) => {
        var k = getKeyValueCallback(item);
        return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  static areEqual(array1: any[], array2: any[], getKeyValueCallback: Function): boolean {
    return array1.length === array2.length
    && array1.slice().every((item, index) => {
      return getKeyValueCallback(item) === getKeyValueCallback(array2[index]);
    });
  }

}