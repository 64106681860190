import { Injectable } from '@angular/core';
import { TranslateParser } from '@ngx-translate/core';
import { LanguageService } from '../app/language.service';




@Injectable()
export class TranslatePoParser extends TranslateParser {
  templateMatcher: RegExp = /{{\s?([^{}\s]*)\s?}}/g;

  constructor(
    private languageService: LanguageService,
  ) {
    super();
  }

  interpolate(expr: string | Function, params?: any): string {
    let result: string;

    if (typeof expr === 'string') {
      result = this.interpolateString(expr, params);
    } else if (typeof expr === 'function') {
      result = this.interpolateFunction(expr, params);
    } else {
      // this should not happen, but an unrelated TranslateService test depends on it
      result = expr as string;
    }

    return result;
  }

  getValue(translations: any, key: string): any {
    const context = (this.languageService.currentTerminology || {}).programIdentifier;

    if (this.isDefined(translations)) {
      if (this.isDefined(translations[context]) && this.isDefined(translations[context][key])) {
        return translations[context][key];
      } else {
        return this.isDefined(translations[''][key]) ? translations[''][key] : key;
      }
    } else {
      return key;
    }
  }

  private interpolateFunction(fn: Function, params?: any) {
    return fn(params);
  }

  private interpolateString(expr: string, params?: any) {
    if (!params) {
      return expr;
    }

    return expr.replace(this.templateMatcher, (substring: string, b: string) => {
      let r = params ? params[b] : undefined;
      return this.isDefined(r) ? r : substring;
    });
  }

  private isDefined(value: any): boolean {
    return typeof value !== 'undefined' && value !== null;
  }
}