/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi diagram types
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type DiagramType = 'Conceptual Model' | 'Results Chain';

export const DiagramType = {
    ConceptualModel: 'Conceptual Model' as DiagramType,
    ResultsChain: 'Results Chain' as DiagramType
};

marker('Conceptual Model');
marker('Results Chain');

