/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Measurement precision type
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type MeasurementPrecisionTypeEnum = 'Standard Deviation' | 'Standard Error' | 'Confidence Interval (95 Percent)' | 'Confidence Interval (Other)';

export const MeasurementPrecisionTypeEnum = {
    StandardDeviation: 'Standard Deviation' as MeasurementPrecisionTypeEnum,
    StandardError: 'Standard Error' as MeasurementPrecisionTypeEnum,
    ConfidenceInterval95Percent: 'Confidence Interval (95 Percent)' as MeasurementPrecisionTypeEnum,
    ConfidenceIntervalOther: 'Confidence Interval (Other)' as MeasurementPrecisionTypeEnum
};

marker('Standard Deviation');
marker('Standard Error');
marker('Confidence Interval (95 Percent)');
marker('Confidence Interval (Other)');

