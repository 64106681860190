export class CaseUtils {

  static toCamel(obj: any): any {
    if (
      typeof obj != 'object' ||
      typeof obj === 'string'
    ) return obj;

    obj = JSON.parse(JSON.stringify(obj));

    let newO, origKey, newKey, value;
    if (obj instanceof Array) {
      return obj.map(function(value) {
        if (value != null && typeof value === 'object') {
          value = CaseUtils.toCamel(value);
        }
        return value;
      })
    } else {
      newO = {};
      for (origKey in obj) {
        if (obj.hasOwnProperty(origKey)) {
          newKey = CaseUtils.camelize(origKey);
          value = obj[origKey];
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = CaseUtils.toCamel(value);
          }
          newO[newKey] = value;
        }
      }
    }
    return newO;
  }

  static camelize(str): string {
    return str.split('_').map(function(word,index){
      // If it is the first word make sure to lowercase all the chars.
      if(index == 0){
        return word.toLowerCase();
      }
      // If it is not the first word only upper case the first char and lowercase the rest.
      return CaseUtils.toUpperFirstLetter(word);
    }).join('');
  }

  static toSnake(obj: any): any {
    if (
      typeof obj != 'object' ||
      typeof obj === 'string' ||
      (obj?.type && obj?.type === 'FeatureCollection')
    ) return obj;

    obj = JSON.parse(JSON.stringify(obj, (_key, value) => (value instanceof Set ? [...value] : value)));

    for(var oldName in obj){

      // Camel to underscore
      let newName = oldName.replace(/([A-Z])/g, function($1){return '_' + $1.toLowerCase();});

      // Only process if names are different
      if (newName != oldName) {
        // Check for the old property name to avoid a ReferenceError in strict mode.
        if (obj.hasOwnProperty(oldName)) {
          obj[newName] = obj[oldName];
          delete obj[oldName];
        }
      }

      // Recursion
      if (typeof(obj[newName]) == 'object') {
        obj[newName] = CaseUtils.toSnake(obj[newName]);
      }

    }
    return obj;
  }

  static toUpperFirstLetter(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

}
