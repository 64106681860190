import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SystemService } from '../api-generated';


@Injectable({
  providedIn: 'root'
})
export class SystemCachedService {

  readonly invalidateCacheTimeout = 60 * 60 * 1000;

  private cachedCallRequestDateMap: any;
  private cachedCallResponseMap: any;


  constructor(
    private systemService: SystemService,
  ) {
    this.invalidateCache();
  }

  invalidateCache(key?: string) {
    if (key) {
      delete this.cachedCallRequestDateMap[key];
      delete this.cachedCallResponseMap[key];
    } else {
      this.cachedCallRequestDateMap = {};
      this.cachedCallResponseMap = {};
    }
  }

  private callApiOrReturnCachedValue(key: string, ...params: any): Observable<any> {
    const cacheKey = key + params.join('');
    if (
      Date.now() - (this.cachedCallRequestDateMap[cacheKey] || 0) > this.invalidateCacheTimeout
    ) {
      this.cachedCallRequestDateMap[cacheKey] = Date.now();
      delete this.cachedCallResponseMap[cacheKey];
      return this.systemService[key](...params)
      .pipe(
        map((response: any) => {
          this.cachedCallResponseMap[cacheKey] = response;
          return response;
        })
      );
    } else if (this.cachedCallResponseMap[cacheKey]) {
      return of(this.cachedCallResponseMap[cacheKey]);
    } else {
      // a previous request is currently in progress, so try again in a few milliseconds to see if the result has become available
      return new Observable((observer: Observer<any>) => {
        setTimeout(() => {
          (params?.length ? this.callApiOrReturnCachedValue(key, params) : this.callApiOrReturnCachedValue(key))
          .subscribe((response: any) => {
            observer.next(response);
            observer.complete();
          });
        }, 250);
      });
    }
  }

  getParentFactorTypes() {
    const key = 'getParentFactorTypes';
    return this.callApiOrReturnCachedValue(key, 'response')
    .pipe(
      map((response: HttpResponse<any>) => {
        return response.body
      })
    );
  }

  getMiradiDesktopFiles() {
    const key = 'getMiradiDesktopFiles';
    return this.callApiOrReturnCachedValue(key);
  }

  getSettings() {
    const key = 'getSettings';
    return this.callApiOrReturnCachedValue(key);
  }

}
