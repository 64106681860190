import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormFieldComponent } from 'app/shared/components/form-field/form-field.component';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { GlobalBusyService, NotificationService } from 'app/shared/services';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { FileUpload } from 'primeng/fileupload';
import { Observable } from 'rxjs';



@Component({
  selector: 'miradi-add-files-dialog',
  templateUrl: './add-files.dialog.html',
  styleUrls: ['./add-files.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddFilesDialog extends BaseDialog {

  @ViewChild(FileUpload, { static: true }) fileUpload: FileUpload;
  @ViewChildren(FormFieldComponent) fields: QueryList<FormFieldComponent>;

  emptyFiles: File[];

  accept: string;
  extraActions: any[];
  extraFields: any;
  extraFieldsAfterFileUploader: boolean;
  fileIsOptional: boolean;
  maxFileSize: number;
  missingFile: boolean;
  multiple: boolean;
  subTitle: string;
  title: string;

  saveCallback: (files: File[], extraFields: any[]) => Observable<any>;

  constructor(
    authService: AuthService,
    private globalBusyService: GlobalBusyService,
    cdr: ChangeDetectorRef,
    el: ElementRef,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {
    super(authService, cdr, el);

    this.emptyFiles = [];
  }

  init(data: any) {
    this.missingFile = false;
    this.validationMessages = [];

    if (data) {
      this.accept = data.accept;
      this.extraActions = data.extraActions;
      this.extraFields = data.extraFields;
      this.extraFieldsAfterFileUploader = data.extraFieldsAfterFileUploader;
      this.fileIsOptional = data.fileIsOptional;
      this.maxFileSize = data.maxFileSize;
      this.multiple = data.multiple;
      this.subTitle = data.subTitle;
      this.title = data.title;
      this.saveCallback = data.saveCallback;
    }

    setTimeout(() => {
      if (this.fileUpload) {
        this.fileUpload.clear();
        this.cdr.markForCheck();
      }
    });
  }

  isInConfirmMode() {
    return this.extraActions && this.extraActions.some((action: any) => {
      return action.confirmMode;
    });
  }

  save() {
    this.validationMessages = [];
    this.cdr.markForCheck();

    this.fields.forEach((field: FormFieldComponent) => {
      field.forceValidate();
    });

    let missingRequiredField = undefined;
    for (const extraField of this.extraFields || []) {
      missingRequiredField = extraField.validationObject.minLength && !extraField.value ?
      extraField.label :
      undefined;

      if (missingRequiredField) { break; }
    }

    if (missingRequiredField) {
      this.validationMessages.push({
        severity: 'error',
        summary: this.translateService.instant('Missing field:'),
        detail: this.translateService.instant('Please add a ') + missingRequiredField,
      });
      this.cdr.markForCheck();
    } else if (
      this.fileIsOptional ||
      (this.fileUpload.files && this.fileUpload.files.length)
     ) {
      this.missingFile = false;
      this.globalBusyService.setBusy(true);

      this.saveCallback(this.fileUpload.files, this.extraFields)
      .subscribe((response: any) => {
        this.globalBusyService.setBusy(false);
        this.notificationService.success(
          this.translateService.instant('Success'),
          this.translateService.instant('Changes saved successfully.')
        );

        this.close(response);
      }, (error: any) => {
        this.globalBusyService.setBusy(false);
        this.cdr.markForCheck();
      });
    } else {
      this.missingFile = true;
      this.validationMessages.push({
        severity: 'error',
        summary: this.translateService.instant('Missing field:'),
        detail: this.translateService.instant('Please choose a file.'),
      });
      this.cdr.markForCheck();
    }
  }

}
