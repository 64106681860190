/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DiagramType } from './diagramType';
import { ProjectDiagramGuideFactorInfo } from './projectDiagramGuideFactorInfo';
import { ProjectDiagramGuideFactorLink } from './projectDiagramGuideFactorLink';


/**
 * Project diagram guide content
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectDiagramGuideContent { 
    diagramType?: DiagramType;
    /**
     * Factor identifier
     */
    identifier?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    name?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    details?: string;
    /**
     * Top-level factors for guide
     */
    factors?: Array<ProjectDiagramGuideFactorInfo>;
    /**
     * Factor links for guide
     */
    factorLinks?: Array<ProjectDiagramGuideFactorLink>;

    constructor(item?: Partial<ProjectDiagramGuideContent>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            diagramType: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.diagramType : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            identifier: {
                
                minLength: 0,
                maxLength: 255,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 0, 255);
                    }

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            details: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.details : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factors: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factors : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorLinks: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorLinks : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProjectDiagramGuideContent.isValid,
        };
    }

    static isValid(obj: ProjectDiagramGuideContent): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectDiagramGuideContent.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.diagramType.isValid(item);
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.details.isValid(item);
            result = result && validationObject.factors.isValid(item);
            result = result && validationObject.factorLinks.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

