/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Evidence confidence (external)
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type EvidenceConfidenceExternalEnum = 'Rough Guess' | 'Expert Knowledge' | 'External Research' | 'On-Site Research';

export const EvidenceConfidenceExternalEnum = {
    RoughGuess: 'Rough Guess' as EvidenceConfidenceExternalEnum,
    ExpertKnowledge: 'Expert Knowledge' as EvidenceConfidenceExternalEnum,
    ExternalResearch: 'External Research' as EvidenceConfidenceExternalEnum,
    OnSiteResearch: 'On-Site Research' as EvidenceConfidenceExternalEnum
};

marker('Rough Guess');
marker('Expert Knowledge');
marker('External Research');
marker('On-Site Research');

