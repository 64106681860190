<div *ngIf="!!maintenanceText"
  class="flex-row gap-1 center middle maintenance-banner">

  <i class="icon fas fa-exclamation-triangle"></i>

  <div class="label">
    {{ maintenanceText }}
  </div>

</div>
