/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ViabilityRatingEnum } from './viabilityRatingEnum';


/**
 * Indicator future status
 */
import { ValidateUtils } from 'app/shared/utils';

export class IndicatorFutureStatusNew { 
    /**
     * Future Status name
     */
    name?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    details?: string;
    /**
     * Miradi formatted_text fields (allows limited html markup)
     */
    comments?: string;
    /**
     * Status date
     */
    statusDate?: string;
    /**
     * Future Status summary
     */
    summary?: string;
    rating?: ViabilityRatingEnum;

    constructor(item?: Partial<IndicatorFutureStatusNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            details: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.details : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            comments: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.comments : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            statusDate: {
                type: 'date',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.statusDate : itemOrValue;

                    if (itemOrValue?.statusDate != null) {
                        if (itemOrValue.statusDate === '') {
                            itemOrValue.statusDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date') >= 0) {
                            itemOrValue.statusDate = parseInt(itemOrValue.statusDate.toString(), 10);
                        } else if (['float'].indexOf('date') >= 0) {
                            itemOrValue.statusDate = parseFloat(itemOrValue.statusDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date');
                    if (['uuid'].indexOf('date') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            summary: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.summary : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            rating: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.rating : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: IndicatorFutureStatusNew.isValid,
        };
    }

    static isValid(obj: IndicatorFutureStatusNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = IndicatorFutureStatusNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.details.isValid(item);
            result = result && validationObject.comments.isValid(item);
            result = result && validationObject.statusDate.isValid(item);
            result = result && validationObject.summary.isValid(item);
            result = result && validationObject.rating.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

