import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class PluginLoaderService {

  private plugins = {
    domtoimage: {
      loaded: false,
      src: [
        '/static/dist/assets/plugins/dom-to-image/dom-to-image.js',
      ],
    },
    leaflet: {
      loaded: false,
      src: [
        'static/dist/assets/plugins/leaflet/leaflet.css',
        'static/dist/assets/plugins/leaflet/leaflet.draw.css',
        'static/dist/assets/plugins/leaflet/MarkerCluster.css',
        'static/dist/assets/plugins/leaflet/MarkerCluster.Default.css',
        'static/dist/assets/plugins/leaflet/leaflet.contextmenu.min.css',
        'static/dist/assets/plugins/leaflet/leaflet.panel-layers.css',
        'static/dist/assets/plugins/leaflet/leaflet.fullscreen.css',

        '/static/dist/assets/plugins/leaflet/leaflet.js',
        '/static/dist/assets/plugins/leaflet/leaflet.contextmenu.min.js',
        '/static/dist/assets/plugins/leaflet/L.Deflate.js',
        '/static/dist/assets/plugins/leaflet/leaflet.draw.js',
        '/static/dist/assets/plugins/leaflet/leaflet.drawEx.js',
        '/static/dist/assets/plugins/leaflet/leaflet.fullscreen.min.js',
        '/static/dist/assets/plugins/leaflet/leaflet.markercluster.js',
        '/static/dist/assets/plugins/leaflet/leaflet.panel-layers.js',
        '/static/dist/assets/plugins/leaflet/leaflet.browser-print.js',
        '/static/dist/assets/plugins/leaflet/leaflet.browser-printEx.js',
      ],
    }
  };

  constructor() {

  }

  load(pluginKey: string): Observable<boolean> {
    return new Observable<boolean>((observer: Observer<boolean>) => {
      const plugin = this.plugins[pluginKey];
      if (!plugin.loaded) {
        plugin.loaded = true;

        const stylesHostElement = document.querySelector('head');
        const scriptsHostElement = document.querySelector('#plugin-scripts');

        if (typeof plugin.src === 'string') {
          this.loadScripts(stylesHostElement, scriptsHostElement, [plugin.src], observer);
        } else {
          this.loadScripts(stylesHostElement, scriptsHostElement, plugin.src, observer);
        }
      } else {
        setTimeout(() => {
          observer.next(true);
          observer.complete();
        });
      }
    });
  }

  private loadScripts(stylesHostElement: any, scriptsHostElement: any, scripts: string[], observer: Observer<boolean>): void {
    if ((scripts || []).length > 0) {
      const src = scripts.splice(0, 1)[0];
      let injectElement = null;
      if (src.indexOf('.js') >= 0) {
        injectElement = document.createElement('script');
        injectElement.type = 'text/javascript';
        injectElement.src = src;

        scriptsHostElement.appendChild(injectElement);
      } else if (src.indexOf('.css') >= 0) {
        injectElement = document.createElement('link');
        injectElement.rel = 'stylesheet';
        injectElement.href = src;

        stylesHostElement.appendChild(injectElement);
      }

      if (injectElement) {
        injectElement.onload = () => {
          // console.log(src);
          this.loadScripts(stylesHostElement, scriptsHostElement, scripts, observer);
        }
        injectElement.onerror = (error: any) => {
          console.error(`error loading ${src}`);
          observer.next(false);
          observer.complete();
        };
      }
    } else {
      observer.next(true);
      observer.complete();
    }
  }
}
