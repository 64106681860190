import * as moment from 'moment';


export class MomentUtils {

  static getLocaleDate(dateString: string) {
    return dateString ? moment(dateString).format('ll') : undefined;
  }

  static getLocaleDateFullMonth(dateString: string) {
    return dateString ? moment(dateString).format('LL') : undefined;
  }

  static getLocaleDateTime(dateString: string) {
    return dateString ? moment(dateString).format('lll') : undefined;
  }

}
