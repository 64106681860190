/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Strategy priority ratings
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type StrategyPriorityRatingEnum = 'Unknown' | 'Not Effective' | 'Need More Info' | 'Effective' | 'Very Effective';

export const StrategyPriorityRatingEnum = {
    Unknown: 'Unknown' as StrategyPriorityRatingEnum,
    NotEffective: 'Not Effective' as StrategyPriorityRatingEnum,
    NeedMoreInfo: 'Need More Info' as StrategyPriorityRatingEnum,
    Effective: 'Effective' as StrategyPriorityRatingEnum,
    VeryEffective: 'Very Effective' as StrategyPriorityRatingEnum
};

marker('Unknown');
marker('Not Effective');
marker('Need More Info');
marker('Effective');
marker('Very Effective');

