import { DomUtils } from './dom.utils';

export class MiradiUtils {

  /** @deprecated */
  static concatenateIdAndName(id: string, name: string, keepLineBreaks?: boolean) {
    if (keepLineBreaks) {
      return (id || '') + (id && name ? '. ' : '') + DomUtils.decodeText((name || '').replace(/<br\/*>/g, '\n'), keepLineBreaks).replace(/\n/g, '<br>');
    } else {
      return (id || '') + (id && name ? '. ' : '') + DomUtils.decodeText((name || ''));
    }
  }

  static concatenateIdAndNameV2(factor: { identifier?: string, name?: string }, keepLineBreaks?: boolean) {
    if (keepLineBreaks) {
      return (factor?.identifier || '') + (factor?.identifier && factor?.name ? '. ' : '') + DomUtils.decodeText((factor?.name || '').replace(/<br\/*>/g, '\n'), keepLineBreaks).replace(/\n/g, '<br>');
    } else {
      return (factor?.identifier || '') + (factor?.identifier && factor?.name ? '. ' : '') + DomUtils.decodeText((factor?.name || ''));
    }
  }

  static sort(a: any, b: any): number {
    const aString = (a.data?.factor?.identifier || '') + (a.data?.factor?.name || '');
    const bString = (b.data?.factor?.identifier || '') + (b.data?.factor?.name || '');

    if (
      !aString ||
      !bString ||
      aString[0] === aString[0].toLocaleLowerCase() && bString[0] === bString[0].toLocaleLowerCase() ||
      aString[0] === aString[0].toLocaleUpperCase() && bString[0] === bString[0].toLocaleUpperCase()
    ) {
        return aString.localeCompare(bString);
    }
    return aString[0] === aString[0].toLocaleLowerCase() ? -1 : 1;
  }

}
