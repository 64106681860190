/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Relevant factors for Output
 */
import { ValidateUtils } from 'app/shared/utils';

export class OutputRelevantFactors { 
    /**
     * Objectives marked as relevant to Output
     */
    objectiveResourceIdentifiers?: Array<string>;
    /**
     * Goals marked as relevant to Output
     */
    goalResourceIdentifiers?: Array<string>;
    /**
     * Indicators marked as relevant to Output
     */
    indicatorResourceIdentifiers?: Array<string>;

    constructor(item?: Partial<OutputRelevantFactors>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            objectiveResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.objectiveResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.objectiveResourceIdentifiers != null) {
                        if (itemOrValue.objectiveResourceIdentifiers === '') {
                            itemOrValue.objectiveResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.objectiveResourceIdentifiers = parseInt(itemOrValue.objectiveResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.objectiveResourceIdentifiers = parseFloat(itemOrValue.objectiveResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            goalResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.goalResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.goalResourceIdentifiers != null) {
                        if (itemOrValue.goalResourceIdentifiers === '') {
                            itemOrValue.goalResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.goalResourceIdentifiers = parseInt(itemOrValue.goalResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.goalResourceIdentifiers = parseFloat(itemOrValue.goalResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            indicatorResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.indicatorResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.indicatorResourceIdentifiers != null) {
                        if (itemOrValue.indicatorResourceIdentifiers === '') {
                            itemOrValue.indicatorResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.indicatorResourceIdentifiers = parseInt(itemOrValue.indicatorResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.indicatorResourceIdentifiers = parseFloat(itemOrValue.indicatorResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: OutputRelevantFactors.isValid,
        };
    }

    static isValid(obj: OutputRelevantFactors): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = OutputRelevantFactors.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.objectiveResourceIdentifiers.isValid(item);
            result = result && validationObject.goalResourceIdentifiers.isValid(item);
            result = result && validationObject.indicatorResourceIdentifiers.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

