/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Supported geometry types
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type GeometryType = 'Point' | 'LineString' | 'Polygon' | 'MultiPolygon';

export const GeometryType = {
    Point: 'Point' as GeometryType,
    LineString: 'LineString' as GeometryType,
    Polygon: 'Polygon' as GeometryType,
    MultiPolygon: 'MultiPolygon' as GeometryType
};

marker('Point');
marker('LineString');
marker('Polygon');
marker('MultiPolygon');

