import { AssignmentTaxonomyClassification, TaxonomyClassification, TaxonomyConfigurationTypeEnum, TaxonomyElementSelection } from 'app/shared/services';
import { MiradiUtils } from '../utils/miradi.utils';


export class FlatAssignmentTaxonomyClassification {

  projectIdentifier: string;
  resourceIdentifier: string;

  activity: string;
  strategy: string;
  resourceID: string;
  resourceFirstName: string;
  resourceLastName: string;
  type: TaxonomyConfigurationTypeEnum | 'Classification';

  isStandardClassification: boolean;
  taxonomyClassificationIdentifier: string;
  taxonomyClassificationLabel: string;
  taxonomyElementSelections: Array<TaxonomyElementSelection>;

  constructor(
    projectIdentifier: string,
    assignment: AssignmentTaxonomyClassification,
    classification: TaxonomyClassification,
  ) {
    if (assignment && classification) {
      this.projectIdentifier = projectIdentifier;
      this.resourceIdentifier = assignment.resourceIdentifier;

      this.activity = MiradiUtils.concatenateIdAndName(assignment.activityIdentifier, assignment.activityName);
      this.strategy = MiradiUtils.concatenateIdAndName(assignment.strategyIdentifier, assignment.strategyName);
      this.type = classification.configurationType || 'Classification';

      this.resourceID = assignment.resourceId;
      this.resourceFirstName = assignment.resourceFirstName;
      this.resourceLastName = assignment.resourceLastName;

      this.isStandardClassification = classification.isStandardClassification;
      this.taxonomyClassificationIdentifier = classification.taxonomyVersionResourceIdentifier;
      this.taxonomyClassificationLabel = classification.taxonomyVersionLabel;
      this.taxonomyElementSelections = classification.taxonomyElementSelections;
    }
  }

}
