/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi features
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type MiradiFeatureEnum = 'Supports Monitoring Activities' | 'Supports Evidence' | 'Supports Work Plan' | 'Supports Biophysical Results' | 'Supports Online Diagramming' | 'Supports Diagram Factor ZIndex' | 'Supports Extended Progress Reports' | 'Supports Future Status Viability' | 'Supports Online Threat Ratings' | 'Supports Outputs' | 'Supports Output Relevancies' | 'Supports Methods' | 'Supports Assumptions' | 'Supports Custom Diagram Colors' | 'Supports Indicator Strategy Activity Relevancy';

export const MiradiFeatureEnum = {
    MonitoringActivities: 'Supports Monitoring Activities' as MiradiFeatureEnum,
    Evidence: 'Supports Evidence' as MiradiFeatureEnum,
    WorkPlan: 'Supports Work Plan' as MiradiFeatureEnum,
    BiophysicalResults: 'Supports Biophysical Results' as MiradiFeatureEnum,
    OnlineDiagramming: 'Supports Online Diagramming' as MiradiFeatureEnum,
    DiagramFactorZIndex: 'Supports Diagram Factor ZIndex' as MiradiFeatureEnum,
    ExtendedProgressReports: 'Supports Extended Progress Reports' as MiradiFeatureEnum,
    FutureStatusViability: 'Supports Future Status Viability' as MiradiFeatureEnum,
    OnlineThreatRatings: 'Supports Online Threat Ratings' as MiradiFeatureEnum,
    Outputs: 'Supports Outputs' as MiradiFeatureEnum,
    OutputRelevancies: 'Supports Output Relevancies' as MiradiFeatureEnum,
    Methods: 'Supports Methods' as MiradiFeatureEnum,
    Assumptions: 'Supports Assumptions' as MiradiFeatureEnum,
    CustomDiagramColors: 'Supports Custom Diagram Colors' as MiradiFeatureEnum,
    IndicatorStrategyActivityRelevancy: 'Supports Indicator Strategy Activity Relevancy' as MiradiFeatureEnum
};

marker('Supports Monitoring Activities');
marker('Supports Evidence');
marker('Supports Work Plan');
marker('Supports Biophysical Results');
marker('Supports Online Diagramming');
marker('Supports Diagram Factor ZIndex');
marker('Supports Extended Progress Reports');
marker('Supports Future Status Viability');
marker('Supports Online Threat Ratings');
marker('Supports Outputs');
marker('Supports Output Relevancies');
marker('Supports Methods');
marker('Supports Assumptions');
marker('Supports Custom Diagram Colors');
marker('Supports Indicator Strategy Activity Relevancy');

