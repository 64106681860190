/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeatureCollection } from './featureCollection';


/**
 * Program map data
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProgramMap { 
    programBoundary?: FeatureCollection;
    /**
     * Geoserver url for map print
     */
    readonly geoserverPrintUrl?: string;
    /**
     * Geoserver url for WMS
     */
    readonly geoserverWmsUrl?: string;
    /**
     * Geoserver url for WFS
     */
    readonly geoserverWfsUrl?: string;
    /**
     * Geoserver layer name for project labels
     */
    readonly projectFootprintLabelLayerName?: string;

    constructor(item?: Partial<ProgramMap>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            programBoundary: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.programBoundary : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            geoserverPrintUrl: {
                type: 'uri',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.geoserverPrintUrl : itemOrValue;

                    if (itemOrValue?.geoserverPrintUrl != null) {
                        if (itemOrValue.geoserverPrintUrl === '') {
                            itemOrValue.geoserverPrintUrl = undefined;
                        } else if (['int32', 'int64'].indexOf('uri') >= 0) {
                            itemOrValue.geoserverPrintUrl = parseInt(itemOrValue.geoserverPrintUrl.toString(), 10);
                        } else if (['float'].indexOf('uri') >= 0) {
                            itemOrValue.geoserverPrintUrl = parseFloat(itemOrValue.geoserverPrintUrl.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uri');
                    if (['uuid'].indexOf('uri') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            geoserverWmsUrl: {
                type: 'uri',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.geoserverWmsUrl : itemOrValue;

                    if (itemOrValue?.geoserverWmsUrl != null) {
                        if (itemOrValue.geoserverWmsUrl === '') {
                            itemOrValue.geoserverWmsUrl = undefined;
                        } else if (['int32', 'int64'].indexOf('uri') >= 0) {
                            itemOrValue.geoserverWmsUrl = parseInt(itemOrValue.geoserverWmsUrl.toString(), 10);
                        } else if (['float'].indexOf('uri') >= 0) {
                            itemOrValue.geoserverWmsUrl = parseFloat(itemOrValue.geoserverWmsUrl.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uri');
                    if (['uuid'].indexOf('uri') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            geoserverWfsUrl: {
                type: 'uri',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.geoserverWfsUrl : itemOrValue;

                    if (itemOrValue?.geoserverWfsUrl != null) {
                        if (itemOrValue.geoserverWfsUrl === '') {
                            itemOrValue.geoserverWfsUrl = undefined;
                        } else if (['int32', 'int64'].indexOf('uri') >= 0) {
                            itemOrValue.geoserverWfsUrl = parseInt(itemOrValue.geoserverWfsUrl.toString(), 10);
                        } else if (['float'].indexOf('uri') >= 0) {
                            itemOrValue.geoserverWfsUrl = parseFloat(itemOrValue.geoserverWfsUrl.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uri');
                    if (['uuid'].indexOf('uri') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            projectFootprintLabelLayerName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectFootprintLabelLayerName : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProgramMap.isValid,
        };
    }

    static isValid(obj: ProgramMap): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProgramMap.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.programBoundary.isValid(item);
            result = result && validationObject.geoserverPrintUrl.isValid(item);
            result = result && validationObject.geoserverWmsUrl.isValid(item);
            result = result && validationObject.geoserverWfsUrl.isValid(item);
            result = result && validationObject.projectFootprintLabelLayerName.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

