/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Project-level feature to which permission can be granted
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ProjectFeature = 'ViewProjectBasic' | 'ViewProjectDetail' | 'EditProjectInfo' | 'CheckIn' | 'CheckOut' | 'CancelCheckout' | 'DeleteProject' | 'AddTeamMember' | 'AssignDeleteProjectRoles' | 'AssignProjectAccess' | 'AssignProjectState' | 'AssignProjectVersion' | 'RedactDownloadFile';

export const ProjectFeature = {
    ViewProjectBasic: 'ViewProjectBasic' as ProjectFeature,
    ViewProjectDetail: 'ViewProjectDetail' as ProjectFeature,
    EditProjectInfo: 'EditProjectInfo' as ProjectFeature,
    CheckIn: 'CheckIn' as ProjectFeature,
    CheckOut: 'CheckOut' as ProjectFeature,
    CancelCheckout: 'CancelCheckout' as ProjectFeature,
    DeleteProject: 'DeleteProject' as ProjectFeature,
    AddTeamMember: 'AddTeamMember' as ProjectFeature,
    AssignDeleteProjectRoles: 'AssignDeleteProjectRoles' as ProjectFeature,
    AssignProjectAccess: 'AssignProjectAccess' as ProjectFeature,
    AssignProjectState: 'AssignProjectState' as ProjectFeature,
    AssignProjectVersion: 'AssignProjectVersion' as ProjectFeature,
    RedactDownloadFile: 'RedactDownloadFile' as ProjectFeature
};



