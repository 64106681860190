export enum ProgressReportStatusColor {
  Planned = '#4C8699',
  MajorIssues = '#CC3333',
  MinorIssues = '#DDBA00', //
  OnTrack = '#A6D4A5',
  Completed = '#037429',
  Abandoned = '#959393',
  NotKnown = '#959393',
  NotSpecified = 'transparent',
}
