import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { User } from '../api-generated';
import { AuthService } from '../auth/auth.service';
import { NotificationService } from '../notification/notification.service';



@Injectable({
  providedIn: 'root'
})
export class HomeRouteGuardService  {

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.getSessionUser(true)
    .pipe(
      map((user: User) => {
        if (user && route.url[0].path !== 'home-user') {
          return this.router.createUrlTree(['/home-user'], { queryParams: route.queryParams });
        } else if (!user && route.url[0].path === 'home-user') {
          return this.router.createUrlTree(['/home'], { queryParams: route.queryParams });
        } else {
          return true;
        }
      }),
    );
  }
}
