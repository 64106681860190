/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Project report document
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectReportDocument { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    /**
     * Project identifier
     */
    readonly projectIdentifier?: string;
    /**
     * Document name
     */
    readonly documentName?: string;
    /**
     * Document description
     */
    readonly documentDescription?: string;
    /**
     * Document guidance
     */
    readonly documentGuidance?: string;
    /**
     * Indicates whether underlying template is Program-specific or applies to all Programs (i.e. is a standard template)
     */
    readonly isStandardTemplate?: boolean;
    /**
     * Date document last downloaded (rendered)
     */
    readonly lastDownloadedDate?: string;
    /**
     * Resource identifier for User who last downloaded document (if applicable)
     */
    readonly lastDownloadedByResourceIdentifier?: string;
    /**
     * Full name of User who who last downloaded document (if applicable)
     */
    readonly lastDownloadedByFullName?: string;
    /**
     * Date underlying document template last updated
     */
    readonly documentUpdatedDate?: string;

    constructor(item?: Partial<ProjectReportDocument>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 36, 36);
                    }

                    return result;
                }
            },
            projectIdentifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectIdentifier : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            documentName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.documentName : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            documentDescription: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.documentDescription : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            documentGuidance: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.documentGuidance : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isStandardTemplate: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isStandardTemplate : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            lastDownloadedDate: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastDownloadedDate : itemOrValue;

                    if (itemOrValue?.lastDownloadedDate != null) {
                        if (itemOrValue.lastDownloadedDate === '') {
                            itemOrValue.lastDownloadedDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.lastDownloadedDate = parseInt(itemOrValue.lastDownloadedDate.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.lastDownloadedDate = parseFloat(itemOrValue.lastDownloadedDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    if (['uuid'].indexOf('date-time') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            lastDownloadedByResourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastDownloadedByResourceIdentifier : itemOrValue;

                    if (itemOrValue?.lastDownloadedByResourceIdentifier != null) {
                        if (itemOrValue.lastDownloadedByResourceIdentifier === '') {
                            itemOrValue.lastDownloadedByResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.lastDownloadedByResourceIdentifier = parseInt(itemOrValue.lastDownloadedByResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.lastDownloadedByResourceIdentifier = parseFloat(itemOrValue.lastDownloadedByResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            lastDownloadedByFullName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastDownloadedByFullName : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            documentUpdatedDate: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.documentUpdatedDate : itemOrValue;

                    if (itemOrValue?.documentUpdatedDate != null) {
                        if (itemOrValue.documentUpdatedDate === '') {
                            itemOrValue.documentUpdatedDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.documentUpdatedDate = parseInt(itemOrValue.documentUpdatedDate.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.documentUpdatedDate = parseFloat(itemOrValue.documentUpdatedDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    if (['uuid'].indexOf('date-time') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProjectReportDocument.isValid,
        };
    }

    static isValid(obj: ProjectReportDocument): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectReportDocument.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.projectIdentifier.isValid(item);
            result = result && validationObject.documentName.isValid(item);
            result = result && validationObject.documentDescription.isValid(item);
            result = result && validationObject.documentGuidance.isValid(item);
            result = result && validationObject.isStandardTemplate.isValid(item);
            result = result && validationObject.lastDownloadedDate.isValid(item);
            result = result && validationObject.lastDownloadedByResourceIdentifier.isValid(item);
            result = result && validationObject.lastDownloadedByFullName.isValid(item);
            result = result && validationObject.documentUpdatedDate.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

