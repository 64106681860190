import { HttpResponse } from '@angular/common/http';
import { BlobUtils } from './blob.utils';


export class DownloadUtils {

  static getFilenameFromHttpResponse(response: HttpResponse<any>) {
    const contentDisposition = response.headers.get('Content-Disposition');
    const asciiFilenamePrefix = 'filename=';
    const asciiFilenameIndex = contentDisposition.indexOf(asciiFilenamePrefix);
    const utf8Filename = 'filename*=UTF-8\'\'';
    const utf8FilenameIndex = contentDisposition.indexOf(utf8Filename);
    if (utf8FilenameIndex >= 0) {
      return decodeURI(contentDisposition.substring(utf8FilenameIndex + utf8Filename.length));
    } else {
      return contentDisposition.substring(asciiFilenameIndex + asciiFilenamePrefix.length).replace(/"/g, '');
    }
  }

  static downloadB64AsFile(b64: string, mimeType: string, filename: string) {
    if ((navigator as any).msSaveBlob) {
      (navigator as any).msSaveBlob(
        BlobUtils.b64ToBlob(b64, mimeType),
        filename
      );
    } else {
      this.downloadHrefAsFile(
        URL.createObjectURL(
          BlobUtils.b64ToBlob(b64, mimeType)
        ),
        filename,
      );
    }
  }

  static downloadBlobAsFile(blob: Blob, filename: string) {
    if ((navigator as any).msSaveBlob) {
      (navigator as any).msSaveBlob(
        blob,
        filename
      );
    } else {
      this.downloadHrefAsFile(
        URL.createObjectURL(blob),
        filename,
      );
    }
  }

  static downloadPlainTextAsFile(text: string, filename: string) {
    if ((navigator as any).msSaveBlob) {
      (navigator as any).msSaveBlob(
        BlobUtils.stringToBlob(text, 'plain/text'),
        filename
      );
    } else {
      this.downloadHrefAsFile(
        URL.createObjectURL(
          BlobUtils.stringToBlob(text, 'plain/text'),
        ),
        filename,
      );
    }
  }

  static downloadHrefAsFile(href: string, filename: string) {
    if (href.indexOf('data:') === 0 && (navigator as any).msSaveBlob) {
      (navigator as any).msSaveBlob(
        this.dataURItoBlob(href),
        filename
      );
    } else {
      const tempDownloadLink = document.createElement('a');
      tempDownloadLink.href = href;
      tempDownloadLink.download = filename;

      document.body.appendChild(tempDownloadLink);
      tempDownloadLink.click();
      document.body.removeChild(tempDownloadLink);
    }
  }

  private static dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], {type: mimeString});
    return blob;
  }

}
