import { Injectable } from '@angular/core';


export interface DiagramViewportBoundsAndScale {
  viewportBounds: any;
  scale: number;
}

export interface DiagramVisibilityExtras {
  isDiagramDetailsDisplayEnabled: boolean;
  isDiagramLegendDisplayEnabled: boolean;
  isFactorIdentifierDisplayEnabled: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private readonly activeAccordionsKey = 'miradi_active_accordions';
  private readonly diagramDefaultColorPalette = 'miradi_diagram_default_color_palette';
  private readonly diagramFactorNameEdit = 'miradi_diagram_factor_name_edit';
  private readonly diagramFactorsLocations = 'miradi_diagram_factors_locations';
  private readonly diagramFactorsSizes = 'miradi_diagram_factors_sizes';
  private readonly diagramGroupBoxChanged = 'miradi_diagram_groupbox_changed';
  private readonly diagramGroupBoxesLockedState = 'miradi_diagram_groupboxes_locked_state';
  private readonly diagramLayoutOptions = 'miradi_diagram_layout_options';
  private readonly diagramViewportBoundsAndScale = 'miradi_diagram_viewport_bounds_and_scale';
  private readonly diagramVisibilityExtras = 'miradi_diagram_visibility_extras';

  private readonly tocGuide = 'midari_toc_guide';

  private diagramFactorLocationMap: any;
  private diagramFactorSizesMap: any;
  private diagramGroupboxesLockedStateMap: any;
  private diagramViewportBoundsAndScaleMap: { [key: string]: DiagramViewportBoundsAndScale };
  private diagramVisibilityExtrasMap: { [key: string]: DiagramVisibilityExtras };

  constructor () {
  }

  getActiveAccordions(): string[] {
    return JSON.parse(sessionStorage.getItem(this.activeAccordionsKey) || '[]');
  }

  setActiveAccordions(activeAccordionKeys: string[]): void {
    sessionStorage.setItem(this.activeAccordionsKey, JSON.stringify(activeAccordionKeys));
  }

  getDiagramDefaultColorPaletteResourceIdentifier(): string {
    return sessionStorage.getItem(this.diagramDefaultColorPalette);
  }

  setDiagramDefaultColorPaletteResourceIdentifier(resourceIdentifier: string): void {
    sessionStorage.setItem(this.diagramDefaultColorPalette, resourceIdentifier);
  }

  getDiagramFactorNameEdit(): boolean {
    return sessionStorage.getItem(this.diagramFactorNameEdit) === 'true';
  }

  setDiagramFactorNameEdit(diagramFactorNameEdit: boolean): void {
    sessionStorage.setItem(this.diagramFactorNameEdit, diagramFactorNameEdit.toString());
  }

  getDiagramGroupBoxChanged(): boolean {
    return sessionStorage.getItem(this.diagramGroupBoxChanged) === 'true';
  }

  setDiagramGroupBoxChanged(diagramGroupBoxChanged: boolean): void {
    sessionStorage.setItem(this.diagramGroupBoxChanged, diagramGroupBoxChanged.toString());
  }

  getDiagramLayoutOptions(): string[] {
    return JSON.parse(sessionStorage.getItem(this.diagramLayoutOptions) || '["smart-guidelines"]');
  }

  setDiagramLayoutOptions(diagramLayoutOptions: string[]): void {
    sessionStorage.setItem(this.diagramLayoutOptions, JSON.stringify(diagramLayoutOptions));
  }

  getDiagramFactorLocation(projectDiagramResourceIdentifier: string, factorResourceIdentifier: string): any /* go.Point */ {
    if (!this.diagramFactorLocationMap) {
      this.diagramFactorLocationMap = JSON.parse(sessionStorage.getItem(this.diagramFactorsLocations) || '{}');
    }
    return this.diagramFactorLocationMap[projectDiagramResourceIdentifier + '_' + factorResourceIdentifier];
  }

  setDiagramFactorLocation(projectDiagramResourceIdentifier: string, factorResourceIdentifier: string, location: any): any /* go.Point */ {
    if (!this.diagramFactorLocationMap) {
      this.diagramFactorLocationMap = {};
    }
    this.diagramFactorLocationMap[projectDiagramResourceIdentifier + '_' + factorResourceIdentifier] = location;
    sessionStorage.setItem(this.diagramFactorsLocations, JSON.stringify(this.diagramFactorLocationMap));
  }

  getDiagramFactorSize(projectDiagramResourceIdentifier: string, factorResourceIdentifier: string): any /* go.Size */ {
    if (!this.diagramFactorSizesMap) {
      this.diagramFactorSizesMap = JSON.parse(sessionStorage.getItem(this.diagramFactorsSizes) || '{}');
    }
    return this.diagramFactorSizesMap[projectDiagramResourceIdentifier + '_' + factorResourceIdentifier];
  }

  setDiagramFactorSize(projectDiagramResourceIdentifier: string, factorResourceIdentifier: string, location: any): any /* go.Size */ {
    if (!this.diagramFactorSizesMap) {
      this.diagramFactorSizesMap = {};
    }
    this.diagramFactorSizesMap[projectDiagramResourceIdentifier + '_' + factorResourceIdentifier] = location;
    sessionStorage.setItem(this.diagramFactorsSizes, JSON.stringify(this.diagramFactorSizesMap));
  }

  getDiagramGroupboxesLockedState(projectDiagramResourceIdentifier: string, diagramFactorResourceIdentifier: string): boolean {
    if (!this.diagramGroupboxesLockedStateMap) {
      this.diagramGroupboxesLockedStateMap = JSON.parse(sessionStorage.getItem(this.diagramGroupBoxesLockedState) || '{}');
    }
    return this.diagramGroupboxesLockedStateMap[projectDiagramResourceIdentifier + '_' + diagramFactorResourceIdentifier];
  }

  setDiagramGroupboxesLockedState(projectDiagramResourceIdentifier: string, diagramFactorResourceIdentifier: string, isLocked: boolean): void {
    if (!this.diagramGroupboxesLockedStateMap) {
      this.diagramGroupboxesLockedStateMap = {};
    }
    this.diagramGroupboxesLockedStateMap[projectDiagramResourceIdentifier + '_' + diagramFactorResourceIdentifier] = isLocked;
    sessionStorage.setItem(this.diagramGroupBoxesLockedState, JSON.stringify(this.diagramGroupboxesLockedStateMap));
  }

  clearDiagramGroupboxesLockedState(): void {
    this.diagramGroupboxesLockedStateMap = undefined;
    sessionStorage.removeItem(this.diagramGroupBoxesLockedState);
  }

  clearDiagramViewportBoundsAndScale(): void {
    this.diagramViewportBoundsAndScaleMap = undefined;
    sessionStorage.removeItem(this.diagramViewportBoundsAndScale);
  }

  getDiagramViewportBoundsAndScale(projectDiagramResourceIdentifier: string): DiagramViewportBoundsAndScale {
    if (!this.diagramViewportBoundsAndScaleMap) {
      this.diagramViewportBoundsAndScaleMap = JSON.parse(sessionStorage.getItem(this.diagramViewportBoundsAndScale) || '{}');
    }
    return this.diagramViewportBoundsAndScaleMap[projectDiagramResourceIdentifier];
  }

  setDiagramViewportBoundsAndScale(projectDiagramResourceIdentifier: string, diagramViewportBoundsAndScale: DiagramViewportBoundsAndScale): void {
    if (!this.diagramViewportBoundsAndScaleMap) {
      this.diagramViewportBoundsAndScaleMap = {};
    }
    this.diagramViewportBoundsAndScaleMap[projectDiagramResourceIdentifier] = JSON.parse(JSON.stringify(diagramViewportBoundsAndScale));
    sessionStorage.setItem(this.diagramViewportBoundsAndScale, JSON.stringify(this.diagramViewportBoundsAndScaleMap));
  }

  getDiagramVisibilityExtras(projectDiagramResourceIdentifier: string): DiagramVisibilityExtras {
    if (!this.diagramVisibilityExtrasMap) {
      this.diagramVisibilityExtrasMap = JSON.parse(sessionStorage.getItem(this.diagramVisibilityExtras) || '{}');
    }
    return this.diagramVisibilityExtrasMap[projectDiagramResourceIdentifier];
  }

  setDiagramVisibilityExtras(projectDiagramResourceIdentifier: string, diagramVisibilityExtras: DiagramVisibilityExtras): void {
    if (!this.diagramVisibilityExtrasMap) {
      this.diagramVisibilityExtrasMap = {};
    }
    this.diagramVisibilityExtrasMap[projectDiagramResourceIdentifier] = JSON.parse(JSON.stringify(diagramVisibilityExtras));
    sessionStorage.setItem(this.diagramVisibilityExtras, JSON.stringify(this.diagramVisibilityExtrasMap));
  }

  getTocGuide(): any {
    return JSON.parse(sessionStorage.getItem(this.tocGuide) || 'null');
  }

  setTocGuide(tocGuide: any): void {
    sessionStorage.setItem(this.tocGuide, JSON.stringify(tocGuide));
  }
}
