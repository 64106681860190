import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, Input, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { PrimeTemplate } from 'primeng/api';


@Component({
  selector: 'miradi-info-dialog',
  templateUrl: './info.dialog.html',
  styleUrls: ['./info.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoDialog extends BaseDialog {

  @ContentChild(PrimeTemplate, { read: TemplateRef }) infoTemplate?: any;

  @Input() appendTo: string;

  data: {
    title: string;
    imgSrc: string;
    message: string;
    buttonText: string;
  }

  constructor(
    authService: AuthService,
    cdr: ChangeDetectorRef,
    el: ElementRef,
    private translateService: TranslateService,
  ) {
    super(authService, cdr, el);
  }

  init(data: any) {
  }

  imageLoad() {
    this.cdr.markForCheck();

    setTimeout(() => {
      this.cdr.markForCheck();
    });
  }

  submit() {
    this.close(true);
  }

}
