/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi Share program member roles
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ProgramRoleEnum = 'Program Manager' | 'Program Editor' | 'Program Admin' | 'Program Partner' | 'Program Viewer';

export const ProgramRoleEnum = {
    Manager: 'Program Manager' as ProgramRoleEnum,
    Editor: 'Program Editor' as ProgramRoleEnum,
    Admin: 'Program Admin' as ProgramRoleEnum,
    Partner: 'Program Partner' as ProgramRoleEnum,
    Viewer: 'Program Viewer' as ProgramRoleEnum
};

marker('Program Manager');
marker('Program Editor');
marker('Program Admin');
marker('Program Partner');
marker('Program Viewer');

