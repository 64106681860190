import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnumUtils } from '../utils';

@Pipe({
  name: 'toLabelString'
})
export class ToLabelStringPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService,
  ) {}

  transform(text: string): string {
    return EnumUtils.toLabelString(text, this.translateService);
  }

}