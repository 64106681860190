/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Program custom report group  (new / update)
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProgramCustomReportGroupNew { 
    /**
     * Custom report group name
     */
    name?: string;
    /**
     * Custom report group description
     */
    description?: string;
    /**
     * Relevant Portfolio for custom report group
     */
    relevantPortfolioResourceIdentifiers?: Array<string>;
    /**
     * Relevant Custom Reports for custom report group
     */
    relevantCustomReportResourceIdentifiers?: Array<string>;

    constructor(item?: Partial<ProgramCustomReportGroupNew>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            name: {
                
                minLength: 1,
                maxLength: 200,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 1, 200);
                    }

                    return result;
                }
            },
            description: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.description : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            relevantPortfolioResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.relevantPortfolioResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.relevantPortfolioResourceIdentifiers != null) {
                        if (itemOrValue.relevantPortfolioResourceIdentifiers === '') {
                            itemOrValue.relevantPortfolioResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.relevantPortfolioResourceIdentifiers = parseInt(itemOrValue.relevantPortfolioResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.relevantPortfolioResourceIdentifiers = parseFloat(itemOrValue.relevantPortfolioResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            relevantCustomReportResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.relevantCustomReportResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.relevantCustomReportResourceIdentifiers != null) {
                        if (itemOrValue.relevantCustomReportResourceIdentifiers === '') {
                            itemOrValue.relevantCustomReportResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.relevantCustomReportResourceIdentifiers = parseInt(itemOrValue.relevantCustomReportResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.relevantCustomReportResourceIdentifiers = parseFloat(itemOrValue.relevantCustomReportResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProgramCustomReportGroupNew.isValid,
        };
    }

    static isValid(obj: ProgramCustomReportGroupNew): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProgramCustomReportGroupNew.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.description.isValid(item);
            result = result && validationObject.relevantPortfolioResourceIdentifiers.isValid(item);
            result = result && validationObject.relevantCustomReportResourceIdentifiers.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

