/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ProjectScopeProtectedAreaCategoryTypeEnum = 'Category Ia: Strict Nature Reserve' | 'Category Ib: Wilderness Area' | 'Category II: National Park' | 'Category III: Natural Monument or Feature' | 'Category IV: Habitat/Species Management Area' | 'Category V: Protected Landscape/Seascape' | 'Category VI: Managed Resource Protected Area';

export const ProjectScopeProtectedAreaCategoryTypeEnum = {
    IaStrictNatureReserve: 'Category Ia: Strict Nature Reserve' as ProjectScopeProtectedAreaCategoryTypeEnum,
    IbWildernessArea: 'Category Ib: Wilderness Area' as ProjectScopeProtectedAreaCategoryTypeEnum,
    IiNationalPark: 'Category II: National Park' as ProjectScopeProtectedAreaCategoryTypeEnum,
    IiiNaturalMonumentOrFeature: 'Category III: Natural Monument or Feature' as ProjectScopeProtectedAreaCategoryTypeEnum,
    IvHabitatSpeciesManagementArea: 'Category IV: Habitat/Species Management Area' as ProjectScopeProtectedAreaCategoryTypeEnum,
    VProtectedLandscapeSeascape: 'Category V: Protected Landscape/Seascape' as ProjectScopeProtectedAreaCategoryTypeEnum,
    ViManagedResourceProtectedArea: 'Category VI: Managed Resource Protected Area' as ProjectScopeProtectedAreaCategoryTypeEnum
};

marker('Category Ia: Strict Nature Reserve');
marker('Category Ib: Wilderness Area');
marker('Category II: National Park');
marker('Category III: Natural Monument or Feature');
marker('Category IV: Habitat/Species Management Area');
marker('Category V: Protected Landscape/Seascape');
marker('Category VI: Managed Resource Protected Area');

