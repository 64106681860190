/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectFactorSummaryReportAllOf } from './projectFactorSummaryReportAllOf';
import { Report } from './report';
import { FactorKeyValuePair } from './factorKeyValuePair';
import { FactorSummary } from './factorSummary';


/**
 * Project factor summary report
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectFactorSummaryReport { 
    /**
     * Datetime that report data was materialized
     */
    readonly asOfDate?: string;
    /**
     * Unique identifier for Project
     */
    readonly projectIdentifier: string;
    /**
     * Project name
     */
    readonly projectName: string;
    /**
     * Portfolios project is part of
     */
    readonly portfolios: Array<FactorKeyValuePair>;
    /**
     * Factor summaries for Project
     */
    readonly factorSummaries: Array<FactorSummary>;

    constructor(item?: Partial<ProjectFactorSummaryReport>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            asOfDate: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.asOfDate : itemOrValue;

                    if (itemOrValue?.asOfDate != null) {
                        if (itemOrValue.asOfDate === '') {
                            itemOrValue.asOfDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.asOfDate = parseInt(itemOrValue.asOfDate.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.asOfDate = parseFloat(itemOrValue.asOfDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    if (['uuid'].indexOf('date-time') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            projectIdentifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectIdentifier : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            projectName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectName : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            portfolios: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.portfolios : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            factorSummaries: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.factorSummaries : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProjectFactorSummaryReport.isValid,
        };
    }

    static isValid(obj: ProjectFactorSummaryReport): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectFactorSummaryReport.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.asOfDate.isValid(item);
            result = result && validationObject.projectIdentifier.isValid(item);
            result = result && validationObject.projectName.isValid(item);
            result = result && validationObject.portfolios.isValid(item);
            result = result && validationObject.factorSummaries.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

