/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Taxonomy selection type
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type TaxonomySelectionTypeEnum = 'Any Node' | 'Leaf Only';

export const TaxonomySelectionTypeEnum = {
    AnyNode: 'Any Node' as TaxonomySelectionTypeEnum,
    LeafOnly: 'Leaf Only' as TaxonomySelectionTypeEnum
};

marker('Any Node');
marker('Leaf Only');

