/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectKeyValuePair } from './projectKeyValuePair';


/**
 * Program report document template
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProgramReportDocumentTemplate { 
    /**
     * Miradi object resource identifier
     */
    resourceIdentifier?: string;
    /**
     * Template name (unique within program)
     */
    readonly templateName?: string;
    /**
     * Template description
     */
    readonly templateDescription?: string;
    /**
     * Template guidance
     */
    readonly templateGuidance?: string;
    /**
     * Date Template last updated
     */
    readonly lastUpdatedDate?: string;
    /**
     * Resource identifier for User who last updated template (if applicable)
     */
    readonly lastUpdatedByResourceIdentifier?: string;
    /**
     * Full name of User who who last updated template (if applicable)
     */
    readonly lastUpdatedByFullName?: string;
    /**
     * Template file name
     */
    readonly fileName?: string;
    /**
     * Template file size
     */
    readonly templateFileSize?: string;
    /**
     * Indicates whether template is Program-specific or applies to all Programs (i.e. is a standard template)
     */
    readonly isStandardTemplate?: boolean;
    /**
     * Indicates whether template is active or not (i.e. visible to relevant projects)
     */
    readonly isActive?: boolean;
    /**
     * Relevant Projects for Template (does not apply to standard templates)
     */
    readonly relevantProjectResourceIdentifiers?: Array<ProjectKeyValuePair>;
    /**
     * List of tags for filtering
     */
    readonly filterTags?: Array<string>;

    constructor(item?: Partial<ProgramReportDocumentTemplate>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            resourceIdentifier: {
                type: 'uuid',
                minLength: 36,
                maxLength: 36,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.resourceIdentifier : itemOrValue;

                    if (itemOrValue?.resourceIdentifier != null) {
                        if (itemOrValue.resourceIdentifier === '') {
                            itemOrValue.resourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseInt(itemOrValue.resourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.resourceIdentifier = parseFloat(itemOrValue.resourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 36, 36);
                    }

                    return result;
                }
            },
            templateName: {
                
                minLength: 1,
                maxLength: 1024,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.templateName : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 1, 1024);
                    }

                    return result;
                }
            },
            templateDescription: {
                
                minLength: 0,
                maxLength: 1024,
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.templateDescription : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, 0, 1024);
                    }

                    return result;
                }
            },
            templateGuidance: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.templateGuidance : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            lastUpdatedDate: {
                type: 'date-time',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastUpdatedDate : itemOrValue;

                    if (itemOrValue?.lastUpdatedDate != null) {
                        if (itemOrValue.lastUpdatedDate === '') {
                            itemOrValue.lastUpdatedDate = undefined;
                        } else if (['int32', 'int64'].indexOf('date-time') >= 0) {
                            itemOrValue.lastUpdatedDate = parseInt(itemOrValue.lastUpdatedDate.toString(), 10);
                        } else if (['float'].indexOf('date-time') >= 0) {
                            itemOrValue.lastUpdatedDate = parseFloat(itemOrValue.lastUpdatedDate.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'date-time');
                    if (['uuid'].indexOf('date-time') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            lastUpdatedByResourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastUpdatedByResourceIdentifier : itemOrValue;

                    if (itemOrValue?.lastUpdatedByResourceIdentifier != null) {
                        if (itemOrValue.lastUpdatedByResourceIdentifier === '') {
                            itemOrValue.lastUpdatedByResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.lastUpdatedByResourceIdentifier = parseInt(itemOrValue.lastUpdatedByResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.lastUpdatedByResourceIdentifier = parseFloat(itemOrValue.lastUpdatedByResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            lastUpdatedByFullName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.lastUpdatedByFullName : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            fileName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.fileName : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            templateFileSize: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.templateFileSize : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isStandardTemplate: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isStandardTemplate : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isActive: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isActive : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            relevantProjectResourceIdentifiers: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.relevantProjectResourceIdentifiers : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            filterTags: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.filterTags : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProgramReportDocumentTemplate.isValid,
        };
    }

    static isValid(obj: ProgramReportDocumentTemplate): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProgramReportDocumentTemplate.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.resourceIdentifier.isValid(item);
            result = result && validationObject.templateName.isValid(item);
            result = result && validationObject.templateDescription.isValid(item);
            result = result && validationObject.templateGuidance.isValid(item);
            result = result && validationObject.lastUpdatedDate.isValid(item);
            result = result && validationObject.lastUpdatedByResourceIdentifier.isValid(item);
            result = result && validationObject.lastUpdatedByFullName.isValid(item);
            result = result && validationObject.fileName.isValid(item);
            result = result && validationObject.templateFileSize.isValid(item);
            result = result && validationObject.isStandardTemplate.isValid(item);
            result = result && validationObject.isActive.isValid(item);
            result = result && validationObject.relevantProjectResourceIdentifiers.isValid(item);
            result = result && validationObject.filterTags.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

