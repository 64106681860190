/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Simple irreversibility ratings
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type SimpleIrreversibilityRatingEnum = 'Low' | 'Medium' | 'High' | 'Very High';

export const SimpleIrreversibilityRatingEnum = {
    Low: 'Low' as SimpleIrreversibilityRatingEnum,
    Medium: 'Medium' as SimpleIrreversibilityRatingEnum,
    High: 'High' as SimpleIrreversibilityRatingEnum,
    VeryHigh: 'Very High' as SimpleIrreversibilityRatingEnum
};

marker('Low');
marker('Medium');
marker('High');
marker('Very High');

