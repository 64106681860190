/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Relevant factors for Goal
 */
import { ValidateUtils } from 'app/shared/utils';

export class GoalRelevantFactors { 
    /**
     * Indicators marked as relevant to Goal
     */
    indicatorResourceIdentifiers?: Array<string>;
    /**
     * Strategies marked as relevant to Goal
     */
    strategyResourceIdentifiers?: Array<string>;
    /**
     * Activities marked as relevant to Goal
     */
    activityResourceIdentifiers?: Array<string>;
    /**
     * Outputs marked as relevant to Goal
     */
    outputResourceIdentifiers?: Array<string>;

    constructor(item?: Partial<GoalRelevantFactors>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            indicatorResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.indicatorResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.indicatorResourceIdentifiers != null) {
                        if (itemOrValue.indicatorResourceIdentifiers === '') {
                            itemOrValue.indicatorResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.indicatorResourceIdentifiers = parseInt(itemOrValue.indicatorResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.indicatorResourceIdentifiers = parseFloat(itemOrValue.indicatorResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            strategyResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.strategyResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.strategyResourceIdentifiers != null) {
                        if (itemOrValue.strategyResourceIdentifiers === '') {
                            itemOrValue.strategyResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.strategyResourceIdentifiers = parseInt(itemOrValue.strategyResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.strategyResourceIdentifiers = parseFloat(itemOrValue.strategyResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            activityResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.activityResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.activityResourceIdentifiers != null) {
                        if (itemOrValue.activityResourceIdentifiers === '') {
                            itemOrValue.activityResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.activityResourceIdentifiers = parseInt(itemOrValue.activityResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.activityResourceIdentifiers = parseFloat(itemOrValue.activityResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            outputResourceIdentifiers: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.outputResourceIdentifiers : itemOrValue;

                    if (itemOrValue?.outputResourceIdentifiers != null) {
                        if (itemOrValue.outputResourceIdentifiers === '') {
                            itemOrValue.outputResourceIdentifiers = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.outputResourceIdentifiers = parseInt(itemOrValue.outputResourceIdentifiers.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.outputResourceIdentifiers = parseFloat(itemOrValue.outputResourceIdentifiers.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    if (['uuid'].indexOf('uuid') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: GoalRelevantFactors.isValid,
        };
    }

    static isValid(obj: GoalRelevantFactors): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = GoalRelevantFactors.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.indicatorResourceIdentifiers.isValid(item);
            result = result && validationObject.strategyResourceIdentifiers.isValid(item);
            result = result && validationObject.activityResourceIdentifiers.isValid(item);
            result = result && validationObject.outputResourceIdentifiers.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

