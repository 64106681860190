import { Project } from '../services';

export class ProjectChangeMessage {

  options?: any;
  project: Project;
  source?: string;

  constructor(item?: any) {
    Object.assign(this, item);
  }

}
