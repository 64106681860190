/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi activity types
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ActivityType = 'Activity' | 'Monitoring Activity';

export const ActivityType = {
    Activity: 'Activity' as ActivityType,
    MonitoringActivity: 'Monitoring Activity' as ActivityType
};

marker('Activity');
marker('Monitoring Activity');

