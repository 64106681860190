import { ExpenseTaxonomyClassification, TaxonomyClassification, TaxonomyConfigurationTypeEnum, TaxonomyElementSelection } from 'app/shared/services';
import { MiradiUtils } from '../utils/miradi.utils';


export class FlatExpenseTaxonomyClassification {

  projectIdentifier: string;
  resourceIdentifier: string;

  activity: string;
  strategy: string;
  expenseName: string;
  type: TaxonomyConfigurationTypeEnum | 'Classification';

  isStandardClassification: boolean;
  taxonomyClassificationIdentifier: string;
  taxonomyClassificationLabel: string;
  taxonomyElementSelections: Array<TaxonomyElementSelection>;

  constructor(
    projectIdentifier: string,
    expense: ExpenseTaxonomyClassification,
    classification: TaxonomyClassification,
  ) {
    if (expense && classification) {
      this.projectIdentifier = projectIdentifier;
      this.resourceIdentifier = expense.resourceIdentifier;

      this.activity = MiradiUtils.concatenateIdAndName(expense.activityIdentifier, expense.activityName);
      this.strategy = MiradiUtils.concatenateIdAndName(expense.strategyIdentifier, expense.strategyName);
      this.expenseName = expense.expenseName;
      this.type = classification.configurationType || 'Classification';

      this.isStandardClassification = classification.isStandardClassification;
      this.taxonomyClassificationIdentifier = classification.taxonomyVersionResourceIdentifier;
      this.taxonomyClassificationLabel = classification.taxonomyVersionLabel;
      this.taxonomyElementSelections = classification.taxonomyElementSelections;
    }
  }

}
