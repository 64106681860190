/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Miradi factor types
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type FactorType = 'Activity' | 'Biophysical Factor' | 'Biophysical Result' | 'Conceptual Model' | 'Contributing Factor' | 'Direct Threat' | 'Expense Assignment' | 'Goal' | 'Human Wellbeing Target' | 'Indicator' | 'Intermediate Result' | 'Key Ecological Attribute' | 'Method' | 'Monitoring Activity' | 'Nested Target' | 'Objective' | 'Project' | 'Project Resource' | 'Resource Assignment' | 'Results Chain' | 'Strategy' | 'Stress' | 'Target' | 'Task' | 'Threat Reduction Result' | 'Output' | 'Assumption' | 'Subassumption';

export const FactorType = {
    Activity: 'Activity' as FactorType,
    BiophysicalFactor: 'Biophysical Factor' as FactorType,
    BiophysicalResult: 'Biophysical Result' as FactorType,
    ConceptualModel: 'Conceptual Model' as FactorType,
    ContributingFactor: 'Contributing Factor' as FactorType,
    DirectThreat: 'Direct Threat' as FactorType,
    ExpenseAssignment: 'Expense Assignment' as FactorType,
    Goal: 'Goal' as FactorType,
    HumanWellbeingTarget: 'Human Wellbeing Target' as FactorType,
    Indicator: 'Indicator' as FactorType,
    IntermediateResult: 'Intermediate Result' as FactorType,
    KeyEcologicalAttribute: 'Key Ecological Attribute' as FactorType,
    Method: 'Method' as FactorType,
    MonitoringActivity: 'Monitoring Activity' as FactorType,
    NestedTarget: 'Nested Target' as FactorType,
    Objective: 'Objective' as FactorType,
    Project: 'Project' as FactorType,
    ProjectResource: 'Project Resource' as FactorType,
    ResourceAssignment: 'Resource Assignment' as FactorType,
    ResultsChain: 'Results Chain' as FactorType,
    Strategy: 'Strategy' as FactorType,
    Stress: 'Stress' as FactorType,
    Target: 'Target' as FactorType,
    Task: 'Task' as FactorType,
    ThreatReductionResult: 'Threat Reduction Result' as FactorType,
    Output: 'Output' as FactorType,
    Assumption: 'Assumption' as FactorType,
    Subassumption: 'Subassumption' as FactorType
};

marker('Activity');
marker('Biophysical Factor');
marker('Biophysical Result');
marker('Conceptual Model');
marker('Contributing Factor');
marker('Direct Threat');
marker('Expense Assignment');
marker('Goal');
marker('Human Wellbeing Target');
marker('Indicator');
marker('Intermediate Result');
marker('Key Ecological Attribute');
marker('Method');
marker('Monitoring Activity');
marker('Nested Target');
marker('Objective');
marker('Project');
marker('Project Resource');
marker('Resource Assignment');
marker('Results Chain');
marker('Strategy');
marker('Stress');
marker('Target');
marker('Task');
marker('Threat Reduction Result');
marker('Output');
marker('Assumption');
marker('Subassumption');

