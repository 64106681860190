/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectAccessEnum } from './projectAccessEnum';


/**
 * Project access state
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProjectAccessState { 
    accessState?: ProjectAccessEnum;

    constructor(item?: Partial<ProjectAccessState>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            accessState: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.accessState : itemOrValue;

                    if (['uuid'].indexOf('') === -1 || value != null) {
                      result = result && ValidateUtils.isValidLength(value, null, );
                    }

                    return result;
                }
            },
            isValid: ProjectAccessState.isValid,
        };
    }

    static isValid(obj: ProjectAccessState): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProjectAccessState.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.accessState.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

