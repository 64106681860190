/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Timeframe date units
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type TimeframeDateUnitEnum = 'Year' | 'Quarter' | 'Month' | 'Day' | 'Full Project Timespan';

export const TimeframeDateUnitEnum = {
    Year: 'Year' as TimeframeDateUnitEnum,
    Quarter: 'Quarter' as TimeframeDateUnitEnum,
    Month: 'Month' as TimeframeDateUnitEnum,
    Day: 'Day' as TimeframeDateUnitEnum,
    FullProjectTimespan: 'Full Project Timespan' as TimeframeDateUnitEnum
};

marker('Year');
marker('Quarter');
marker('Month');
marker('Day');
marker('Full Project Timespan');

