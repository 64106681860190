/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Target viability status
 */
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

export type ViabilityStatusEnum = 'Poor' | 'Fair' | 'Good' | 'Very Good';

export const ViabilityStatusEnum = {
    Poor: 'Poor' as ViabilityStatusEnum,
    Fair: 'Fair' as ViabilityStatusEnum,
    Good: 'Good' as ViabilityStatusEnum,
    VeryGood: 'Very Good' as ViabilityStatusEnum
};

marker('Poor');
marker('Fair');
marker('Good');
marker('Very Good');

